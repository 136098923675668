import { BONUS } from 'constants/currency';
import { currencyHeaderName } from 'constants/CurrencyHeaderName';
import { MODAL_TITLE_CREATE_PAYMENT_TYPE } from 'constants/modalTitles';
import { PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import usePaymentTypeCreate from 'shared/hook/paymentType/create/usePaymentTypeCreate';
import { useStore } from 'store';

import {
    PAYMENT_TYPE_DEFAULT_VALUES,
    PAYMENT_TYPE_FIELD_KEYS, PAYMENT_TYPE_LABEL_DATA, PAYMENT_TYPE_VALIDATION_SCHEMA,
    PaymentTypeFormFields,
} from './formData';

const CreatePaymentTypeModal: FC = observer(() => {
    const { isOpen, onClose, paymentId } = useStore('createPaymentType');
    const { createPaymentType, loading } = usePaymentTypeCreate();

    const {
        control, handleSubmit, reset, formState: { isValid, isDirty },
    } = useForm<PaymentTypeFormFields>({
        mode: 'onBlur',
        defaultValues: PAYMENT_TYPE_DEFAULT_VALUES,
        resolver: yupResolver(PAYMENT_TYPE_VALIDATION_SCHEMA),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleCreatePaymentType = (input: PaymentTypeFormFields) => {
        createPaymentType(input, paymentId).then(() => handleCloseModal());
    };

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ handleCloseModal }>{ MODAL_TITLE_CREATE_PAYMENT_TYPE }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 container spacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            name={ PAYMENT_TYPE_FIELD_KEYS.title }
                            control={ control }
                            label={ PROPERTY_TITLE }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            name={ PAYMENT_TYPE_FIELD_KEYS.position }
                            control={ control }
                            label={ PROPERTY_POSITION }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            name={ PAYMENT_TYPE_FIELD_KEYS.defaultBonusPayment }
                            control={ control }
                            label={ currencyHeaderName(BONUS) }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            name={ PAYMENT_TYPE_FIELD_KEYS.defaultFiatPayment }
                            control={ control }
                            label={ currencyHeaderName() }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            control={ control }
                            name={ PAYMENT_TYPE_FIELD_KEYS.fiatPaymentDivisible }
                            label={ PAYMENT_TYPE_LABEL_DATA.fiatPaymentDivisible }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            control={ control }
                            name={ PAYMENT_TYPE_FIELD_KEYS.bonusPaymentDivisible }
                            label={ PAYMENT_TYPE_LABEL_DATA.bonusPaymentDivisible }
                            type="number"
                        />
                    </Grid2>
                    <Grid2 xs={ 4 }>
                        <Switch
                            name={ PAYMENT_TYPE_FIELD_KEYS.isActive }
                            control={ control }
                            label={ PROPERTY_IS_ACTIVE }
                            type="boolean"
                        />
                    </Grid2>
                    <Grid2 xs={ 4 }>
                        <Switch
                            name={ PAYMENT_TYPE_FIELD_KEYS.shouldConfirmBySms }
                            control={ control }
                            label={ PAYMENT_TYPE_LABEL_DATA.shouldConfirmBySms }
                            type="boolean"
                        />
                    </Grid2>
                    <Grid2 xs={ 4 }>
                        <Switch
                            name={ PAYMENT_TYPE_FIELD_KEYS.allowNegativePayments }
                            control={ control }
                            label={ PAYMENT_TYPE_LABEL_DATA.allowNegativePayments }
                            type="boolean"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    onClick={ handleSubmit(handleCreatePaymentType) }
                    variant="contained"
                    color="success"
                    loading={ loading }
                    disabled={ !isValid || !isDirty }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreatePaymentTypeModal;
