import useCourtTypeMultipleDelete from 'shared/data-hook/courtType/delete/multiple/useCourtTypeMultipleDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtTypeT } from 'types/CourtType';

const useDeleteMultipleCourtType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultipleCourtType: actionDelete } = useCourtTypeMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultipleCourtType = async (courtTypeIds: [CourtTypeT['id']]) => {
        const { affectedRows } = await actionDelete(courtTypeIds);

        if (error || !affectedRows) {
            showError('Не удалось удалить типы кортов');

            return;
        }

        toggleRefetchTrigger();
        showSuccess(`Строк удалено: ${affectedRows}`);
    };

    return {
        deleteMultipleCourtType,
    };
};

export default useDeleteMultipleCourtType;
