import { makeAutoObservable } from 'mobx';

export default class RefetchTriggerStore {
    public refetchTrigger: boolean = false;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public toggleRefetchTrigger() {
        this.refetchTrigger = !this.refetchTrigger;
    }
}
