import { ERROR_DELETE } from 'constants/notificationMessage';

import useMemberCardTypeMultipleDelete from 'shared/data-hook/memberCardType/delete/multiple/useMemberCardTypeMultipleDelete';
import { useStore } from 'store';
import { MemberCardTypeT } from 'types/MemberCardType';

import { useNotification } from '../../useNotification';

const useDeleteMultipleMemberCardType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMultipleMemberCardType: actionDelete } = useMemberCardTypeMultipleDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const deleteMultipleMemberCardType = async (memberCardTypeIds: [MemberCardTypeT['id']]) => {
        const { affectedRows } = await actionDelete(memberCardTypeIds);

        if (error || !affectedRows) {
            showError(ERROR_DELETE);

            return;
        }

        toggleRefetchTrigger();
        showSuccess(`Строк удалено: ${affectedRows}`);
    };

    return {
        deleteMultipleMemberCardType,
    };
};

export default useDeleteMultipleMemberCardType;
