import { useLazyQuery } from '@apollo/client';
import {
    CLIENT_VIEWS_QUERY,
    ClientViewsResponseT,
} from 'shared/graphql/query/clientView/ClientViewQuery';
import { EMPTY_CLIENT_VIEWS_RESULT } from 'types/ClientView';
import { FetchDataParams } from 'types/DataGrid';

import decodeDTO from '../../../utils/DTO/decodeDTO';
import { filterData } from '../../../utils/filter/filterData';
import { ClientViewsDTO } from '../DTO/ClientViewsDTO';

import { ClientViewsDataT } from './ClientViewsData';

const useClientViews = (): ClientViewsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<ClientViewsResponseT>(CLIENT_VIEWS_QUERY, {
        fetchPolicy: 'network-only',
    });

    const decodedClientViewsData = decodeDTO(
        ClientViewsDTO,
        EMPTY_CLIENT_VIEWS_RESULT,
        (clientViews) => clientViews,
        {
            clientViews: data?.clientViews,
            clientViewsCount: data?.clientViewsCount,
        },
    );

    const fetchClientViews = async ({
        limit,
        offset,
        orderBy = { birthday_day: 'asc' },
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy: { ...orderBy },
                    where: filterData(where),
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchClientViews,
        loading,
        data: decodedClientViewsData.clientViews,
        count: decodedClientViewsData.clientViewsCount,
    };
};

export default useClientViews;
