import { useMutation } from '@apollo/client';
import { MemberCardTypeFormFields } from 'page/MemberCardType/components/formData';
import {
    MEMBER_CARD_TYPE_CREATE,
    MemberCardTypeCreateResponseDataT,
    MemberCardTypeCreateVar,
} from 'shared/graphql/mutation/memberCardType/MemberCardTypeCreate';

import { MemberCardTypeCreateDataT } from './MemberCardTypeCreateData';

const useMemberCardTypeCreate = (): MemberCardTypeCreateDataT => {
    const [create, { loading, error }] = useMutation<MemberCardTypeCreateResponseDataT, MemberCardTypeCreateVar>(
        MEMBER_CARD_TYPE_CREATE,
    );

    const createAction = async (input: MemberCardTypeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        title: input.title,
                        is_active: input.isActive,
                        expiration_period: input.expirationPeriod,
                        cashback_fiat_operations_limit: input.cashbackFiatOperationsLimit,
                        cashback_fiat_sum_limit: input.cashbackFiatSumLimit,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        memberCardTypeCreate: createAction,
        loading,
        error,
    };
};

export default useMemberCardTypeCreate;
