import { makeAutoObservable } from 'mobx';
import { EMPTY_SERVICE_APP, ServiceAppT } from 'types/serviceApp/ServiceApp';

export default class ServiceAppStore {
    public serviceApp: ServiceAppT = EMPTY_SERVICE_APP;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setServiceApp(serviceApp: ServiceAppT): void {
        this.serviceApp = serviceApp;
    }
}
