import { MODAL_TITLE_CREATE_MEMBER_CARD } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useClients from 'shared/data-hook/client/fetch/fetchAll/useClients';
import useMemberCardTypes from 'shared/data-hook/memberCardType/fetch/fetchAll/useMemberCardTypes';
import useOrganizations from 'shared/data-hook/organization/fetch/fetchAll/useOrganizations';
import useMemberCardCreate from 'shared/hook/memberCard/create/useCreateMemberCard';
import { useStore } from 'store';

import { getMemberCardValidationSchema, MEMBER_CARD_DEFAULT_VALUES, MemberCardFormFields } from './formData';
import MemberCardForm from './MemberCardForm';

const CreateMemberCardModal: FC = observer(() => {
    const {
        isOpen, onClose, setMemberCardTypes, setClients, setOrganizations,
    } = useStore('createMemberCardModal');
    const { fetchMemberCardTypes, data: memberCardTypesData, loading: memberCardTypesLoading } = useMemberCardTypes();
    const { fetchOrganizations, data: organizationsData, loading: organizationsLoading } = useOrganizations();
    const { fetchClients, data: clientsData, loading: clientsLoading } = useClients();
    const { createMemberCard, loading: loadingSave } = useMemberCardCreate();
    const loadingFetch = memberCardTypesLoading || organizationsLoading || clientsLoading;

    const {
        control, handleSubmit, reset, formState: { isDirty, isValid },
    } = useForm<MemberCardFormFields>({
        defaultValues: MEMBER_CARD_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getMemberCardValidationSchema()),
    });

    const handleCloseModal = () => {
        onClose();
        reset();
    };

    const handleCreateMemberCard = (data: MemberCardFormFields) => {
        createMemberCard(data).then(() => {
            handleCloseModal();
        });
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        fetchMemberCardTypes().then();
        fetchClients().then();
        fetchOrganizations().then();
    }, [isOpen]);

    useEffect(() => {
        setClients(clientsData);
    }, [clientsData, clientsLoading]);

    useEffect(() => {
        setMemberCardTypes(memberCardTypesData);
    }, [memberCardTypesData, memberCardTypesLoading]);

    useEffect(() => {
        setMemberCardTypes(memberCardTypesData);
    }, [memberCardTypesData, memberCardTypesLoading]);

    useEffect(() => {
        if (organizationsLoading) {
            return;
        }

        setOrganizations(organizationsData);
    }, [organizationsLoading]);

    return (
        <Modal open={ isOpen } maxWidth="md">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_MEMBER_CARD }
            </ModalTitle>
            <DialogContent dividers={ true }>
                {
                    loadingFetch && <Spinner />
                }
                <MemberCardForm
                    control={ control }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="error" onClick={ () => reset() }>Сбросить</Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleCreateMemberCard) }
                    autoFocus
                    disabled={ !isDirty || !isValid }
                    loading={ loadingSave }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateMemberCardModal;
