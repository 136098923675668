import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { COACH_HOURS_QUERY, CoachHoursResponseT } from 'shared/graphql/query/coachHours/CoachHoursQuery';
import { FetchCoachHoursDataParams } from 'types/CoachHours';

import { CoachHoursDTO } from '../DTO/CoachHoursDTO';

import { CoachHoursDataT } from './CoachHoursData';

const useCoachHours = (): CoachHoursDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CoachHoursResponseT>(
        COACH_HOURS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCoachHours = decodeDTO(
        CoachHoursDTO,
        0,
        (coachHours) => coachHours,
        {
            implementationsSumDuration: data?.implementationsSumDuration,
        },
    );

    const fetchCoachHours = async ({
        dateRange = {}, coach = {}, where = { items: [], linkOperator: 'and' },
    }: FetchCoachHoursDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    where: { ...filterData(where), ...dateRange, ...coach },
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        data: decodedCoachHours,
        fetchCoachHours,
        loading,
    };
};

export default useCoachHours;
