import { NotificationColor } from 'enums/NotificationColor';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';

export const useNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showSuccess = (message: string) => {
        enqueueSnackbar(<>{ parse(message) }</>, { variant: NotificationColor.success });
    };

    const showWarning = (message: string) => {
        enqueueSnackbar(<>{ parse(message) }</>, { variant: NotificationColor.warning });
    };
    const showError = (message: string) => {
        enqueueSnackbar(<>{ parse(message) }</>, { variant: NotificationColor.error });
    };

    const showInfo = (message: string) => {
        enqueueSnackbar(<>{ parse(message) }</>, { variant: NotificationColor.info });
    };

    return {
        showSuccess,
        showWarning,
        showError,
        showInfo,
    };
};
