import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { BulkOperationT } from 'types/BulkOperation';

const DTO = t.type({
    id: t.string,
    type: t.string,
    created_at: Nullable(t.string),
    finished_at: Nullable(t.string),
    status: t.string,
    error_message: Nullable(t.string),
    error_data: t.array(t.string),
    input_data: t.record(t.string, t.union([t.string, t.number, t.boolean])),
});

export type BulkOperationDTOT = t.TypeOf<typeof DTO>;

export const BulkOperationDTO = tPromise.extendDecoder<BulkOperationDTOT, BulkOperationT>(
    DTO,
    ({
        id, type, created_at, finished_at, status, error_message, error_data, input_data,
    }) => ({
        id,
        type,
        createdAt: created_at ? new Date(created_at) : null,
        finishedAt: finished_at ? new Date(finished_at) : null,
        status,
        errorMessage: error_message,
        errorData: error_data,
        inputData: input_data,
    }),
) as t.Type<BulkOperationT, BulkOperationDTOT>;
