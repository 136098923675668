import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { MEMBER_CARD_HEADER_DATA } from './HeaderData';

export const prepareMemberCardHeaderData = (priceSeasons: FilterOptionT[], memberCardTypes: FilterOptionT[]): GridColumns =>
    useMemo(() => mapFields(MEMBER_CARD_HEADER_DATA, {
        season: priceSeasons,
        title: memberCardTypes,
    }), [priceSeasons, memberCardTypes]);
