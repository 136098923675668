import { useMutation } from '@apollo/client';
import {
    GROUP_VIEW_DELETE,
    GroupViewDeleteResponseDataT,
    GroupViewDeleteVarT,
} from 'shared/graphql/mutation/groupView/GroupViewDelete';
import { GroupViewT } from 'types/GroupView';

import { GroupViewDeleteDataT } from './GroupViewDeleteData';

const useGroupViewDelete = (): GroupViewDeleteDataT => {
    const [deleteGroupView, { loading, error }] = useMutation<
        GroupViewDeleteResponseDataT,
        GroupViewDeleteVarT
    >(GROUP_VIEW_DELETE);

    const deleteAction = async (GroupViewId: GroupViewT['id']) => {
        try {
            const { data } = await deleteGroupView({
                variables: {
                    id: GroupViewId,
                },
            });

            return data;
        } catch (errorDelete) {
            console.error((errorDelete as Error).message); // eslint-disable-line
            throw errorDelete;
        }
    };

    return {
        deleteGroupView: deleteAction,
        loading,
        error,
    };
};

export default useGroupViewDelete;
