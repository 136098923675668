import { makeAutoObservable } from 'mobx';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export default class PriceSalesRoundStore {
    public priceSalesRoundList: PriceSalesRoundT[] = [];

    public priceSalesRound: PriceSalesRoundT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get priceSalesRounds(): PriceSalesRoundT[] {
        return this.priceSalesRoundList;
    }

    public setPriceSalesRound(priceSalesRound: PriceSalesRoundT) {
        this.priceSalesRound = priceSalesRound;
    }

    public setPriceSalesRounds(priceSalesRounds: PriceSalesRoundT[]) {
        this.priceSalesRoundList = priceSalesRounds;
    }
}
