import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export const isActiveIcon: FC<GridRenderCellParams> = (props) => {
    const { value } = props;

    return value ? <DoneIcon color="success" /> : <CloseIcon color="error" />;
};
