import { makeAutoObservable } from 'mobx';
import { ClientViewT } from 'types/ClientView';

export default class ClientViewStore {
    public clientViews: ClientViewT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setClientViews(clientViews: ClientViewT[], count?: number) {
        this.clientViews = clientViews;
        this.count = count ?? 0;
    }
}
