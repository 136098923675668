import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareImplementationHeaderData } from '../domain/prepareImplementationHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const ImplementationList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const {
        implementations, count,
    } = useStore('implementation');

    const { getFilterOption } = useStore('filterOptions');
    const serviceCategoryFilterOptions = getFilterOption('serviceCategory');

    const columns = prepareImplementationHeaderData(serviceCategoryFilterOptions);

    return (
        <DataGrid
            rows={ implementations }
            rowCount={ count }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
        />
    );
});

export default ImplementationList;
