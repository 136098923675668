import {
    PROPERTY_ACTIONS,
    PROPERTY_PERIOD,
    PROPERTY_STATUS,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import EditButton from 'shared/component/Button/EditButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeleteMemberCardType from 'shared/hook/memberCardType/delete/useDeleteMemberCardType';
import { CountForm } from 'shared/utils/CountForm';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { memberCardTypeDelete } = useDeleteMemberCardType();

    return (
        <>
            <EditButton id={ row.id } />
            <DeleteButton id={ row.id } title={ row.title } actionDelete={ memberCardTypeDelete } />
        </>
    );
};

export const MEMBER_CARD_TYPE_HEADER_DATA: GridColumns = [
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        flex: 1,
        type: 'string',
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        renderCell: isActiveIcon,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: PROPERTY_PERIOD,
        field: 'expirationPeriod',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        renderCell: (params) => <span>{ params.row.expirationPeriod } { CountForm(params.row.expirationPeriod, ['месяц', 'месяца', 'месяцев']) }</span>,

    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.4,
        align: 'center',
        headerAlign: 'center',
    },
];
