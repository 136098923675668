import * as React from 'react';
import { useCallback } from 'react';
import { DataGridPremiumProps, GridColumns } from '@mui/x-data-grid-premium';

import { DetailPanelContent } from './DetailPanelContent';

export const getDetailPanelContent = (detailPanelColumns: GridColumns = []) => useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
>(({ row }) => {
    return <DetailPanelContent row={ row } columns={ detailPanelColumns } />;
}, [detailPanelColumns]);
