import {
    ERROR_ORGANIZATION_LOCATION_UPDATE,
    SUCCESS_ORGANIZATION_LOCATION_UPDATE,
} from 'constants/notificationMessage';

import { OrganizationLocationFormFields } from 'page/OrganizationLocation/components/formData';
import useOrganizationLocationUpdate
    from 'shared/data-hook/organizationLocation/update/useOrganizationLocationUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { OrganizationLocationT } from 'types/OrganizationLocation';

const useUpdateOrganizationLocation = () => {
    const { error, loading, organizationLocationUpdate } = useOrganizationLocationUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateOrganizationLocation = async (input: OrganizationLocationFormFields, organizationLocationId: OrganizationLocationT['id']) => {
        const response = await organizationLocationUpdate(input, organizationLocationId);

        if (error || !response || !response.updateOrganizationLocation) {
            showError(ERROR_ORGANIZATION_LOCATION_UPDATE);
        } else {
            showSuccess(SUCCESS_ORGANIZATION_LOCATION_UPDATE);
        }
        toggleRefetchTrigger();
    };

    return {
        updateOrganizationLocation,
        loading,
    };
};

export default useUpdateOrganizationLocation;
