import { gql } from '@apollo/client';

export type OrganizationDeleteResponseDataT = {
    deleteOrganization: {
        id: string;
    };
} | null | undefined;

export type OrganizationDeleteVarT = {
    id: string;
};

export const ORGANIZATION_DELETE = gql`
    mutation DeleteOrganization($id: String = "") {
        deleteOrganization: delete_organization_by_pk(id: $id) {
            id
        }
    }
`;
