import { makeAutoObservable } from 'mobx';
import { ActivePriceSeasonT, PriceMemberCardT } from 'types/PriceMemberCard';
import { PriceSeasonT } from 'types/PriceSeason';
import { PriceTypeT } from 'types/PriceType';

export default class PriceMemberCardsStore {
    public priceMemberCardsList: PriceMemberCardT[] = [];

    public priceSeasons: PriceSeasonT[] = [];

    public priceTypeList: PriceTypeT[] = [];

    public count: number = 0;

    public activePriceType: PriceTypeT | undefined = undefined;

    public activePriceSeason: ActivePriceSeasonT = { id: '', title: '' };

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get priceMemberCards(): PriceMemberCardT[] {
        return this.priceMemberCardsList;
    }

    public get priceTypes(): PriceTypeT[] {
        return this.priceTypeList;
    }

    public setPriceMemberCards(priceMemberCards: PriceMemberCardT[], count?: number) {
        this.priceMemberCardsList = priceMemberCards;
        this.count = count ?? 0;
    }

    public setPriceTypes(priceTypes: PriceTypeT[]) {
        this.priceTypeList = priceTypes;
    }

    public setPriceSeasons(priceSeasons: PriceSeasonT[]) {
        this.priceSeasons = priceSeasons;
    }

    public setActivePriceType(priceType: PriceTypeT) {
        this.activePriceType = priceType;
    }

    public setActivePriceSeason(priceSeason: ActivePriceSeasonT) {
        this.activePriceSeason = priceSeason;
    }
}
