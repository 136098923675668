import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import Nullable from '../../../utils/DTO/Nullable';

const DTO = t.type({
    implementationsSumDuration: t.type({
        aggregate: t.type({
            sum: t.type({
                duration: Nullable(t.number),
            }),
        }),
    }),
});

export type CoachHoursDTOT = t.TypeOf<typeof DTO>;

export const CoachHoursDTO = tPromise.extendDecoder<CoachHoursDTOT, number>(
    DTO,
    ({
        implementationsSumDuration: {
            aggregate: {
                sum: {
                    duration,
                },
            },
        },
    }) => duration ?? 0,
) as t.Type<number, CoachHoursDTOT>;
