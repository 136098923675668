import { ERROR_COURT_TYPE_UPDATE, SUCCESS_COURT_TYPE_UPDATE } from 'constants/notificationMessage';

import { CourtTypeFormFields } from 'page/Court/components/formData';
import useUpdateCourtType from 'shared/data-hook/courtType/update/useUpdateCourtType';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtTypeT } from 'types/CourtType';

const useCourtTypeUpdate = () => {
    const { error, loading, courtTypeUpdate: updateAction } = useUpdateCourtType();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateCourtType = async (input: CourtTypeFormFields, courtTypeId: CourtTypeT['id']) => {
        const response = await updateAction(input, courtTypeId);

        if (error || !response || !response.updateCourtType) {
            showError(ERROR_COURT_TYPE_UPDATE);
        } else {
            showSuccess(SUCCESS_COURT_TYPE_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateCourtType,
        loading,
    };
};

export default useCourtTypeUpdate;
