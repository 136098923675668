import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Client from 'shared/data-hook/utils/DTO/Client';
import { decodeClientData } from 'shared/data-hook/utils/DTO/decodeClientData';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ImplementationT } from 'types/Implementation';

const DTO = t.type({
    id: t.string,
    client_datum: Client(),
    operation: Nullable(t.string),
    service: t.string,
    datetime: t.string,
    place: Nullable(t.string),
    performer: Nullable(t.string),
    comment: Nullable(t.string),
    rate: Nullable(t.number),
    total: Nullable(t.number),
    document: t.string,
    clientDatumByPayerId: Client(),
    duration: t.number,
    datetime_operation: Nullable(t.string),
    discount: Nullable(t.number),
    title: Nullable(t.string),
    payment_in_fiat: Nullable(t.number),
    payment_in_bonus: Nullable(t.number),
    service_category: Nullable(t.type({
        name: t.string,
    })),
    price_type: Nullable(t.type({
        title: t.string,
    })),
    user: Nullable(t.type({
        full_name: Nullable(t.string),
    })),
});

export type ImplementationDTOT = t.TypeOf<typeof DTO>;

export const ImplementationDTO = tPromise.extendDecoder<ImplementationDTOT, ImplementationT>(
    DTO,
    ({
        id,
        client_datum,
        operation,
        service,
        datetime,
        place,
        performer,
        comment,
        rate,
        total,
        document,
        clientDatumByPayerId,
        duration,
        datetime_operation,
        discount,
        title,
        payment_in_fiat,
        payment_in_bonus,
        service_category,
        price_type,
        user,
    }) => ({
        id,
        client: decodeClientData(client_datum),
        operation,
        service,
        datetime: new Date(datetime),
        place,
        performer,
        comment,
        rate,
        total,
        document,
        payer: decodeClientData(clientDatumByPayerId),
        duration,
        datetimeOperation: datetime_operation ? new Date(datetime_operation) : null,
        discount,
        title,
        paymentInFiat: payment_in_fiat,
        paymentInBonus: payment_in_bonus,
        serviceCategory: service_category ? service_category.name : null,
        priceType: price_type ? price_type.title : null,
        user: user ? user.full_name : null,
    }),
) as t.Type<ImplementationT, ImplementationDTOT>;
