import {
    FILTER_LINK_OPERATOR_PARAM,
    FILTER_PARAM,
    PAGE_PARAM,
    PAGE_SIZE_PARAM,
    SORT_PARAM,
} from 'constants/dataGrid';

import React from 'react';
import {
    GridFilterModel, GridLinkOperator, GridSortDirection, GridSortModel,
} from '@mui/x-data-grid-premium';
import queryString from 'query-string';

export const getQueryParams = (
    setPage: React.Dispatch<React.SetStateAction<number>>,
    setPageSize: React.Dispatch<React.SetStateAction<number>>,
    handleSortModelChange: (sort: GridSortModel) => void,
    handleFilterModelChange: (filter: GridFilterModel) => void,
    filterLinkOperator: GridLinkOperator,
    setFilterLinkOperator: React.Dispatch<React.SetStateAction<GridLinkOperator>>,
) => {
    const search = window.location.search.substring(1);
    const queryParams = queryString.parse(search);

    const setParameterToSortModel = (queryParameter: any, sortModel: GridSortModel) => {
        const [field, sortValue] = (queryParameter as string).split(';');

        sortModel.push({
            field,
            sort: sortValue as GridSortDirection,
        });

        handleSortModelChange(sortModel);
    };

    const setParameterToFilterModel = (queryParameter: any, filterModel: GridFilterModel, index?: number) => {
        const [columnField, operatorValue, value] = (queryParameter as string).split(';');

        filterModel.items.push({
            id: index ?? 0,
            columnField,
            operatorValue,
            value: operatorValue === 'isAnyOf' ? value.split(',') : value,
        });

        handleFilterModelChange(filterModel);
    };

    Object.keys(queryParams).forEach((item) => {
        switch (item) {
            case PAGE_PARAM:
                setPage(parseInt((queryParams[item]) as string, 10) - 1);
                break;
            case PAGE_SIZE_PARAM:
                setPageSize(parseInt(queryParams[item] as string, 10));
                break;
            case FILTER_LINK_OPERATOR_PARAM:
                setFilterLinkOperator(queryParams[item] as GridLinkOperator);
                break;
            case SORT_PARAM: {
                const sort: GridSortModel = [];

                if (typeof queryParams[item] === 'string') {
                    setParameterToSortModel(queryParams[item], sort);

                    return;
                }

                (queryParams[item] as []).forEach((sortItem) => {
                    setParameterToSortModel(sortItem, sort);
                });
                break;
            }
            case FILTER_PARAM: {
                const filter: GridFilterModel = {
                    items: [],
                    linkOperator: filterLinkOperator,
                };

                if (typeof queryParams[item] === 'string') {
                    setParameterToFilterModel(queryParams[item], filter);

                    return;
                }

                (queryParams[item] as []).forEach((filterItem, index) => {
                    setParameterToFilterModel(filterItem, filter, index);
                });
                break;
            }
        }
    });
};
