import React, { FC, useEffect } from 'react';
import { Spinner } from 'shared/component/Spinner';
import useLogout from 'shared/hook/useLogout';

const Logout: FC = () => {
    const logout = useLogout();

    useEffect(() => {
        logout().then();
    }, []);

    return <Spinner />;
};

export default Logout;
