import { ERROR_COURT_UPDATE, SUCCESS_COURT_UPDATE } from 'constants/notificationMessage';

import { CourtFormFields } from 'page/Court/components/CourtSetting/components/formData';
import useCourtUpdate from 'shared/data-hook/court/update/useCourtUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtT } from 'types/Court';

const useUpdateCourt = () => {
    const { error, loading, courtUpdate } = useCourtUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateCourt = async (input: CourtFormFields, courtId: CourtT['id']) => {
        const response = await courtUpdate(input, courtId);

        if (error || !response || !response.updateCourt) {
            showError(ERROR_COURT_UPDATE);
        } else {
            showSuccess(SUCCESS_COURT_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateCourt,
        loading,
    };
};

export default useUpdateCourt;
