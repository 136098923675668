import { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

type PropsT = {
    id: string;
};

const MemberCardEditButton: FC<PropsT> = (props) => {
    const { id } = props;

    const { open, setId } = useStore('updateMemberCardModal');

    const handleOpenModal = () => {
        setId(id);
        open();
    };

    return (
        <Tooltip title="Редактировать" placement="top">
            <IconButton onClick={ handleOpenModal }>
                <EditIcon color="primary" />
            </IconButton>
        </Tooltip>
    );
};

export default MemberCardEditButton;
