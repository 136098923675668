import { PAGE_TITLE_BULK_OPERATIONS } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useBulkOperations from 'shared/data-hook/bulkOperation/fetch/fetchAll/useBulkOperations';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import BulkOperationsList from './components/BulkOperationsList';
import { BULK_OPERATIONS_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: bulkOperationsData, loading: bulkOperationsLoading, count: rowCount, fetchBulkOperations,
    } = useBulkOperations();
    const { setBulkOperations } = useStore('bulkOperation');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_BULK_OPERATIONS);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchBulkOperations, refetchTrigger, BULK_OPERATIONS_HEADER_DATA);

    useEffect(() => {
        setBulkOperations(bulkOperationsData, rowCount);
    }, [bulkOperationsData, bulkOperationsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <BulkOperationsList
                loading={ bulkOperationsLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
