import React, { FC, MouseEventHandler } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

import SwitchIsActiveModal from '../../Modal/SwitchIsActiveModal';

type PropsT = {
    id: string;
    title: string;
    actionDelete: (id: string, state: boolean) => Promise<any>;
    loading: boolean;
};

const DeleteButton: FC<PropsT> = (props) => {
    const {
        id, title, actionDelete, loading,
    } = props;
    const { onOpen } = useStore('deleteItemModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id || 'id');
    };

    const onDeleteButtonClick = async (): Promise<any> => {
        return actionDelete(id, false);
    };

    return (
        <>
            <Tooltip title="Деактивировать" placement="top">
                <IconButton onClick={ handleButtonClick }>
                    <DeleteIcon color="error" />
                </IconButton>
            </Tooltip>
            <SwitchIsActiveModal
                loading={ loading }
                id={ id || 'id' }
                title={ title }
                onDeleteButtonClick={ onDeleteButtonClick }
            />
        </>
    );
};

export default DeleteButton;
