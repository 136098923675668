import { gql } from '@apollo/client';

export type GroupViewCreatePayloadT = {
    name: string;
    category_id: string | null;
    age_type_id: string | null;
    min_size: number;
    avg_size: number;
    max_size: number;
    clients_for_main_coach: number;
    ignore_busy_coaches: boolean;
    group_constant_price: boolean;
    correction_factors: Array<Record<string, number>>;
}

export type GroupViewCreateResponseDataT = {
    createGroupView: {
        id: string;
    };
} | null | undefined;

export type GroupViewCreateVarT = {
    object: GroupViewCreatePayloadT;
}

export const GROUP_VIEW_CREATE = gql`
    mutation GroupViewCreate($object: group_view_insert_input = {}) {
        insert_group_view: insert_group_view_one(
            object: $object
        ) {
            id
        }
    }
`;
