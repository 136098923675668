import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { PaymentsT } from 'types/Payment';

import { PaymentDTO } from './PaymentDTO';

const DTO = t.type({
    payments: t.array(PaymentDTO),
    paymentsCount: AggregateDTO,
});

type PaymentsDTOT = t.TypeOf<typeof DTO>;

export const PaymentsDTO = tPromise.extendDecoder<PaymentsDTOT, PaymentsT>(
    DTO,
    ({
        payments,
        paymentsCount,
    }) => ({
        payments,
        paymentsCount,
    }),
) as t.Type<PaymentsT, PaymentsDTOT>;
