import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PaymentMethodT } from 'types/PaymentMethod';

export type PaymentMethodsResponseT = {
    paymentMethods: PaymentMethodT[];
    paymentMethodsCount: AggregateT;
}

export type PaymentMethodResponseT = {
    paymentMethod: PaymentMethodT;
}

export const PAYMENT_METHOD_FILTER_OPTIONS_QUERY = gql`
    query PaymentMethodFilterOptions {
        filterOptions: payment_method(
            order_by: { title: asc }
        ) {
            value: id
            label: title
        }
    }
`;

export const PAYMENT_METHODS_QUERY = gql`
    query PaymentMethods(
        $limit: Int,
        $offset: Int,
        $orderBy: payment_method_order_by!,
        $where: payment_method_bool_exp!,
    ) {
        paymentMethods: payment_method(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            title
            position
            is_active
            payment_types(order_by: {position: asc}) {
                id
                title
                position
                is_active
                default_fiat_payment
                default_bonus_payment
                fiat_payment_divisible
                bonus_payment_divisible
                payment_method_id
                should_confirm_by_sms
                allow_negative_payments
            }
        }
        paymentMethodsCount: payment_method_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const PAYMENT_METHOD_QUERY = gql`
    query PaymentMethod($id: String!) {
        paymentMethod: payment_method_by_pk(id: $id) {
            id
            title
            position
            is_active
        }
    }
`;
