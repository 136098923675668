enum ImplementationDocumentType {
    Group = 'group',
    Rent = 'rent',
    Other = 'other',
}

export const IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY: { [key: string]: string } = {
    [ImplementationDocumentType.Group]: 'Группа',
    [ImplementationDocumentType.Rent]: 'Аренда',
    [ImplementationDocumentType.Other]: 'Другое',
};

export const IMPLEMENTATION_DOCUMENT_TYPE_OPTIONS = [
    {
        value: ImplementationDocumentType.Group,
        label: IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY[ImplementationDocumentType.Group],
    },
    {
        value: ImplementationDocumentType.Rent,
        label: IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY[ImplementationDocumentType.Rent],
    },
    {
        value: ImplementationDocumentType.Other,
        label: IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY[ImplementationDocumentType.Other],
    },
];
