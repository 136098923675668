import React, { FC, MouseEventHandler } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    onDeleteButtonClick: () => Promise<any>;
    loading: boolean;
};

const SwitchIsActiveModal: FC<PropsT> = observer((props) => {
    const {
        id, title, onDeleteButtonClick, loading,
    } = props;
    const { deletedItemId, onClose } = useStore('deleteItemModal');

    const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onDeleteButtonClick().then(() => {
            onClose();
        });
    };

    return (
        <Modal open={ deletedItemId === id } onClose={ onClose }>
            <DialogTitle>Вы действительно хотите деактивировать { title || 'выбранный элемент' }?</DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <LoadingButton
                    loading={ loading }
                    onClick={ handleDeleteButtonClick }
                    variant="contained"
                    autoFocus
                    color="error"
                >деактивировать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default SwitchIsActiveModal;
