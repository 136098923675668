import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { ImplementationT } from 'types/Implementation';

export type ImplementationsResponseT = {
    implementations: ImplementationT[];
    implementationsCount: AggregateT;
};

export const IMPLEMENTATIONS_QUERY = gql`
    query ImplementationsQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: implementation_order_by! = {},
        $where: implementation_bool_exp! = {}
    ) {
        implementations: implementation(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            client_datum {
                client {
                    first_name
                    last_name
                    middle_name
                }
            }
            operation
            service
            datetime
            place
            performer
            comment
            rate
            title
            total
            document
            clientDatumByPayerId {
                client {
                    first_name
                    last_name
                    middle_name
                }
            }
            duration
            datetime_operation
            discount
            payment_in_fiat
            payment_in_bonus
            service_category {
                name
            }
            price_type {
                title
            }
            user {
                full_name
            }
        }
        implementationsCount: implementation_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
