import { makeAutoObservable } from 'mobx';
import { ImplementationT } from 'types/Implementation';
import { PriceTypeT } from 'types/PriceType';

export default class ImplementationStore {
    public implementationList: ImplementationT[] = [];

    public count: number = 0;

    public priceTypes: PriceTypeT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get implementations(): ImplementationT[] {
        return this.implementationList;
    }

    public setImplementations(implementations: ImplementationT[], count?: number) {
        this.implementationList = implementations;
        this.count = count ?? 0;
    }
}
