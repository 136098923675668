import { useLazyQuery } from '@apollo/client';
import { CourtTypeDTO } from 'shared/data-hook/courtType/fetch/DTO/CourtTypeDTO';
import { CourtTypeDataT } from 'shared/data-hook/courtType/fetch/fetchOne/CourtTypeData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { COURT_TYPE_QUERY, CourtTypeResponseT } from 'shared/graphql/query/courtType/CourtTypeQuery';
import { CourtTypeT, EMPTY_COURT_TYPE_RESULT } from 'types/CourtType';

const useCourtType = (): CourtTypeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<CourtTypeResponseT>(
        COURT_TYPE_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchCourtType = async (id: string): Promise<CourtTypeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                CourtTypeDTO,
                EMPTY_COURT_TYPE_RESULT,
                (courtType) => courtType,
                data?.courtType,
            );
        } catch {
            return EMPTY_COURT_TYPE_RESULT;
        }
    };

    return {
        fetchCourtType,
        loading,
        error,
    };
};

export default useCourtType;
