import { ClientFullNameT } from './Client';

export type ImplementationDocumentT = {
    id: string;
    datetime: Date | null;
    number: string;
    type: string | null;
    serviceCategory: string;
    group: string | null;
    client: ClientFullNameT | null;
    comment: string | null;
    groupMainCoach: string | null;
};

export type ImplementationDocumentsT = {
    implementationDocuments: ImplementationDocumentT[];
    implementationDocumentsCount: number;
};

export const EMPTY_IMPLEMENTATION_DOCUMENTS_RESULT: ImplementationDocumentsT = {
    implementationDocuments: [],
    implementationDocumentsCount: 0,
};
