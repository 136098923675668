import { HasuraOperatorT } from 'constants/gqlOperators';

import { buildNestedObject } from '../../../utils/buildNestedObject';

enum singleSelectGridOperators {
    is = 'is',
    not = 'not',
    isAnyOf = 'isAnyOf',
    jsonNot = 'jsonNot',
    jsonIs = 'jsonIs',
    jsonIsAnyOf = 'jsonIsAnyOf',
}

type singleSelectGridOperatorsT = keyof typeof singleSelectGridOperators

export const filterSingleSelectField = (filter: any, value: string | string[], gridOperator: singleSelectGridOperatorsT, hasuraOperator: HasuraOperatorT, hasuraFilterFields: string[], field: string) => {
    const updatedFilter = { ...filter };
    let filterValue = {};

    if (!value) {
        return;
    }

    switch (gridOperator) {
        case singleSelectGridOperators.is:
            filterValue = { [field]: { _eq: value } };
            break;
        case singleSelectGridOperators.jsonIs:
            filterValue = { [field]: { _eq: [value] } };
            break;
        case singleSelectGridOperators.not:
            filterValue = { [field]: { _neq: value } };
            break;
        case singleSelectGridOperators.jsonNot:
            filterValue = { [field]: { _neq: [value] } };
            break;
        case singleSelectGridOperators.jsonIsAnyOf:
            if (!Array.isArray(value) || value.length === 0) {
                break;
            }
            filterValue = { [field]: { _contained_in: value, _neq: [] } };
            break;
        case singleSelectGridOperators.isAnyOf:
            if (!Array.isArray(value) || value.length === 0) {
                break;
            }
            filterValue = { [field]: { _in: value } };
            break;
    }

    if (!updatedFilter[hasuraOperator][hasuraOperator]) {
        updatedFilter[hasuraOperator][hasuraOperator] = [];
    }

    updatedFilter[hasuraOperator][hasuraOperator].push(buildNestedObject(hasuraFilterFields, { ...filterValue }));
};
