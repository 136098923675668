import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CourtTypeDTO } from 'shared/data-hook/courtType/fetch/DTO/CourtTypeDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { CourtTypesT } from 'types/CourtType';

const DTO = t.type({
    courtTypes: t.array(CourtTypeDTO),
    courtTypesCount: AggregateDTO,
});

type CourtTypeDTOT = t.TypeOf<typeof DTO>;

export const CourtTypesDTO = tPromise.extendDecoder<CourtTypeDTOT, CourtTypesT>(
    DTO,
    ({
        courtTypes,
        courtTypesCount,
    }) => ({
        courtTypes,
        courtTypesCount,
    }),
) as t.Type<CourtTypesT, CourtTypeDTOT>;
