import {
    PAGE_TITLE_BULK_OPERATIONS,
    PAGE_TITLE_CLIENT, PAGE_TITLE_COACH_HOURS_REPORT, PAGE_TITLE_COACHES_IN_GROUPS,
    PAGE_TITLE_COURT_CATEGORY,
    PAGE_TITLE_COURT_VIDEO_ANALYTICS,
    PAGE_TITLE_IMPLEMENTATION,
    PAGE_TITLE_IMPLEMENTATION_DOCUMENT,
    PAGE_TITLE_MEMBER_CARD_TYPE,
    PAGE_TITLE_ORGANIZATION,
    PAGE_TITLE_ORGANIZATION_LOCATION, PAGE_TITLE_PAYMENT,
    PAGE_TITLE_PAYMENT_DOCUMENT, PAGE_TITLE_PAYMENT_SETTING,
    PAGE_TITLE_PRICE,
    PAGE_TITLE_PRICE_MEMBER_CARDS,
    PAGE_TITLE_PRICE_TYPE,
    PAGE_TITLE_SCHEDULE_SLOT_RESERVE,
    PAGE_TITLE_SERVICE_APP,
    PAGE_TITLE_SERVICE_CATEGORY,
} from 'constants/pageTitles';
import {
    AGE_GROUPS, BONUS, BONUS_DOCUMENT, BULK_OPERATIONS,
    CALCULATION, CLIENT, CLIENT_BONUS_TRANSACTION,
    CLIENT_DELAY_OF_PAYMENT,
    CLIENT_TRANSACTION,
    COACH as COACH_LINK,
    COACH_CATEGORY, COACH_HOURS,
    COACH_SALARY, COACHES_IN_GROUPS,
    COURT as COURT_LINK,
    COURT_CATEGORY,
    COURT_VIDEO_ANALYTICS,
    EMPLOYEE,
    GROUP as GROUP_LINK,
    IMPLEMENTATION, IMPLEMENTATION_DOCUMENTS, MEMBER_CARD, MEMBER_CARD_TYPE,
    ORGANIZATION as ORGANIZATION_LINK,
    ORGANIZATION_LOCATION,
    PAYMENT,
    PAYMENT_DOCUMENTS,
    PAYMENT_SETTINGS, PRICE_MEMBER_CARDS,
    PRICE_SEASON,
    PRICE_TYPE,
    PRT,
    SCHEDULE_SLOT_RESERVE,
    SERVICE_APP,
    SERVICE_CATEGORY,
    USER,
    WAITING_LIST,
} from 'constants/routes';

import { MenuItemT } from 'types/MenuItems';

export const PRICE_MANAGEMENT: MenuItemT = {
    id: 1,
    header: PAGE_TITLE_PRICE,
    rootLink: PRICE_SEASON,
    links: [
        {
            path: PRICE_TYPE,
            header: PAGE_TITLE_PRICE_TYPE,
        },
        {
            path: SERVICE_CATEGORY,
            header: PAGE_TITLE_SERVICE_CATEGORY,
        },
        {
            path: PRICE_MEMBER_CARDS,
            header: PAGE_TITLE_PRICE_MEMBER_CARDS,
        },
    ],
};

export const MEMBERSHIP_CARD_MANAGEMENT: MenuItemT = {
    id: 2,
    header: 'Членские карты',
    rootLink: MEMBER_CARD,
    links: [
        {
            path: MEMBER_CARD_TYPE,
            header: PAGE_TITLE_MEMBER_CARD_TYPE,
        },
    ],
};

export const SCHEDULE: MenuItemT = {
    id: 3,
    header: 'Расписание',
    rootLink: SCHEDULE_SLOT_RESERVE,
    links: [
        {
            path: SCHEDULE_SLOT_RESERVE,
            header: PAGE_TITLE_SCHEDULE_SLOT_RESERVE,
        },
        {
            path: IMPLEMENTATION,
            header: PAGE_TITLE_IMPLEMENTATION,
        },
        {
            path: IMPLEMENTATION_DOCUMENTS,
            header: PAGE_TITLE_IMPLEMENTATION_DOCUMENT,
        },
        {
            path: CALCULATION,
            header: 'Предрасчеты',
        },
        {
            path: WAITING_LIST,
            header: 'Листы ожидания',
        },
    ],
};

export const PAYMENTS: MenuItemT = {
    id: 4,
    header: PAGE_TITLE_PAYMENT,
    rootLink: PAYMENT,
    links: [
        {
            path: PAYMENT_DOCUMENTS,
            header: PAGE_TITLE_PAYMENT_DOCUMENT,
        },
        {
            path: PAYMENT_SETTINGS,
            header: PAGE_TITLE_PAYMENT_SETTING,
        },
    ],
};

export const BONUSES: MenuItemT = {
    id: 5,
    header: 'Бонусы',
    rootLink: BONUS,
    links: [
        {
            path: BONUS,
            header: 'Бонусы',
        },
        {
            path: BONUS_DOCUMENT,
            header: 'Бонусные документы',
        },
    ],
};

export const CLIENTS: MenuItemT = {
    id: 6,
    header: PAGE_TITLE_CLIENT,
    rootLink: CLIENT,
    links: [
        {
            path: CLIENT_TRANSACTION,
            header: 'Транзакции клиентов',
        },
        {
            path: CLIENT_BONUS_TRANSACTION,
            header: 'Бонусные транзакции клиентов',
        },
        {
            path: CLIENT_DELAY_OF_PAYMENT,
            header: 'Отсрочка платежа',
        },
    ],
};

export const COACH: MenuItemT = {
    id: 7,
    header: 'Тренеры',
    rootLink: COACH_LINK,
    links: [
        {
            path: COACH_LINK,
            header: 'Тренеры',
        },
        {
            path: COACH_CATEGORY,
            header: 'Категории тренеров',
        },
        {
            path: COACH_SALARY,
            header: 'Вознаграждение тренера',
        },
    ],
};

export const GROUP: MenuItemT = {
    id: 8,
    header: 'Группы',
    rootLink: GROUP_LINK,
};

export const COURT: MenuItemT = {
    id: 9,
    header: 'Корты',
    rootLink: COURT_LINK,
    links: [
        {
            path: COURT_CATEGORY,
            header: PAGE_TITLE_COURT_CATEGORY,
        },
        {
            path: COURT_VIDEO_ANALYTICS,
            header: PAGE_TITLE_COURT_VIDEO_ANALYTICS,
        },
    ],
};

export const ORGANIZATION: MenuItemT = {
    id: 10,
    header: 'Организация',
    rootLink: ORGANIZATION_LINK,
    links: [
        {
            path: ORGANIZATION_LINK,
            header: PAGE_TITLE_ORGANIZATION,
        },
        {
            path: ORGANIZATION_LOCATION,
            header: PAGE_TITLE_ORGANIZATION_LOCATION,
        },
    ],
};

export const ADMINISTRATION: MenuItemT = {
    id: 11,
    header: 'Администрирование',
    rootLink: BULK_OPERATIONS,
    links: [
        {
            path: BULK_OPERATIONS,
            header: PAGE_TITLE_BULK_OPERATIONS,
        },
        {
            path: SERVICE_APP,
            header: PAGE_TITLE_SERVICE_APP,
        },
        {
            path: USER,
            header: 'Пользователи',
        },
        {
            path: EMPLOYEE,
            header: 'Сотрудники',
        },
    ],
};

export const OTHER_DIRECTORIES: MenuItemT = {
    id: 12,
    header: 'Прочие справочники',
    rootLink: AGE_GROUPS,
    links: [
        {
            path: AGE_GROUPS,
            header: 'Возрастные группы',
        },
        {
            path: PRT,
            header: 'Группы ПРТ',
        },
    ],
};

export const REPORTS: MenuItemT = {
    id: 13,
    header: 'Отчеты',
    rootLink: COACHES_IN_GROUPS,
    links: [
        {
            path: COACHES_IN_GROUPS,
            header: PAGE_TITLE_COACHES_IN_GROUPS,
        },
        {
            path: COACH_HOURS,
            header: PAGE_TITLE_COACH_HOURS_REPORT,
        },
    ],
};

// export const MENU_ITEMS = [PRICE_MANAGEMENT, MEMBERSHIP_CARD_MANAGEMENT, SCHEDULE, PAYMENTS, BONUSES, CLIENTS, COACH, GROUP, COURT, ORGANIZATION, ADMINISTRATION, OTHER_DIRECTORIES];
export const MENU_ITEMS = [
    PRICE_MANAGEMENT,
    MEMBERSHIP_CARD_MANAGEMENT,
    SCHEDULE,
    CLIENTS,
    COURT,
    GROUP,
    ORGANIZATION,
    PAYMENTS,
    ADMINISTRATION,
    REPORTS,
];
