import { PROPERTY_COURTS_AREA, PROPERTY_POSITION, PROPERTY_TITLE } from 'constants/propertyNames';

import { object, string } from 'yup';

export type CourtCategoryFormFields = {
    name: string;
    position: number;
    courts: string[];
}

export const COURT_CATEGORY_DEFAULT_VALUES: CourtCategoryFormFields = {
    name: '',
    position: 0,
    courts: [],
};

export const COURT_CATEGORY_LABEL_DATA = {
    name: PROPERTY_TITLE,
    position: PROPERTY_POSITION,
    courts: PROPERTY_COURTS_AREA,
};

export enum COURT_CATEGORY_FIELD_KEYS {
    name = 'name',
    position = 'position',
    courts = 'courts',
}

export const COURT_CATEGORY_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
}).required();

export const getCourtCategoryValidationSchema = () => COURT_CATEGORY_VALIDATION_SCHEMA;
