import React, { FC, memo, ReactNode } from 'react';
import { Button } from '@mui/material';

import { ControlPanelWrap } from './domain/Styled';

type PropsT = {
    children?: ReactNode | ReactNode[];
}
const ControlPanel: FC<PropsT> = (props) => {
    const { children } = props;

    return (
        <ControlPanelWrap direction="row" spacing={ 2 }>
            { children || <Button size="small" sx={ { visibility: 'hidden' } }>добавить</Button> }
        </ControlPanelWrap>
    );
};

export default memo(ControlPanel);
