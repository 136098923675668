import { GridSortModel } from '@mui/x-data-grid-premium';
import { decamelize } from 'humps';
import { CustomHeaderData } from 'types/DataGrid';

import { buildNestedObject } from './buildNestedObject';

export const addParamsToSortModel = (sortModel: GridSortModel, headerData: CustomHeaderData[]) => {
    const sort = {};

    sortModel.forEach((sortItem) => {
        const matchingData = headerData.find((data) => data.field === sortItem.field);

        if (matchingData) {
            const fields = matchingData.hasuraSortFields ? [...matchingData.hasuraSortFields] : [decamelize(sortItem.field)];

            Object.assign(sort, buildNestedObject(fields, sortItem.sort));
        }
    });

    if (Object.keys(sort).length > 0) {
        return sort;
    }

    return undefined;
};
