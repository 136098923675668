import { OPERATION_SELL } from 'constants/operations';
import { SERVICE_GROUP_RENT } from 'constants/services';

import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import {
    COACHES_IN_GROUPS_QUERY,
    CoachesInGroupsResponseT,
} from 'shared/graphql/query/coachesInGroup/CoachesInGroupQuery';
import { COACHES_IN_GROUP_DATETIME_ASC, EMPTY_COACHES_IN_GROUPS_RESULT } from 'types/CoachesInGroup';
import { FetchDataParams } from 'types/DataGrid';

import decodeDTO from '../../../utils/DTO/decodeDTO';
import { filterData } from '../../../utils/filter/filterData';
import { CoachesInGroupsDTO } from '../DTO/CoachesInGroupsDTO';

import { CoachesInGroupsDataT } from './CoachesInGroupsData';

const defaultWhere = {
    type: {
        _eq: 'group',
    },
    implementations: {
        service: {
            _eq: SERVICE_GROUP_RENT,
        },
        operation: {
            _eq: OPERATION_SELL,
        },
        cancel_id: {
            _is_null: true,
        },
    },
};

const useCoachesInGroups = (): CoachesInGroupsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CoachesInGroupsResponseT>(
        COACHES_IN_GROUPS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCoachesInGroupsData = decodeDTO(
        CoachesInGroupsDTO,
        EMPTY_COACHES_IN_GROUPS_RESULT,
        (coachesInGroups) => coachesInGroups,
        {
            coachesInGroups: data?.coachesInGroups,
            coachesInGroupsCount: data?.coachesInGroupsCount,
        },
    );

    const fetchCoachesInGroups = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy: isEmpty(orderBy) ? { ...COACHES_IN_GROUP_DATETIME_ASC } : orderBy,
                    where: {
                        ...filterData(where),
                        ...defaultWhere,
                    },
                    service: SERVICE_GROUP_RENT,
                    operation: OPERATION_SELL,
                },
            });
        } catch (error) {
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchCoachesInGroups,
        loading,
        data: decodedCoachesInGroupsData.coachesInGroups,
        count: decodedCoachesInGroupsData.coachesInGroupsCount,
    };
};

export default useCoachesInGroups;
