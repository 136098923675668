import { API_DATE_FORMAT } from 'constants/date';
import { HasuraOperatorT } from 'constants/gqlOperators';

import dayjs from 'dayjs';

import { buildNestedObject } from '../../../utils/buildNestedObject';

enum dateGridOperators {
    is = 'is',
    not = 'not',
    after = 'after',
    onOrAfter = 'onOrAfter',
    before = 'before',
    onOrBefore = 'onOrBefore',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
}

type dateGridOperatorsT = keyof typeof dateGridOperators

export const filterDateField = (filter: any, currentDay: string, gridOperator: dateGridOperatorsT, hasuraOperator: HasuraOperatorT, hasuraFilterFields: string[], field: string) => {
    const updatedFilter = { ...filter };
    const nextDay = dayjs(currentDay).add(1, 'day').format(API_DATE_FORMAT);
    let filterValue = {};

    if (!currentDay && gridOperator !== dateGridOperators.isEmpty && gridOperator !== dateGridOperators.isNotEmpty) {
        return;
    }

    switch (gridOperator) {
        case dateGridOperators.is:
            filterValue = { [field]: { _gte: currentDay, _lt: nextDay } };
            break;
        case dateGridOperators.not:
            filterValue = { _or: [{ [field]: { _lt: currentDay } }, { [field]: { _gte: nextDay } }, { [field]: { _is_null: true } }] };
            break;
        case dateGridOperators.after:
            filterValue = { [field]: { _gte: nextDay } };
            break;
        case dateGridOperators.onOrAfter:
            filterValue = { [field]: { _gte: currentDay } };
            break;
        case dateGridOperators.before:
            filterValue = { [field]: { _lt: currentDay } };
            break;
        case dateGridOperators.onOrBefore:
            filterValue = { [field]: { _lt: nextDay } };
            break;
        case dateGridOperators.isEmpty:
            filterValue = { [field]: { _is_null: true } };
            break;
        case dateGridOperators.isNotEmpty:
            filterValue = { [field]: { _is_null: false } };
            break;
    }

    if (!updatedFilter[hasuraOperator][hasuraOperator]) {
        updatedFilter[hasuraOperator][hasuraOperator] = [];
    }

    updatedFilter[hasuraOperator][hasuraOperator].push(buildNestedObject(hasuraFilterFields, { ...filterValue }));
};
