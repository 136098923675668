import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CoachesInGroupsT } from 'types/CoachesInGroup';

import { AggregateDTO } from '../../../utils/DTO/AggregateDTO';

import { CoachesInGroupDTO } from './CoachesInGroupDTO';

const DTO = t.type({
    coachesInGroups: t.array(CoachesInGroupDTO),
    coachesInGroupsCount: AggregateDTO,
});

type CoachesInGroupsDTOT = t.TypeOf<typeof DTO>;

export const CoachesInGroupsDTO = tPromise.extendDecoder<CoachesInGroupsDTOT, CoachesInGroupsT>(
    DTO,
    ({
        coachesInGroups,
        coachesInGroupsCount,
    }) => ({
        coachesInGroups,
        coachesInGroupsCount,
    }),
) as t.Type<CoachesInGroupsT, CoachesInGroupsDTOT>;
