import {
    DATA_GRID_FILTER_MODEL_INITIAL_STATE,
    DATA_GRID_INITIAL_PAGE,
    DATA_GRID_SORT_MODEL_INITIAL_STATE,
} from 'constants/dataGrid';
import { PRICE_SEASON } from 'constants/routes';

import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import usePrices from 'shared/data-hook/price/fetch/fetchAll/usePrices';
import usePriceSalesRounds from 'shared/data-hook/priceSalesRound/fetch/fetchAll/usePriceSalesRounds';
import usePriceSeason from 'shared/data-hook/priceSeason/fetch/fetchOne/usePriceSeason';
import usePriceTypes from 'shared/data-hook/priceType/fetch/fetchAll/usePriceTypes';
import { COACH_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/coachCategory/CoachCategoryQuery';
import { COURT_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/courtType/CourtTypeQuery';
import { GROUP_VIEW_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/groupView/GroupViewQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';
import { PRICE_TYPES_IS_ACTIVE, PRICE_TYPES_POSITION_ASC } from 'types/PriceType';

import CreatePriceModal from './components/CreatePriceModal';
import TabContext from './components/TabContext';
import UpdatePriceRate from './components/UpdatePriceRate/UpdatePriceRate';

const List: FC = observer(() => {
    const [shouldUpdateGetParams, setShouldUpdateGetParams] = useState(true);
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { id: priceSeasonId, priceTypeId } = useParams();
    const navigate = useNavigate();
    const [tab, setTab] = useState(priceTypeId ?? '');
    const {
        setPrices, setPriceTypes, setPriceSeason, priceSeason,
    } = useStore('price');
    const { setPriceSalesRounds } = useStore('priceSalesRound');
    const { setPageTitle } = useStore('pageTitleStore');
    const {
        fetchPrices, data: pricesData, count: rowCount, loading: pricesLoading,
    } = usePrices();
    const {
        data: priceTypesData, loading: priceTypesLoading, fetchPriceTypes,
    } = usePriceTypes();
    const { loading: priceSalesRoundsLoading, data: priceSalesRoundsData, fetchSalesRounds } = usePriceSalesRounds();
    const { loading: courtTypesLoading } = useFetchFilterOptions(COURT_TYPE_FILTER_OPTIONS_QUERY, 'courtType');
    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');
    const { loading: coachCategoriesLoading } = useFetchFilterOptions(COACH_CATEGORY_FILTER_OPTIONS_QUERY, 'coachCategory');
    const { loading: groupViewsLoading } = useFetchFilterOptions(GROUP_VIEW_FILTER_OPTIONS_QUERY, 'groupView');
    const { fetchPriceSeason } = usePriceSeason();
    const getParams = window.location.search.substring(1);
    const handleTabChange = (newTab: string) => {
        if (tab) {
            setPage(DATA_GRID_INITIAL_PAGE);
            setSortModel(DATA_GRID_SORT_MODEL_INITIAL_STATE);
            setFilterModel(DATA_GRID_FILTER_MODEL_INITIAL_STATE);
        }
        setTab(newTab);
    };

    useEffect(() => {
        fetchPriceTypes({ orderBy: PRICE_TYPES_POSITION_ASC, where: PRICE_TYPES_IS_ACTIVE }).then();
        fetchPriceSeason(priceSeasonId as string).then((priceSeasonData) => {
            setPriceSeason(priceSeasonData);
        });
    }, []);

    useEffect(() => {
        setPriceTypes(priceTypesData);
    }, [priceTypesLoading, priceTypesData]);

    useEffect(() => {
        setPrices(pricesData, rowCount);
    }, [pricesLoading, pricesData]);

    useEffect(() => {
        setPriceSalesRounds(priceSalesRoundsData);
    }, [priceSalesRoundsData, priceSalesRoundsLoading]);

    useEffect(() => {
        if (!priceSeason) {
            return;
        }
        setPageTitle(<>Прайс сезона &quot;{ priceSeason.title }&quot; <Typography variant="body1" display="inline" color="rgba(0, 0, 0, 0.6)">{ dayjs(priceSeason.startAt).format('DD MMMM YYYY') } - { dayjs(priceSeason.finishAt).format('DD MMMM YYYY') }</Typography></>);
    }, [priceSeason]);

    useEffect(() => {
        if (shouldUpdateGetParams) {
            navigate(`${PRICE_SEASON}/${priceSeasonId}/${tab}?${getParams}`);
            setShouldUpdateGetParams(false);

            return;
        }
        navigate(`${PRICE_SEASON}/${priceSeasonId}/${tab}`);
    }, [tab]);

    return (
        <>
            { priceSeason && (
                <Helmet>
                    <title>
                        { `Прайс сезона "${priceSeason?.title}"` }
                    </title>
                </Helmet>
            ) }
            <TabContext
                tab={ tab }
                handleTabChange={ handleTabChange }
                fetchPrices={ fetchPrices }
                loading={ pricesLoading || priceSalesRoundsLoading || courtTypesLoading || serviceCategoriesLoading || coachCategoriesLoading || groupViewsLoading }
                pageSize={ pageSize }
                page={ page }
                sortModel={ sortModel }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
                fetchSalesRounds={ fetchSalesRounds }
                priceSeasonId={ priceSeasonId ?? '' }
            />
            <UpdatePriceRate priceType={ tab } />
            <CreatePriceModal priceTypeId={ tab } priceSeasonId={ priceSeasonId ?? '' } />
        </>
    );
});

export default List;
