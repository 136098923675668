import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { IMPLEMENTATION_DOCUMENT_HEADER_DATA } from './HeaderData';

export const prepareImplementationDocumentsHeaderData = (serviceCategories: FilterOptionT[], groups: FilterOptionT[], employees: FilterOptionT[]): GridColumns =>
    useMemo(() => mapFields(IMPLEMENTATION_DOCUMENT_HEADER_DATA, {
        serviceCategory: serviceCategories,
        group: groups,
        coach: employees,
    }), [serviceCategories, groups, employees]);
