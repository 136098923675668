import { PAGE_TITLE_ORGANIZATION_LOCATION } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useOrganizationLocations
    from 'shared/data-hook/organizationLocation/fetch/fetchAll/useOrganizationLocations';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateOrganizationLocationModal from './components/CreateOrganizationLocationModal';
import OrganizationLocationList from './components/OrganizationLocationList';
import UpdateOrganizationLocationModal from './components/UpdateOrganizationLocationModal';
import { ORGANIZATION_LOCATION_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: organizationLocationsData, loading: organizationLocationsLoading, count: rowCount, fetchOrganizationLocations,
    } = useOrganizationLocations();
    const { setOrganizationLocations } = useStore('organizationLocation');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createOrganizationLocationModal');

    usePageTitle(PAGE_TITLE_ORGANIZATION_LOCATION);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchOrganizationLocations, refetchTrigger, ORGANIZATION_LOCATION_HEADER_DATA);

    useEffect(() => {
        setOrganizationLocations(organizationLocationsData, rowCount);
    }, [organizationLocationsData, organizationLocationsLoading]);

    const handleCreate = () => {
        onOpen();
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ handleCreate } startIcon={ <AddIcon /> }>Добавить</Button>
            </ControlPanel>
            <OrganizationLocationList
                loading={ organizationLocationsLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateOrganizationLocationModal />
            <UpdateOrganizationLocationModal />
        </StyledPageWrap>
    );
});

export default List;
