import { ClientFullNameT } from './Client';

export type ImplementationT = {
    id: string;
    client: ClientFullNameT | null;
    operation: string | null;
    service: string;
    datetime: Date;
    place: string | null;
    performer: string | null;
    comment: string | null;
    rate: number | null;
    title: string | null;
    total: number | null;
    document: string;
    payer: ClientFullNameT | null;
    duration: number;
    datetimeOperation: Date | null;
    discount: number | null;
    paymentInFiat: number | null;
    paymentInBonus: number | null;
    serviceCategory: string | null;
    priceType: string | null;
    user: string | null;
};

export type ImplementationsT = {
    implementations: ImplementationT[];
    implementationsCount: number;
};

export const EMPTY_IMPLEMENTATION_RESULT: ImplementationT = {
    id: '',
    client: null,
    payer: null,
    serviceCategory: null,
    priceType: null,
    user: null,
    operation: null,
    service: '',
    datetime: new Date(),
    place: null,
    performer: null,
    comment: null,
    rate: null,
    total: null,
    document: '',
    duration: 0,
    datetimeOperation: null,
    discount: null,
    title: null,
    paymentInFiat: null,
    paymentInBonus: null,
};

export const EMPTY_IMPLEMENTATIONS_RESULT: ImplementationsT = {
    implementations: [],
    implementationsCount: 0,
};
