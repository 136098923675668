import { useEffect } from 'react';
import useMemberCardType from 'shared/data-hook/memberCardType/fetch/fetchOne/useMemberCardType';
import { useStore } from 'store';

const useFetchMemberCardType = () => {
    const { fetchMemberCardType, loading } = useMemberCardType();
    const { setMemberCardType } = useStore('memberCardType');

    const fetch = (id: string) => {
        useEffect(() => {
            if (!id) {
                return;
            }

            fetchMemberCardType(id).then((memberCardType) => {
                setMemberCardType(memberCardType);
            });
        }, [id]);
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchMemberCardType;
