import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CourtCategoryT } from 'types/CourtCategory';

const DTO = t.type({
    id: t.string,
    name: t.string,
    position: t.number,
    court_court_categories: t.array(t.type({
        court: t.type({
            value: t.string,
            label: t.string,
        }),
    })),
});

export type CourtCategoryDTOT = t.TypeOf<typeof DTO>;

export const CourtCategoryDTO = tPromise.extendDecoder<CourtCategoryDTOT, CourtCategoryT>(
    DTO,
    ({
        id, name, position, court_court_categories,
    }) => ({
        id,
        name,
        position,
        courts: court_court_categories.map((courtCategory) => courtCategory.court),
    }),
) as t.Type<CourtCategoryT, CourtCategoryDTOT>;
