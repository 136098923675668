import { PAGE_TITLE_COURT_CATEGORY } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import CourtCategoryList from 'page/CourtCategory/components/CourtCategoryList';
import CreateCourtCategoryModal from 'page/CourtCategory/components/CreateCourtCategoryModal';
import { COURT_CATEGORY_HEADER_DATA } from 'page/CourtCategory/domain/HeaderData';
import ControlPanel from 'shared/component/ControlPanel';
import useCourtCategories from 'shared/data-hook/courtCategory/fetch/fetchAll/useCourtCategories';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import UpdateCourtCategoryModal from './components/UpdateCourtCategoryModal';

const List: FC = observer(() => {
    const { onOpen } = useStore('createCourtCategoryModal');
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: courtCategoriesData, loading: courtCategoriesLoading, count: rowCount, fetchCourtCategories,
    } = useCourtCategories();
    const { setCourtCategories } = useStore('courtCategory');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_COURT_CATEGORY);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchCourtCategories, refetchTrigger, COURT_CATEGORY_HEADER_DATA);

    useEffect(() => {
        setCourtCategories(courtCategoriesData, rowCount);
    }, [courtCategoriesData, courtCategoriesLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ onOpen } startIcon={ <AddIcon /> }>добавить</Button>
            </ControlPanel>
            <CourtCategoryList
                loading={ courtCategoriesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateCourtCategoryModal />
            <UpdateCourtCategoryModal />
        </StyledPageWrap>
    );
});

export default List;
