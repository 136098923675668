import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { OrganizationLocationsT } from 'types/OrganizationLocation';

import { OrganizationLocationDTO } from './OrganizationLocationDTO';

const DTO = t.type({
    organizationLocations: t.array(OrganizationLocationDTO),
    organizationLocationsCount: AggregateDTO,
});

type OrganizationLocationsDTOT = t.TypeOf<typeof DTO>;

export const OrganizationLocationsDTO = tPromise.extendDecoder<OrganizationLocationsDTOT, OrganizationLocationsT>(
    DTO,
    ({
        organizationLocations,
        organizationLocationsCount,
    }) => ({
        organizationLocations,
        organizationLocationsCount,
    }),
) as t.Type<OrganizationLocationsT, OrganizationLocationsDTOT>;
