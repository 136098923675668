import { gql } from '@apollo/client';

export type OrganizationLocationUpdatePayloadT = {
    name: string;
};

export type OrganizationLocationUpdateResponseDataT = {
    updateOrganizationLocation: {
        id: string;
    };
} | null | undefined;

export type OrganizationLocationUpdateVarT = {
    id: string;
    set: OrganizationLocationUpdatePayloadT;
}

export const ORGANIZATION_LOCATION_UPDATE = gql`
    mutation updateOrganizationLocation($set: organization_location_set_input = {}, $id: String = "") {
        updateOrganizationLocation: update_organization_location_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
