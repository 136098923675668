export const SERVICE_GROUP_RENT = 'Аренда для группы';
export const SERVICE_RENT = 'Аренда';
export const SERVICE_COACH = 'Тренер';
export const SERVICE_GROUP = 'Группа';
export const SERVICE_MEMBER_CARD = 'Членская карта';

export const SERVICES_OPTIONS = [
    {
        value: SERVICE_RENT,
        label: SERVICE_RENT,
    },
    {
        value: SERVICE_COACH,
        label: SERVICE_COACH,
    },
    {
        value: SERVICE_GROUP,
        label: SERVICE_GROUP,
    },
];
