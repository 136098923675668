import { gql } from '@apollo/client';
import { CourtVideoAnalyticsT } from 'types/CourtVideoAnalytics';

export type CourtVideoAnalyticsResponseT = {
    courtsVideoAnalytics: CourtVideoAnalyticsT[];
};

export const COURT_VIDEO_ANALYTICS_QUERY = gql`
    query CourtVideoAnalytics(
        $where: court_video_analytics_bool_exp! = {}
    ) {
        courtsVideoAnalytics: court_video_analytics(
            where: $where,
        ) {
            id
            datetime_start
            datetime_end
            reason
            notification_text
            court {
                id
                name
                order
            }
        }
    }
`;
