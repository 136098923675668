import {
    NOTIFICATION_TEXT_EMPTY_CELL,
    NOTIFICATION_TEXT_NO_CLIENTS,
    NOTIFICATION_TEXT_PLAN_EXCEEDED,
} from 'constants/notificationsText';
import { PROPERTY_COURT_VIDEO_ANALYTICS_NOTIFICATION_TEXT } from 'constants/propertyNames';

import { GridCellParams, GridColumnGroupingModel } from '@mui/x-data-grid-premium';
import { CourtT } from 'types/Court';
import { CustomHeaderData } from 'types/DataGrid';

type CourtVideoAnalyticsHeaderDataT = {
    headerData: CustomHeaderData[];
    columnGroupingModel: GridColumnGroupingModel;
};

const renderNotificationTextCell = (params: GridCellParams<string>) => {
    let className: string = 'border-left';

    if (params.value) {
        if (params.value.includes(NOTIFICATION_TEXT_EMPTY_CELL)) {
            className += ' data-grid-cell-empty';
        }
        if (params.value.includes(NOTIFICATION_TEXT_PLAN_EXCEEDED)) {
            className += ' data-grid-cell-exceeded';
        }
        if (params.value.includes(NOTIFICATION_TEXT_NO_CLIENTS)) {
            className += ' data-grid-cell-no-clients';
        }
    }

    return className;
};

export const prepareHeaderData = (courts: CourtT[]): CourtVideoAnalyticsHeaderDataT => {
    const headerData: CustomHeaderData[] = courts.map((item) => ({
        field: `notificationText-${item.order}`,
        headerName: PROPERTY_COURT_VIDEO_ANALYTICS_NOTIFICATION_TEXT,
        headerAlign: 'center',
        sortable: false,
        disableColumnMenu: true,
        width: 300,
        type: 'string',
        cellClassName: renderNotificationTextCell,
    }));

    const columnGroupingModel: GridColumnGroupingModel = courts.map((item) => ({
        groupId: item.id,
        headerName: item.name,
        headerAlign: 'center',
        headerClassName: `data-grid-section-court-${item.order % 2 === 0 ? 'even' : 'odd'}`,
        children: [{ field: `notificationText-${item.order}` }],
    }));

    return {
        headerData,
        columnGroupingModel,
    };
};
