import { PROPERTY_COURT_VIDEO_ANALYTICS_TIME_INTERVAL } from 'constants/propertyNames';

import { CustomHeaderData } from 'types/DataGrid';

export const COURT_VIDEO_ANALYTICS_HEADER_DATA: CustomHeaderData[] = [
    {
        field: 'timeInterval',
        headerName: PROPERTY_COURT_VIDEO_ANALYTICS_TIME_INTERVAL,
        sortable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
    },
];
