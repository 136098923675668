import {
    DATA_GRID_FILTER_MODEL_INITIAL_STATE,
    DATA_GRID_INITIAL_PAGE,
    DATA_GRID_PAGE_SIZE_SMALL,
    DATA_GRID_SORT_MODEL_INITIAL_STATE,
} from 'constants/dataGrid';

import { useState } from 'react';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { DataGridPropsT } from 'types/DataGrid';

export const useDataGrid = (initSortModel?: GridSortModel, initFilterModel?: GridFilterModel): DataGridPropsT => {
    const [pageSize, setPageSize] = useState<number>(DATA_GRID_PAGE_SIZE_SMALL);
    const [page, setPage] = useState<number>(DATA_GRID_INITIAL_PAGE);
    const [sortModel, setSortModel] = useState<GridSortModel>(initSortModel || DATA_GRID_SORT_MODEL_INITIAL_STATE);
    const [filterModel, setFilterModel] = useState<GridFilterModel>(initFilterModel || DATA_GRID_FILTER_MODEL_INITIAL_STATE);

    return {
        pageSize,
        setPageSize,
        page,
        setPage,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    };
};
