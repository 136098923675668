import { gql } from '@apollo/client';
import { CoachesInGroupT } from 'types/CoachesInGroup';
import { AggregateT } from 'types/Graphql';

export type CoachesInGroupsResponseT = {
    coachesInGroups: CoachesInGroupT[];
    coachesInGroupsCount: AggregateT;
}

export const COACHES_IN_GROUPS_QUERY = gql`
    query CoachesInGroups(
        $limit: Int,
        $offset: Int,
        $orderBy: implementation_document_order_by!,
        $where: implementation_document_bool_exp!
        $service: String!,
        $operation: String!,
    ) {
        coachesInGroups: implementation_document(
            limit: $limit,
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            groupMainCoach {
                id
                employee {
                    full_name
                }
            }
            group {
                id
                name
            }
            implementations(where: {
                service: {_eq: $service},
                operation: {_eq: $operation},
                cancel_id: {_is_null: true}
            }) {
                datetime
            }
            coaches {
                coach {
                    id
                    employee {
                        full_name
                    }
                }
            }
        }
        coachesInGroupsCount: implementation_document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
