import { PAGE_TITLE_MEMBER_CARD_TYPE } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CreateButtonLink from 'shared/component/Button/CreateButtonLink';
import ControlPanel from 'shared/component/ControlPanel';
import useMemberCardTypes from 'shared/data-hook/memberCardType/fetch/fetchAll/useMemberCardTypes';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import MemberCardTypeList from './components/MemberCardTypeList';
import { MEMBER_CARD_TYPE_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();

    const {
        data: memberCardTypesData, loading: memberCardTypesLoading, count: rowCount, fetchMemberCardTypes,
    } = useMemberCardTypes();
    const { setMemberCardTypes } = useStore('memberCardType');
    const { refetchTrigger } = useStore('refetchTrigger');

    usePageTitle(PAGE_TITLE_MEMBER_CARD_TYPE);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchMemberCardTypes, refetchTrigger, MEMBER_CARD_TYPE_HEADER_DATA);

    useEffect(() => {
        setMemberCardTypes(memberCardTypesData, rowCount);
    }, [memberCardTypesData, memberCardTypesLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <CreateButtonLink />
            </ControlPanel>
            <MemberCardTypeList
                loading={ memberCardTypesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
