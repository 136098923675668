import { ERROR_COURT_CATEGORY_UPDATE, SUCCESS_COURT_CATEGORY_UPDATE } from 'constants/notificationMessage';

import { CourtCategoryFormFields } from 'page/CourtCategory/components/formData';
import useCourtCategoryUpdate from 'shared/data-hook/courtCategory/update/useCourtCategoryUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtCategoryT } from 'types/CourtCategory';

const useUpdateCourtCategory = () => {
    const { error, loading, courtCategoryUpdate } = useCourtCategoryUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateCourtCategory = async (input: CourtCategoryFormFields, courtCategoryId: CourtCategoryT['id']) => {
        const response = await courtCategoryUpdate(input, courtCategoryId);

        if (error || !response || !response.updateCourtCategory) {
            showError(ERROR_COURT_CATEGORY_UPDATE);
        } else {
            showSuccess(SUCCESS_COURT_CATEGORY_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateCourtCategory,
        loading,
    };
};

export default useUpdateCourtCategory;
