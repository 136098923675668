import { makeAutoObservable } from 'mobx';
import { CourtT } from 'types/Court';

export default class UpdateCourtModal {
    public isOpen: boolean = false;

    public updateItemId: string = '';

    public court: CourtT | undefined = undefined;

    public id: string = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedItemId: string) {
        this.updateItemId = updatedItemId;
        this.isOpen = true;
    }

    public onClose() {
        this.updateItemId = '';
        this.isOpen = false;
    }

    public setCourt(court: CourtT) {
        this.court = court;
    }
}
