import { gql } from '@apollo/client';
import { CourtTypeT } from 'types/CourtType';

export type CourtTypeDeleteResponseDataT = {
    deleteCourtType: {
        id: CourtTypeT['id'];
    };
} | null | undefined;

export type CourtTypeDeleteVarT = {
    id: CourtTypeT['id'];
};

export const COURT_TYPE_DELETE = gql`
    mutation DeleteCourtType($id: String = "") {
        deleteCourtType: delete_court_type_by_pk(id: $id) {
            id
        }
    }
`;
