import { useMutation } from '@apollo/client';
import {
    MEMBER_CARD_SWITCH_IS_ACTIVE,
    MemberCardSwitchIsActiveResponseDataT, MemberCardSwitchIsActiveVar,
} from 'shared/graphql/mutation/memberCard/MemberCardSwitchIsActive';

import { MemberCardSwitchIsActiveDataT } from './MemberCardSwitchIsActiveData';

const useMemberCardSwitchIsActive = (): MemberCardSwitchIsActiveDataT => {
    const [update, { loading: loadingUpdate, error: errorUpdate }] = useMutation<
        MemberCardSwitchIsActiveResponseDataT,
        MemberCardSwitchIsActiveVar
    >(MEMBER_CARD_SWITCH_IS_ACTIVE);

    const switchAction = async (
        memberCardId: string,
        input: boolean,
    ) => {
        if (!memberCardId) {
            // TODO: use Sentry
            throw new Error('Member Card is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: memberCardId,
                    set: {
                        is_active: input,
                    },
                },
            });

            return data;
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        loading: loadingUpdate,
        error: errorUpdate,
        memberCardSwitchIsActive: switchAction,
    };
};

export default useMemberCardSwitchIsActive;
