import React, { FC, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon, Popover, Typography } from '@mui/material';

type PropsT = {
    text: string;
};

const AuxiliaryPopover: FC<PropsT> = (props) => {
    const {
        text,
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Icon
                aria-owns={ open ? 'mouse-over-popover' : undefined }
                aria-haspopup="true"
                onMouseEnter={ handlePopoverOpen }
                onMouseLeave={ handlePopoverClose }
                fontSize="small"
            >
                <InfoOutlinedIcon fontSize="small" sx={ { cursor: 'help' } } color="info" />
            </Icon>
            <Popover
                id="mouse-over-popover"
                sx={ {
                    pointerEvents: 'none',
                } }
                open={ open }
                anchorEl={ anchorEl }
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left',
                } }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'left',
                } }
                onClose={ handlePopoverClose }
                disableRestoreFocus
            >
                <Typography sx={ { p: 1 } }>{ text }</Typography>
            </Popover>
        </>
    );
};

export default AuxiliaryPopover;
