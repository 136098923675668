import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { CLIENTS_QUERY, ClientsResponseT } from 'shared/graphql/query/client/ClientQuery';
import { EMPTY_CLIENTS_RESULT } from 'types/Client';

import { ClientsDTO } from '../DTO/ClientsDTO';

import { ClientsDataT } from './ClientsData';

const useClients = (): ClientsDataT => {
    const [fetch, { data: clientsData, loading }] = useLazyQuery<ClientsResponseT>(
        CLIENTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedClientData = decodeDTO(
        ClientsDTO,
        EMPTY_CLIENTS_RESULT,
        (clients) => clients,
        {
            clients: clientsData?.clients,
        },
    );

    const fetchClients = async (): Promise<void> => {
        try {
            await fetch();
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchClients,
        loading,
        data: decodedClientData.clients,
    };
};

export default useClients;
