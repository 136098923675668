import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CourtDTO } from 'shared/data-hook/court/fetch/DTO/CourtDTO';
import { CourtTypeT } from 'types/CourtType';

const DTO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        duration: t.string,
        use_halves: t.boolean,
        periods: t.array(
            t.type({
                end: t.string,
                start: t.string,
            }),
        ),
    }),
    t.partial({
        courts: t.array(CourtDTO),
    }),
]);

export type CourtTypeDTOT = t.TypeOf<typeof DTO>;

export const CourtTypeDTO = tPromise.extendDecoder<CourtTypeDTOT, CourtTypeT>(
    DTO,
    ({
        id, name, duration, use_halves, periods, courts,
    }) => ({
        id,
        name,
        duration,
        useHalves: use_halves,
        periods,
        courts: courts ?? [],
    }),
) as t.Type<CourtTypeT, CourtTypeDTOT>;
