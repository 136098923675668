import { ERROR_GROUP_VIEW_CREATE, SUCCESS_GROUP_VIEW_CREATE } from 'constants/notificationMessage';

import { GroupViewFormFields } from 'page/Group/components/formData';
import useCreateGroupView from 'shared/data-hook/groupView/create/useCreateGroupView';
import { useNotification } from 'shared/hook/useNotification';

const useGroupViewCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, groupViewCreate } = useCreateGroupView();

    const createGroupView = async (input: GroupViewFormFields) => {
        const response = await groupViewCreate(input);

        if (error || !response) {
            showError(ERROR_GROUP_VIEW_CREATE);
        } else {
            showSuccess(SUCCESS_GROUP_VIEW_CREATE);
        }
    };

    return {
        createGroupView,
        loading,
    };
};

export default useGroupViewCreate;
