import { API_DATE_FORMAT } from 'constants/date';
import { PAGE_TITLE_COACHES_IN_GROUPS } from 'constants/pageTitles';

import { FC, useEffect, useState } from 'react';
import { GridLinkOperator } from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import DateRangePicker from 'shared/component/DateRangePicker';
import useCoachesInGroups from 'shared/data-hook/coachesInGroup/fetch/fetchAll/useCoachesInGroups';
import { GROUP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/group/GroupQuery';
import useFetchCoachFilterOptions from 'shared/hook/coach/useFetchCoachFilterOptions';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CoachesInGroupsList from './components/CoachesInGroupsList';
import { COACHES_IN_GROUPS_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { refetchTrigger } = useStore('refetchTrigger');
    const {
        data: coachesInGroupsData, loading: coachesInGroupsLoading, fetchCoachesInGroups, count: rowCount,
    } = useCoachesInGroups();
    const { setCoachesInGroups } = useStore('coachesInGroup');
    const { loading: groupFilterOptionsLoading } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');
    const { loading: coachFilterOptionsLoading } = useFetchCoachFilterOptions();
    const loading = coachesInGroupsLoading || groupFilterOptionsLoading || coachFilterOptionsLoading;

    usePageTitle(PAGE_TITLE_COACHES_IN_GROUPS);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchCoachesInGroups, refetchTrigger, COACHES_IN_GROUPS_HEADER_DATA);

    useEffect(() => {
        setCoachesInGroups(coachesInGroupsData, rowCount);
    }, [coachesInGroupsData, coachesInGroupsLoading]);

    const resetDateRangeFilter = () => {
        const { items } = filterModel;

        filterModel.items = items.filter((item) => item.columnField !== 'datetime');
    };

    const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
        setDateRange([newValue[0], newValue[1]]);
        resetDateRangeFilter();
        setFilterModel({
            items: [...filterModel.items, {
                id: 'after',
                columnField: 'datetime',
                operatorValue: 'onOrAfter',
                value: newValue[0]?.format(API_DATE_FORMAT),
            }, {
                id: 'before',
                columnField: 'datetime',
                operatorValue: 'onOrBefore',
                value: newValue[1]?.format(API_DATE_FORMAT),
            }],
            linkOperator: GridLinkOperator.And,
        });
    };

    useEffect(() => {
        if (filterModel.items.length === 0) {
            setDateRange([null, null]);
        }
    }, [filterModel]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <DateRangePicker
                    onChange={ handleDateRangeChange }
                    value={ dateRange }
                />
            </ControlPanel>
            <CoachesInGroupsList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
