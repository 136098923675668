import { makeAutoObservable } from 'mobx';
import { ClientT } from 'types/Client';
import { MemberCardTypeT } from 'types/MemberCardType';
import { OrganizationT } from 'types/Organization';

export default class CreateMemberCardModal {
    public isOpen: boolean = false;

    public clients: ClientT[] = [];

    public memberCardTypes: MemberCardTypeT[] = [];

    public organizations: OrganizationT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }

    public setClients(clients: ClientT[]) {
        this.clients = clients;
    }

    public setMemberCardTypes(memberCardTypes: MemberCardTypeT[]) {
        this.memberCardTypes = memberCardTypes;
    }

    public setOrganizations(organizations: OrganizationT[]) {
        this.organizations = organizations;
    }
}
