import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { CourtVideoAnalyticsT } from 'types/CourtVideoAnalytics';

const DTO = t.type({
    id: t.string,
    datetime_start: t.string,
    datetime_end: t.string,
    notification_text: Nullable(t.string),
    reason: Nullable(t.string),
    court: t.type({
        id: t.string,
        name: t.string,
        order: t.number,
    }),
});

export type CourtVideoAnalyticsDTOT = t.TypeOf<typeof DTO>;

export const CourtVideoAnalyticsDTO = tPromise.extendDecoder<CourtVideoAnalyticsDTOT, CourtVideoAnalyticsT>(
    DTO,
    ({
        id, datetime_start, datetime_end, notification_text, reason, court,
    }) => ({
        id,
        datetimeStart: new Date(datetime_start),
        datetimeEnd: new Date(datetime_end),
        notificationText: notification_text,
        reason,
        court,
    }),
) as t.Type<CourtVideoAnalyticsT, CourtVideoAnalyticsDTOT>;
