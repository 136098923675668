import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { FilterOptionT } from 'types/DataGrid';

const DTO = t.intersection([
    t.type({
        value: t.string,
        label: t.string,
    }),
    t.partial({
        additional: t.type({
            label: t.string,
        }),
    }),
]);

export type FilterOptionDTOT = t.TypeOf<typeof DTO>;

export const FilterOptionDTO = tPromise.extendDecoder<FilterOptionDTOT, FilterOptionT>(
    DTO,
    ({
        value, label, additional,
    }) => ({
        value,
        label: `${label}${additional ? ` (${additional.label})` : ''}`,
    }),
) as t.Type<FilterOptionT, FilterOptionDTOT>;
