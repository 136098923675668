import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    MEMBER_CARD_TYPE_QUERY, MemberCardTypeQueryVars,
    MemberCardTypeResponseT,
} from 'shared/graphql/query/memberCardType/MemberCardTypeQuery';
import { EMPTY_MEMBER_CARD_TYPE_RESULT, MemberCardTypeT } from 'types/MemberCardType';

import { MemberCardTypeDTO } from '../DTO/MemberCardTypeDTO';

import { MemberCardTypeDataT } from './MemberCardTypeData';

const useMemberCardType = (): MemberCardTypeDataT => {
    const [fetch, { loading, error }] = useLazyQuery<MemberCardTypeResponseT, MemberCardTypeQueryVars>(MEMBER_CARD_TYPE_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchMemberCardType = async (id: string): Promise<MemberCardTypeT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                MemberCardTypeDTO,
                EMPTY_MEMBER_CARD_TYPE_RESULT,
                (memberCardType) => memberCardType,
                data?.memberCardType,
            );
        } catch {
            return EMPTY_MEMBER_CARD_TYPE_RESULT;
        }
    };

    return {
        fetchMemberCardType,
        loading,
        error,
    };
};

export default useMemberCardType;
