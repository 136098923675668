import { useLazyQuery } from '@apollo/client';
import { CourtCategoryDTO } from 'shared/data-hook/courtCategory/fetch/DTO/CourtCategoryDTO';
import { CourtCategoryDataT } from 'shared/data-hook/courtCategory/fetch/fetchOne/CouryCategoryData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { COURT_CATEGORY_QUERY, CourtCategoryResponseT } from 'shared/graphql/query/courtCategory/CourtCategoryQuery';
import { CourtCategoryT, EMPTY_COURT_CATEGORY_RESULT } from 'types/CourtCategory';

const useCourtCategory = (): CourtCategoryDataT => {
    const [fetch, { loading, error }] = useLazyQuery<CourtCategoryResponseT>(COURT_CATEGORY_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchCourtCategory = async (id: string): Promise<CourtCategoryT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                CourtCategoryDTO,
                EMPTY_COURT_CATEGORY_RESULT,
                (courtCategory) => courtCategory,
                data?.courtCategory,
            );
        } catch {
            return EMPTY_COURT_CATEGORY_RESULT;
        }
    };

    return {
        fetchCourtCategory,
        loading,
        error,
    };
};

export default useCourtCategory;
