import { Paper } from '@mui/material';
import styled from 'styled-components';

export const LoginFormWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LoginForm = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
`;

export const LoginFormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;
