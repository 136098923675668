import { makeAutoObservable } from 'mobx';
import { OrganizationT } from 'types/Organization';

export default class OrganizationStore {
    public organizationList: OrganizationT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get organizations(): OrganizationT[] {
        return this.organizationList;
    }

    public setOrganizations(organizations: OrganizationT[], count?: number) {
        this.organizationList = organizations;
        this.count = count ?? 0;
    }
}
