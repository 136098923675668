import { TIME_23_HOURS_30_MINUTE_15_STEP } from 'constants/hours';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CATEGORY, PROPERTY_COACH,
    PROPERTY_COURT_TYPE,
    PROPERTY_DAY, PROPERTY_END,
    PROPERTY_START,
} from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_SHORT_OPTIONS } from 'constants/weekdays';

import React from 'react';
import { GridColumnGroupingModel, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeletePrice from 'shared/hook/price/delete/useDeletePrice';
import { getDayByNumber } from 'shared/utils/headerData/getDayByNumber';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell = (props: GridRenderCellParams) => {
    const { row } = props;
    const { priceDelete } = useDeletePrice();

    return (
        <DeleteButton id={ row.id } title={ row.title } actionDelete={ priceDelete } />
    );
};

const MODE_HEADER_DATA = [
    { field: 'id' },
    { field: 'category' },
    { field: 'courtType' },
    { field: 'day' },
    { field: 'timeStart' },
    { field: 'timeEnd' },
    { field: 'coachCategory' },
    { field: 'groupView' },
];

export const GROUP_HEADER_DATA: GridColumnGroupingModel = [
    {
        groupId: 'mode',
        headerName: 'Режим',
        headerAlign: 'center',
        headerClassName: 'data-grid-section',
        children: MODE_HEADER_DATA,
    },
];

export const PRICE_HEADER_DATA: CustomHeaderData[] = [
    {
        field: 'category',
        headerName: PROPERTY_CATEGORY,
        width: 150,
        type: 'singleSelect',
        hasuraFilterFields: ['category_id'],
        hasuraSortFields: ['service_category', 'name'],
    },
    {
        field: 'courtType',
        headerName: PROPERTY_COURT_TYPE,
        type: 'singleSelect',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        hasuraFilterFields: ['court_type_id'],
        hasuraSortFields: ['court_type', 'name'],
    },
    {
        field: 'day',
        headerName: PROPERTY_DAY,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params: GridValueFormatterParams) => getDayByNumber(params.value),
        type: 'singleSelect',
        valueOptions: WEEK_DAYS_VALUE_SHORT_OPTIONS,
        width: 100,
    },
    {
        field: 'timeStart',
        headerName: PROPERTY_START,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: TIME_23_HOURS_30_MINUTE_15_STEP,
        width: 100,
    },
    {
        field: 'timeEnd',
        headerName: PROPERTY_END,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: TIME_23_HOURS_30_MINUTE_15_STEP,
        width: 100,
    },
    {
        field: 'coachCategory',
        headerName: PROPERTY_COACH,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['coach_category_id'],
        hasuraSortFields: ['coach_category', 'name'],
        width: 150,
    },
    {
        field: 'groupView',
        headerName: 'Группа',
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        cellClassName: 'border-right',
        disableReorder: true,
        hasuraFilterFields: ['group_view_id'],
        hasuraSortFields: ['group_view', 'name'],
        width: 150,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        align: 'center',
        headerAlign: 'center',
    },
];
