import { makeAutoObservable } from 'mobx';

import { GroupViewT } from '../../types/GroupView';

export default class GroupViewStore {
    public groupViewList: GroupViewT[] = [];

    public count: number = 0;

    public groupView: GroupViewT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get groupViews(): GroupViewT[] {
        return this.groupViewList;
    }

    public setGroupViews(groupView: GroupViewT[], count?: number) {
        this.groupViewList = groupView;
        this.count = count ?? 0;
    }
}
