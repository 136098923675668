import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientsResponseT } from 'shared/graphql/query/client/ClientQuery';

import { ClientDTO } from './ClientDTO';

const DTO = t.type({
    clients: t.array(ClientDTO),
});

type ClientsDTOT = t.TypeOf<typeof DTO>;

export const ClientsDTO = tPromise.extendDecoder<ClientsDTOT, ClientsResponseT>(
    DTO,
    ({
        clients,
    }) => ({
        clients,
    }),
) as t.Type<ClientsResponseT, ClientsDTOT>;
