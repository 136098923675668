import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { OrganizationT } from 'types/Organization';

export type OrganizationsResponseT = {
    organizations: OrganizationT[];
    organizationsCount: AggregateT;
};

export type OrganizationResponseT = {
    organization: OrganizationT;
};

export const ORGANIZATION_FILTER_OPTIONS_QUERY = gql`
    query OrganizationFilterOptions {
        filterOptions: organization(
            order_by: { name: asc }
        ) {
            value: id
            label: name
        }
    }
`;

export const ORGANIZATIONS_QUERY = gql`
    query Organizations(
        $limit: Int,
        $offset: Int,
        $orderBy: organization_order_by! = {},
        $where: organization_bool_exp! = {}
    ) {
        organizations: organization(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            work_time
            service_app {
                id
                label
            }
        }
        organizationsCount: organization_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const ORGANIZATION_QUERY = gql`
    query Organization($id: String!) {
        organization: organization_by_pk(id: $id) {
            id
            name
            work_time
            service_app {
                id
                label
            }
        }
    }
`;
