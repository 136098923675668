import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { FilterOptionsResponseT } from 'types/DataGrid';

import { FilterOptionDTO } from './filterOptionDTO';

const DTO = t.type({
    filterOptions: t.array(FilterOptionDTO),
});

type FilterOptionsDTOT = t.TypeOf<typeof DTO>;

export const FilterOptionsDTO = tPromise.extendDecoder<FilterOptionsDTOT, FilterOptionsResponseT>(
    DTO,
    ({
        filterOptions,
    }) => ({
        filterOptions,
    }),
) as t.Type<FilterOptionsResponseT, FilterOptionsDTOT>;
