import { GridPinnedRowsProp } from '@mui/x-data-grid-premium';

export const preparePinnedRow = (coachHours: number): GridPinnedRowsProp => ({
    bottom: [
        {
            id: 'coach-hours-aggregate',
            priceType: 'Итого',
            duration: coachHours,
        },
    ],
});
