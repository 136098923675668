import { gql } from '@apollo/client';
import { CourtT } from 'types/Court';
import { AggregateT } from 'types/Graphql';

export type CourtsResponseT = {
    courts: CourtT[];
    courtsCount: AggregateT;
};

export type CourtResponseT = {
    court: CourtT;
};

export const COURTS_QUERY = gql`
    query Court(
        $limit: Int,
        $offset: Int,
        $orderBy: court_order_by! = {},
        $where: court_bool_exp! = {}
    ) {
        courts: court(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            order
            bonus_classes
            schedule
            organization_location {
                id
                name
            }
            organization {
                id
                name
            }
            court_type {
                id
                name
            }
            camera_id
        }
        courtsCount: court_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const COURT_QUERY = gql`
    query Court($id: String!) {
        court: court_by_pk(id: $id) {
            id
            name
            order
            bonus_classes
            schedule
            organization_location {
                id
                name
            }
            organization {
                id
                name
            }
            court_type {
                id
                name
            }
            camera_id
        }
    }
`;

export const COURT_FILTER_OPTIONS_QUERY = gql`
    query CourtFilterOptions {
        filterOptions: court(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;
