import { PERCENT } from 'constants/currency';

import { OptionT } from 'types/Option';

export const getPercentAndFiatOptions = (fiat: string): OptionT[] => [
    {
        value: PERCENT,
        label: 'проценты',
    },
    {
        value: fiat,
        label: fiat,
    },
];
