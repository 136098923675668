import { makeAutoObservable } from 'mobx';
import { PaymentDocumentT } from 'types/PaymentDocument';

export default class PaymentDocumentStore {
    public paymentDocuments: PaymentDocumentT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPaymentDocuments(paymentDocuments: PaymentDocumentT[], count?: number) {
        this.paymentDocuments = paymentDocuments;
        this.count = count ?? 0;
    }
}
