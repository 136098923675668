import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { OrganizationLocationT } from 'types/OrganizationLocation';

export type OrganizationLocationsResponseT = {
    organizationLocations: OrganizationLocationT[];
    organizationLocationsCount: AggregateT;
};

export type OrganizationLocationResponseT = {
    organizationLocation: OrganizationLocationT;
}

export const ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY = gql`
    query OrganizationLocationFilterOptions {
        filterOptions: organization_location(
            order_by: { name: asc }
        ) {
            value: id
            label: name
        }
    }
`;

export const ORGANIZATION_LOCATIONS_QUERY = gql`
    query OrganizationLocations(
        $limit: Int,
        $offset: Int,
        $orderBy: organization_location_order_by! = {},
        $where: organization_location_bool_exp! = {}
    ) {
        organizationLocations: organization_location(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            personal_manager_locations {
                personal_manager {
                    employee {
                        full_name
                    }
                }
            }
        }
        organizationLocationsCount: organization_location_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const ORGANIZATION_LOCATION_QUERY = gql`
    query OrganizationLocation($id: String!) {
        organizationLocation: organization_location_by_pk(id: $id) {
            id
            name
            personal_manager_locations {
                personal_manager {
                    employee {
                        full_name
                    }
                }
            }
        }
    }
`;
