import { PriceFormFields } from 'page/Price/components/formData';
import { PriceFormFieldsPayloadT } from 'shared/graphql/mutation/price/PriceMultipleCreate';
import { preparePriceMultipleCreateData } from 'shared/utils/preparePriceMultipleCreateData';

const useCheckCreatePrice = () => {
    const checkCreateMultiplePrice = async (input: PriceFormFields): Promise<number> => {
        return preparePriceMultipleCreateData(input as PriceFormFieldsPayloadT).length;
    };

    return {
        checkCreateMultiplePrice,
    };
};

export default useCheckCreatePrice;
