import { makeAutoObservable } from 'mobx';
import { MemberCardTypeT } from 'types/MemberCardType';

export default class MemberCardTypeStore {
    public memberCardTypeList: MemberCardTypeT[] = [];

    public memberCardType: MemberCardTypeT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get memberCardTypes(): MemberCardTypeT[] {
        return this.memberCardTypeList;
    }

    public setMemberCardTypes(memberCardTypes: MemberCardTypeT[], count?: number) {
        this.memberCardTypeList = memberCardTypes;
        this.count = count ?? 0;
    }

    public setMemberCardType(memberCardType: MemberCardTypeT) {
        this.memberCardType = memberCardType;
    }
}
