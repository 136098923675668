import React, { FC } from 'react';

import { ModalProps } from './domain/Props';
import { StyledDialog } from './domain/Styled';

const Modal: FC<ModalProps> = (props) => {
    const {
        children, onClose, open, fullWidth = true, maxWidth, className,
    } = props;

    if (!open) {
        return null;
    }

    return (
        <StyledDialog
            open={ open }
            onClose={ onClose }
            fullWidth={ fullWidth }
            maxWidth={ maxWidth }
            scroll="paper"
            className={ className }
        >
            { children }
        </StyledDialog>
    );
};

export default React.memo(Modal);
