import { useMemo } from 'react';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { PAYMENT_HEADER_DATA } from './HeaderData';

export const preparePaymentHeaderData = (organizationLocations: FilterOptionT[], paymentTypes: FilterOptionT[], paymentMethods: FilterOptionT[]) =>
    useMemo(() => mapFields(PAYMENT_HEADER_DATA, {
        organizationLocation: organizationLocations,
        paymentType: paymentTypes,
        paymentMethod: paymentMethods,
    }), [organizationLocations, paymentMethods, paymentTypes]);
