import { PAGE_TITLE_MEMBER_CARD } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useMemberCards from 'shared/data-hook/memberCard/fetch/fetchAll/useMemberCards';
import {
    MEMBER_CARD_TYPE_FILTER_OPTIONS_BY_ID_QUERY,
} from 'shared/graphql/query/memberCardType/MemberCardTypeQuery';
import { PRICE_SEASON_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceSeason/PriceSeasonQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateMemberCardModal from './components/CreateMemberCardModal';
import MemberCardList from './components/MemberCardList';
import UpdateMemberCardModal from './components/UpdateMemberCardModal';
import { MEMBER_CARD_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();

    const {
        data: memberCardsData, loading: memberCardsLoading, count: rowCount, fetchMemberCards,
    } = useMemberCards();
    const { loading: priceSeasonsLoading } = useFetchFilterOptions(PRICE_SEASON_FILTER_OPTIONS_QUERY, 'priceSeason');
    const { loading: memberCardTypesLoading } = useFetchFilterOptions(MEMBER_CARD_TYPE_FILTER_OPTIONS_BY_ID_QUERY, 'memberCardType');
    const { setMemberCards } = useStore('memberCard');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen } = useStore('createMemberCardModal');
    const loading = memberCardsLoading || memberCardTypesLoading || priceSeasonsLoading;

    usePageTitle(PAGE_TITLE_MEMBER_CARD);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchMemberCards, refetchTrigger, MEMBER_CARD_HEADER_DATA);

    useEffect(() => {
        setMemberCards(memberCardsData, rowCount);
    }, [memberCardsData, memberCardsLoading]);

    const openCreateMemberCardModal = () => {
        onOpen();
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ openCreateMemberCardModal } startIcon={ <AddIcon /> }>добавить</Button>
            </ControlPanel>
            <MemberCardList
                loading={ loading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateMemberCardModal />
            <UpdateMemberCardModal />
        </StyledPageWrap>
    );
});

export default List;
