import React, { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, IconButton } from '@mui/material';

type ModalTitleProps = {
    children?: React.ReactNode | string;
    onClose: () => void;
}

const ModalTitle: FC<ModalTitleProps> = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle { ...other }>
            { children }
            { onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={ onClose }
                    sx={ {
                        position: 'absolute',
                        right: 8,
                        top: 12,
                    } }
                >
                    <CloseIcon />
                </IconButton>
            ) : null }
        </DialogTitle>
    );
};

export default React.memo(ModalTitle);
