import { gql } from '@apollo/client';
import { CourtT } from 'types/Court';

export type CourtDeleteResponseDataT = {
    deleteCourtType: {
        id: CourtT['id'];
    };
} | null | undefined;

export type CourtDeleteVarT = {
    id: CourtT['id'];
};

export const COURT_DELETE = gql`
    mutation DeleteCourt($id: String = "") {
        deleteCourt: delete_court_by_pk(id: $id) {
            id
        }
    }
`;
