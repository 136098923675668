import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { ORGANIZATION_QUERY, OrganizationResponseT } from 'shared/graphql/query/organization/OrganizationQuery';
import { EMPTY_ORGANIZATION_RESULT, OrganizationT } from 'types/Organization';

import { OrganizationDTO } from '../DTO/OrganizationDTO';

import { OrganizationDataT } from './OrganizationData';

const useOrganization = (): OrganizationDataT => {
    const [fetch, { loading, error }] = useLazyQuery<OrganizationResponseT>(ORGANIZATION_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchOrganization = async (id: string): Promise<OrganizationT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(OrganizationDTO, EMPTY_ORGANIZATION_RESULT, (organization) => organization, data?.organization);
        } catch {
            return EMPTY_ORGANIZATION_RESULT;
        }
    };

    return {
        fetchOrganization,
        loading,
        error,
    };
};

export default useOrganization;
