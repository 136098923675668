import { HasuraOperatorT } from 'constants/gqlOperators';

import { buildNestedObject } from '../../../utils/buildNestedObject';

enum stringGridOperators {
    contains = 'contains',
    equals = 'equals',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
    isAnyOf = 'isAnyOf',
    jsonIsEmptyOperator = 'jsonIsEmptyOperator',
}

type stringGridOperatorsT = keyof typeof stringGridOperators

export const filterStringField = (filter: any, value: string | string[], gridOperator: stringGridOperatorsT, hasuraOperator: HasuraOperatorT, hasuraFilterFields: string[], field: string) => {
    const updatedFilter = { ...filter };
    let filterValue = {};

    if (!value && gridOperator !== stringGridOperators.isEmpty && gridOperator !== stringGridOperators.isNotEmpty) {
        return;
    }

    switch (gridOperator) {
        case stringGridOperators.contains:
            filterValue = { [field]: { _ilike: `%${value || ''}%` } };
            break;
        case stringGridOperators.equals:
            filterValue = { [field]: { _ilike: `${value || ''}` } };
            break;
        case stringGridOperators.startsWith:
            filterValue = { [field]: { _similar: `${value || ''}%` } };
            break;
        case stringGridOperators.endsWith:
            filterValue = { [field]: { _similar: `%${value || ''}` } };
            break;
        case stringGridOperators.isEmpty:
            filterValue = { [field]: { _is_null: true } };
            break;
        case stringGridOperators.isNotEmpty:
            filterValue = { [field]: { _is_null: false } };
            break;
        case stringGridOperators.jsonIsEmptyOperator:
            filterValue = { [field]: { _eq: Number(value) } };
            break;
        case stringGridOperators.isAnyOf:
            if (!Array.isArray(value) || value.length === 0) {
                break;
            }
            filterValue = { [field]: { _in: value } };
            break;
    }

    if (!updatedFilter[hasuraOperator][hasuraOperator]) {
        updatedFilter[hasuraOperator][hasuraOperator] = [];
    }

    updatedFilter[hasuraOperator][hasuraOperator].push(buildNestedObject(hasuraFilterFields, { ...filterValue }));
};
