import { makeAutoObservable } from 'mobx';

export default class CoachHoursStore {
    public coachHours: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setCoachHours(coachHours: number) {
        this.coachHours = coachHours;
    }
}
