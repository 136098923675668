import {
    ERROR_CARD_ALREADY_CREATED,
    ERROR_CLIENT_BALANCE_INSUFFICIENT,
    ERROR_CLIENT_NOT_FOUND,
    ERROR_MEMBER_CARD_CREATE,
    ERROR_MEMBER_CARD_TYPE_SEASON_PRICE_NOT_FOUND,
    SUCCESS_MEMBER_CARD_CREATE,
} from 'constants/notificationMessage';
import {
    REASON_CARD_ALREADY_CREATED,
    REASON_CLIENT_BALANCE_INSUFFICIENT,
    REASON_CLIENT_NOT_FOUND, REASON_DEFAULT,
    REASON_MEMBER_CARD_TYPE_SEASON_PRICE_NOT_FOUND,
} from 'constants/reasons';

import { useEffect } from 'react';
import { MemberCardFormFields } from 'page/MemberCard/components/formData';
import useCreateMemberCard from 'shared/data-hook/memberCard/create/useCreateMemberCard';
import usePaymentTypes from 'shared/data-hook/paymentType/fetch/fetchAll/usePaymentTypes';
import { useStore } from 'store';
import { PaymentTypePosition } from 'types/PaymentType';

import { useNotification } from '../../useNotification';

const useMemberCardCreate = () => {
    const { error, loading, createMemberCard: actionCreate } = useCreateMemberCard();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { fetchPaymentTypes, data: paymentTypeData } = usePaymentTypes();
    const paymentType = paymentTypeData.find((type) => type.position === PaymentTypePosition.PurchaseMemberCard);

    const errors = new Map<string, string>([
        [REASON_CLIENT_NOT_FOUND, ERROR_CLIENT_NOT_FOUND],
        [REASON_CLIENT_BALANCE_INSUFFICIENT, ERROR_CLIENT_BALANCE_INSUFFICIENT],
        [REASON_CARD_ALREADY_CREATED, ERROR_CARD_ALREADY_CREATED],
        [REASON_MEMBER_CARD_TYPE_SEASON_PRICE_NOT_FOUND, ERROR_MEMBER_CARD_TYPE_SEASON_PRICE_NOT_FOUND],
        [REASON_DEFAULT, ERROR_MEMBER_CARD_CREATE],
    ]);

    useEffect(() => {
        fetchPaymentTypes().then();
    }, []);

    const createMemberCard = async (input: MemberCardFormFields): Promise<boolean> => {
        if (!paymentType) {
            showError(ERROR_MEMBER_CARD_CREATE);

            return false;
        }

        const { status, reason } = await actionCreate(input, paymentType?.id, paymentType?.paymentMethodId);

        if (error || status === 'error') {
            showError(errors.get(reason ?? REASON_DEFAULT) ?? ERROR_MEMBER_CARD_CREATE);

            return false;
        }

        showSuccess(SUCCESS_MEMBER_CARD_CREATE);
        toggleRefetchTrigger();

        return true;
    };

    return {
        createMemberCard,
        loading,
    };
};

export default useMemberCardCreate;
