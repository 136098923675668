import { EMPTY_CELL } from 'constants/dataGrid';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_CLIENT, PROPERTY_DATETIME, PROPERTY_DURATION, PROPERTY_PLACE, PROPERTY_TYPE,
} from 'constants/propertyNames';

import { GridColumnGroupingModel } from '@mui/x-data-grid-premium';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import { CustomHeaderData } from 'types/DataGrid';

export const COLUMNS_GROUPING_COACH_HOURS: GridColumnGroupingModel = [
    {
        groupId: 'client',
        headerName: PROPERTY_CLIENT,
        headerAlign: 'center',
        headerClassName: 'data-grid-section',
        children: [
            {
                field: 'clientLastName',
            },
            {
                field: 'clientFirstName',
            },
            {
                field: 'clientMiddleName',
            },
        ],
    },
];

export const HEADER_DATA_COACH_HOURS: CustomHeaderData[] = [
    {
        headerName: 'Фамилия клиента',
        field: 'clientLastName',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['client_datum', 'client', 'last_name'],
        hasuraSortFields: ['client_datum', 'client', 'last_name'],
        renderCell: (params) => params.row?.client?.lastName ?? EMPTY_CELL,
    },
    {
        headerName: 'Имя клиента',
        field: 'clientFirstName',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['client_datum', 'client', 'first_name'],
        hasuraSortFields: ['client_datum', 'client', 'first_name'],
        renderCell: (params) => params.row?.client?.firstName ?? EMPTY_CELL,
    },
    {
        headerName: 'Отчество клиента',
        field: 'clientMiddleName',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['client_datum', 'client', 'middle_name'],
        hasuraSortFields: ['client_datum', 'client', 'middle_name'],
        renderCell: (params) => params.row?.client?.middleName ?? EMPTY_CELL,
    },
    {
        headerName: PROPERTY_DATETIME,
        field: 'datetime',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        filterable: false,
        hasuraFilterFields: ['datetime'],
        hasuraSortFields: ['datetime'],
        renderCell: (params) => renderDateToGrid(params.row.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_PLACE,
        field: 'place',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['place'],
        hasuraFilterFields: ['place'],
        renderCell: (params) => params.row.place ?? EMPTY_CELL,
    },
    {
        headerName: PROPERTY_TYPE,
        field: 'priceType',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['price_type', 'title'],
        hasuraFilterFields: ['price_type_id'],
        renderCell: (params) => params.row.priceType ?? EMPTY_CELL,
    },
    {
        headerName: PROPERTY_DURATION,
        field: 'duration',
        flex: 0.25,
        align: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        headerAlign: 'center',
        type: 'number',
        renderCell: (params) => `${params.row.duration / 60} ч.`,
    },
];
