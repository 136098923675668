import { ERROR_ORGANIZATION_CREATE, SUCCESS_ORGANIZATION_CREATE } from 'constants/notificationMessage';

import { OrganizationFormFields } from 'page/Organization/components/formdata';
import useCreateOrganization from 'shared/data-hook/organization/create/useCreateOrganization';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useOrganizationCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { organizationCreate, loading, error } = useCreateOrganization();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createOrganization = async (input: OrganizationFormFields) => {
        const response = await organizationCreate(input);

        if (!error || !response || !response.createOrganization) {
            showSuccess(SUCCESS_ORGANIZATION_CREATE);
        } else {
            showError(ERROR_ORGANIZATION_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createOrganization,
        loading,
    };
};

export default useOrganizationCreate;
