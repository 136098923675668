import { makeAutoObservable } from 'mobx';
import { CourtTypeT } from 'types/CourtType';

export default class UpdateCourtTypeModal {
    public isOpen: boolean = false;

    public courtType: CourtTypeT | undefined = undefined;

    public id: string = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(id: string) {
        this.id = id;
        this.isOpen = true;
    }

    public onClose() {
        this.id = '';
        this.isOpen = false;
    }

    public setCourtType(courtType: CourtTypeT) {
        this.courtType = courtType;
    }
}
