import { useEffect } from 'react';
import { TypedDocumentNode } from '@apollo/client';
import useFetchFilterOptionsData from 'shared/data-hook/filterOptions/useFetchFilterOptionsData';
import { useStore } from 'store';
import { FilterOptionsT } from 'types/filterOptions/FilterOptions';

const useFetchFilterOptions = (query: TypedDocumentNode, filterOption: FilterOptionsT, filter?: any) => {
    const { loading, data, refetch } = useFetchFilterOptionsData(query, filter);
    const { setFilterOption } = useStore('filterOptions');

    useEffect(() => {
        if (!loading) {
            setFilterOption(filterOption, data);
        }
    }, [loading]);

    return { loading, refetch };
};

export default useFetchFilterOptions;
