import { useMutation } from '@apollo/client';
import { CourtCategoryFormFields } from 'page/CourtCategory/components/formData';
import { CourtCategoryUpdateDataT } from 'shared/data-hook/courtCategory/update/CourtCategoryUpdateData';
import {
    COURT_CATEGORY_UPDATE,
    CourtCategoryUpdateResponseDataT,
    CourtCategoryUpdateVarT,
} from 'shared/graphql/mutation/courtCategory/courtCategoryUpdate';
import {
    COURT_COURT_CATEGORY_DELETE,
    CourtCourtCategoryDeleteResponseDataT, CourtCourtCategoryDeleteVarT,
} from 'shared/graphql/mutation/courtCourtCategory/courtCourtCategoryDelete';
import { CourtCategoryT } from 'types/CourtCategory';

const useCourtCategoryUpdate = (): CourtCategoryUpdateDataT => {
    const [update, { loading, error }] = useMutation<
        CourtCategoryUpdateResponseDataT,
        CourtCategoryUpdateVarT
    >(COURT_CATEGORY_UPDATE);

    const [deleteMutation] = useMutation<
        CourtCourtCategoryDeleteResponseDataT,
        CourtCourtCategoryDeleteVarT
    >(COURT_COURT_CATEGORY_DELETE);

    const updateAction = async (input: CourtCategoryFormFields, courtCategoryId: CourtCategoryT['id']) => {
        if (!courtCategoryId) {
            // TODO: use Sentry
            throw new Error('Court category is not found');
        }

        await deleteMutation({
            variables: {
                id: courtCategoryId,
            },
        });

        try {
            const { data } = await update({
                variables: {
                    onConflict: {
                        constraint: 'court_category_pkey',
                        update_columns: ['name', 'position'],
                    },
                    courtCategoryPayload: [{
                        id: courtCategoryId,
                        name: input.name,
                        position: input.position,
                        court_court_categories: {
                            data: input.courts.map((courtId) => ({
                                court_id: courtId,
                            })),
                        },
                    }],
                },
            });

            return data;
            // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        courtCategoryUpdate: updateAction,
        loading,
        error,
    };
};

export default useCourtCategoryUpdate;
