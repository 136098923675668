export type MemberCardTypeT = {
    id: string;
    title: string;
    isActive: boolean;
    expirationPeriod: number;
    cashbackFiatOperationsLimit: number;
    cashbackFiatSumLimit: number;
};

export type MemberCardTypesT = {
    memberCardTypes: MemberCardTypeT[];
    memberCardTypesCount: number;
};

export const EMPTY_MEMBER_CARD_TYPE_RESULT: MemberCardTypeT = {
    id: '',
    title: '',
    isActive: false,
    expirationPeriod: 0,
    cashbackFiatOperationsLimit: 0,
    cashbackFiatSumLimit: 0,
};

export const EMPTY_MEMBER_CARD_TYPES_RESULT: MemberCardTypesT = {
    memberCardTypes: [],
    memberCardTypesCount: 0,
};
