import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import useDeleteMultipleMemberCardType
    from 'shared/hook/memberCardType/delete/useDeleteMultipleMemberCardType';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { MEMBER_CARD_TYPE_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const MemberCardTypeList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { memberCardTypes, count } = useStore('memberCardType');
    const { deleteMultipleMemberCardType } = useDeleteMultipleMemberCardType();

    return (
        <DataGrid
            rows={ memberCardTypes }
            rowCount={ count }
            columns={ MEMBER_CARD_TYPE_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            multipleDeleteAction={ deleteMultipleMemberCardType }
        />
    );
});

export default MemberCardTypeList;
