export const base64Encode = (value: string) =>
    btoa(
        encodeURIComponent(value).replace(
            /%([0-9A-F]{2})/g,
            (match, p1) => String.fromCharCode(parseInt(p1, 16)),
        ),
    );

export const base64Decode = (value: string) =>
    decodeURIComponent(Array.prototype.map.call(atob(value), (c) => {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
    }).join(''));
