import { gql } from '@apollo/client';
import { SumDurationT } from 'types/CoachHours';

export type CoachHoursResponseT = {
    implementationsSumDuration: SumDurationT;
};
export const COACH_HOURS_QUERY = gql`
    query CoachHours (
        $where: implementation_bool_exp! = {}
    ) {
        implementationsSumDuration: implementation_aggregate(where: $where) {
            aggregate {
                sum {
                    duration
                }
            }
        }
    }
`;
