import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    ORGANIZATION_LOCATION_QUERY,
    OrganizationLocationResponseT,
} from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import { EMPTY_ORGANIZATION_LOCATION_RESULT, OrganizationLocationT } from 'types/OrganizationLocation';

import { OrganizationLocationDTO } from '../DTO/OrganizationLocationDTO';

import { OrganizationLocationDataT } from './OrganizationLocationData';

const useOrganizationLocation = (): OrganizationLocationDataT => {
    const [fetch, { loading, error }] = useLazyQuery<OrganizationLocationResponseT>(ORGANIZATION_LOCATION_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchOrganizationLocation = async (id: string): Promise<OrganizationLocationT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(OrganizationLocationDTO, EMPTY_ORGANIZATION_LOCATION_RESULT, (organizationLocation) => organizationLocation, data?.organizationLocation);
        } catch {
            return EMPTY_ORGANIZATION_LOCATION_RESULT;
        }
    };

    return {
        fetchOrganizationLocation,
        loading,
        error,
    };
};

export default useOrganizationLocation;
