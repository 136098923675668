import { SUCCESS_LOGIN } from 'constants/notificationMessage';

import LoginStatus from 'enums/LoginStatus';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import LoginResultT from 'types/LoginResult';

const DTO = t.type({
    user_id: Nullable(t.string),
});

type LoginResultDTOT = t.TypeOf<typeof DTO>;

export const LoginResultDTO = tPromise.extendType<LoginResultDTOT, LoginResultT>(
    DTO,
    ({ user_id }) => ({
        userId: user_id,
        status: LoginStatus.Success,
        message: SUCCESS_LOGIN,
    }),
    ({ userId }) => ({
        user_id: userId,
    }),
) as t.Type<LoginResultT, LoginResultDTOT>;
