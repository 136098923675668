import { useMutation } from '@apollo/client';
import {
    ORGANIZATION_DELETE,
    OrganizationDeleteResponseDataT,
    OrganizationDeleteVarT,
} from 'shared/graphql/mutation/organization/OrganizationDelete';

import { OrganizationDeleteDataT } from './OrganiztionDeleteData';

const useOrganizationDelete = (): OrganizationDeleteDataT => {
    const [organizationDelete, { loading, error }] = useMutation<OrganizationDeleteResponseDataT, OrganizationDeleteVarT>(ORGANIZATION_DELETE);

    const deleteAction = async (organizationId: string) => {
        try {
            const { data } = await organizationDelete({
                variables: {
                    id: organizationId,
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error('useOrganizationDelete', errorCreate);
            throw errorCreate;
        }
    };

    return {
        deleteOrganization: deleteAction,
        loading,
        error,
    };
};

export default useOrganizationDelete;
