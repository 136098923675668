import { useEffect } from 'react';
import useGroupView from 'shared/data-hook/groupView/fetch/fetchOne/useGroupView';
import { useStore } from 'store';

const useFetchGroupView = (id: string) => {
    const { fetchGroupView, loading } = useGroupView();
    const { isOpen, setGroupView } = useStore('updateGroupViewModal');

    useEffect(() => {
        if (isOpen && id) {
            fetchGroupView(id).then((fetchedGroupView) => {
                setGroupView(fetchedGroupView);
            });
        }
    }, [isOpen, id]);

    return {
        loading,
    };
};

export default useFetchGroupView;
