import { makeAutoObservable } from 'mobx';
import { PaymentTypeT } from 'types/PaymentType';

export default class PaymentTypeStore {
    public paymentTypes: PaymentTypeT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPaymentTypes(paymentTypes: PaymentTypeT[], count?: number) {
        this.paymentTypes = paymentTypes;
        this.count = count ?? 0;
    }
}
