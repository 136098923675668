import * as React from 'react';
import { FC } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';

import { Modal } from '../../Modal';

type PropsT = {
    isOpen: boolean;
    onClose: () => void;
    submitForm: () => void;
    title: string;
    message: string;
    operationType: 'error' | 'info';
}

const ConfirmModal: FC<PropsT> = (props) => {
    const {
        isOpen, onClose, submitForm, title, message, operationType,
    } = props;

    return (
        <Modal open={ isOpen } onClose={ onClose } fullWidth={ true } maxWidth="sm">
            <DialogTitle>{ title }</DialogTitle>
            <DialogContent dividers={ true }>
                { message }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <Button
                    variant="contained"
                    color={ operationType }
                    onClick={ submitForm }
                    autoFocus
                >
                    Удалить
                </Button>
            </DialogActions>
        </Modal>
    );
};

export default ConfirmModal;
