import { gql } from '@apollo/client';
import { GroupViewT } from 'types/GroupView';

export type GroupViewDeleteResponseDataT = {
    delete_group_view: {
        id: GroupViewT['id'];
    };
} | null | undefined;

export type GroupViewDeleteVarT = {
    id: GroupViewT['id'];
}

export const GROUP_VIEW_DELETE = gql`
    mutation GroupViewDelete($id: String!) {
        delete_group_view: delete_group_view_by_pk(id: $id) {
            id
        }
    }
`;
