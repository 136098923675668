import { gql } from '@apollo/client';

export type CourtTypeUpdateVarT = {
    id: string;
    set: CourtTypeUpdateMutationPayloadT;
};

type CourtTypeUpdateMutationPayloadT = {
    name: string;
    duration: string;
    use_halves: boolean;
}

export type CourtTypeUpdateResponseDataT = {
    updateCourtType: {
        id: string;
    };
} | null | undefined;

export const COURT_TYPE_UPDATE = gql`
    mutation UpdateCourtType($set: court_type_set_input = {}, $id: String = "") {
        updateCourtType: update_court_type_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
