import { PAGE_TITLE_ORGANIZATION } from 'constants/pageTitles';

import React, { FC, memo, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useOrganizations from 'shared/data-hook/organization/fetch/fetchAll/useOrganizations';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateOrganizationModal from './components/CreateOrganizationModal';
import OrganizationList from './components/OrganizationList';
import UpdateOrganizationModal from './components/UpdateOrganizationModal';
import { ORGANIZATION_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: organizationsData, loading: organizationsLoading, count: rowCount, fetchOrganizations,
    } = useOrganizations();

    const { setOrganizations } = useStore('organization');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { loading: serviceAppsLoading } = useFetchFilterOptions(SERVICE_APP_FILTER_OPTIONS_QUERY, 'serviceApp');
    const { onOpen } = useStore('createOrganizationModal');

    usePageTitle(PAGE_TITLE_ORGANIZATION);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchOrganizations, refetchTrigger, ORGANIZATION_HEADER_DATA);

    useEffect(() => {
        setOrganizations(organizationsData, rowCount);
    }, [organizationsData, organizationsLoading]);

    const handleCreate = () => {
        onOpen();
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button variant="outlined" size="small" onClick={ handleCreate } startIcon={ <AddIcon /> }>Добавить</Button>
            </ControlPanel>
            <OrganizationList
                loading={ organizationsLoading || serviceAppsLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
            <CreateOrganizationModal />
            <UpdateOrganizationModal />
        </StyledPageWrap>
    );
});

export default memo(List);
