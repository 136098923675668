import { gql } from '@apollo/client';
import { CourtCategoryT } from 'types/CourtCategory';
import { AggregateT } from 'types/Graphql';

export type CourtCategoriesResponseT = {
    courtCategories: CourtCategoryT[];
    courtCategoriesCount: AggregateT;
};

export type CourtCategoryResponseT = {
    courtCategory: CourtCategoryT;
};

export const COURT_CATEGORIES_QUERY = gql`
    query CourtCategories(
        $limit: Int,
        $offset: Int,
        $orderBy: court_category_order_by! = {},
        $where: court_category_bool_exp! = {}
    ) {
        courtCategories: court_category(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            position
            name
            court_court_categories {
                court {
                    value: id
                    label: name
                }
            }
        }
        courtCategoriesCount: court_category_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const COURT_CATEGORY_QUERY = gql`
    query CourtCategory($id: String!) {
        courtCategory: court_category_by_pk(id: $id) {
            id
            name
            position
            court_court_categories {
                court {
                    value: id
                    label: name
                }
            }
        }
    }
`;
