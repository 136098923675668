import { useQuery } from '@apollo/client';
import { PERSONAL_MANAGER_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/personalManager/PersonalManagerQuery';
import { EMPTY_FILTER_OPTIONS } from 'types/DataGrid';
import { FetchFilterOptionsDataT } from 'types/filterOptions/FetchFilterOptionsData';
import { PersonalManagerFilterOptionT } from 'types/filterOptions/PersonalManagerFilterOptions';
import {
    PersonalManagerFilterOptionsResponseT,
} from 'types/filterOptions/PersonalManagerFilterOptionsResponse';

const useFetchPersonalManagerFilterOptionsData = (): FetchFilterOptionsDataT => {
    const { data, loading } = useQuery<PersonalManagerFilterOptionsResponseT>(
        PERSONAL_MANAGER_FILTER_OPTIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    return {
        data: data?.filterOptions.map((personalManager: PersonalManagerFilterOptionT) => ({
            value: personalManager.value,
            label: personalManager.employee.full_name,
        })) || EMPTY_FILTER_OPTIONS,
        loading,
    };
};

export default useFetchPersonalManagerFilterOptionsData;
