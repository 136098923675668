import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';

type PropsT = {
    id: string;
};

const EditButton: FC<PropsT> = (props) => {
    const {
        id,
    } = props;

    return (
        <Link to={ `edit/${id}` }>
            <Tooltip title="Редактировать" placement="top">
                <IconButton>
                    <EditIcon color="primary" />
                </IconButton>
            </Tooltip>
        </Link>
    );
};

export default EditButton;
