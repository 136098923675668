import { makeAutoObservable } from 'mobx';
import { PriceTypeT } from 'types/PriceType';

export default class PriceTypeStore {
    public priceTypeList: PriceTypeT[] = [];

    public priceType: PriceTypeT | undefined = undefined;

    public count: number = 0;

    public isPriceTypeLoading = false;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get priceTypes(): PriceTypeT[] {
        return this.priceTypeList;
    }

    public setPriceTypes(priceTypes: PriceTypeT[], count?: number) {
        this.priceTypeList = priceTypes;
        this.count = count ?? 0;
    }

    public setPriceType(priceType: PriceTypeT) {
        this.priceType = priceType;
    }

    public setPriceTypeLoading(isPriceTypeLoading: boolean) {
        this.isPriceTypeLoading = isPriceTypeLoading;
    }
}
