import { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { GROUP_VIEW_HEADER_DATA } from '../domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const GroupViewList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;

    const { groupViewList, count } = useStore('groupView');

    return (
        <DataGrid
            rows={ groupViewList }
            rowCount={ count }
            columns={ GROUP_VIEW_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            rowAutoHeight={ true }
        />
    );
});

export default GroupViewList;
