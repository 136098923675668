import { gql } from '@apollo/client';

export type CourtCourtCategoryDeleteVarT = {
    id: string;
};

export type CourtCourtCategoryDeleteResponseDataT = {
    deleteCourtCategory: {
        id: string;
    };
} | null | undefined;

export const COURT_COURT_CATEGORY_DELETE = gql`
    mutation DeleteCourtCourtCategory($id: String!) {
        delete_court_court_category(where: {court_category_id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
