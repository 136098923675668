import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { MemberCardsT } from 'types/MemberCard';

import { MemberCardDTO } from './MemberCardDTO';

const DTO = t.type({
    memberCards: t.array(MemberCardDTO),
    memberCardsCount: AggregateDTO,
});

type MemberCardsDTOT = t.TypeOf<typeof DTO>;

export const MemberCardsDTO = tPromise.extendDecoder<MemberCardsDTOT, MemberCardsT>(
    DTO,
    ({
        memberCards,
        memberCardsCount,
    }) => ({
        memberCards,
        memberCardsCount,
    }),
) as t.Type<MemberCardsT, MemberCardsDTOT>;
