import { useMutation } from '@apollo/client';
import { OrganizationFormFields } from 'page/Organization/components/formdata';
import {
    ORGANIZATION_UPDATE,
    OrganizationUpdateResponseDataT,
    OrganizationUpdateVarT,
} from 'shared/graphql/mutation/organization/OrganizationUpdate';

import { OrganizationUpdateDataT } from './OrganizationUpdateData';

const useOrganizationUpdate = (): OrganizationUpdateDataT => {
    const [update, { loading, error }] = useMutation<OrganizationUpdateResponseDataT, OrganizationUpdateVarT>(ORGANIZATION_UPDATE);
    const updateAction = async (input: OrganizationFormFields, organizationId: string) => {
        try {
            const { data } = await update({
                variables: {
                    id: organizationId,
                    set: {
                        name: input.name,
                        service_app_id: input.serviceApp as string || null,
                        work_time: input.workTime,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw errorUpdate;
        }
    };

    return {
        organizationUpdate: updateAction,
        loading,
        error,
    };
};

export default useOrganizationUpdate;
