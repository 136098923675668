import { API_DATE_TIME_FORMAT } from 'constants/date';

import { useEffect } from 'react';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { CustomHeaderData } from 'types/DataGrid';

import { addParamsToFilterModel } from '../utils/addParamsToFilterModel';
import { addParamsToSortModel } from '../utils/addParamsToSortModel';

const addParamsToDateRange = (dateRange: DateRange<Dayjs>) => ({
    datetime: {
        _gte: dateRange[0]?.format(API_DATE_TIME_FORMAT),
        _lte: dateRange[1]?.format(API_DATE_TIME_FORMAT),
    },
});

const addParamsToCoach = (coach: string) => ({
    implementation_document: {
        coaches: {
            coach_id: {
                _eq: coach,
            },
        },
    },
});

const useFetchDataForCoachHours = (
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    fetchImplementations: Function,
    fetchCoachHours: Function,
    trigger: boolean,
    columns: CustomHeaderData[],
    dateRange: DateRange<Dayjs>,
    coach: string,
) => {
    useEffect(() => {
        if (!coach) {
            return;
        }

        fetchImplementations({
            limit: pageSize,
            offset: page === 0 ? 0 : page * pageSize,
            orderBy: addParamsToSortModel(sortModel, columns),
            where: addParamsToFilterModel(filterModel, columns),
            dateRange: addParamsToDateRange(dateRange),
            coach: addParamsToCoach(coach),
        });
        fetchCoachHours({
            where: addParamsToFilterModel(filterModel, columns),
            coach: addParamsToCoach(coach),
            dateRange: addParamsToDateRange(dateRange),
        });
    }, [sortModel, filterModel, page, pageSize, trigger, dateRange, coach]);
};

export default useFetchDataForCoachHours;
