import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PriceSeasonT } from 'types/PriceSeason';

export type PriceSeasonResponseT = {
    priceSeason: PriceSeasonT;
};

export type PriceSeasonsResponseT = {
    priceSeasons: PriceSeasonT[];
    priceSeasonsCount: AggregateT;
};

export const PRICE_SEASON_FILTER_OPTIONS_QUERY = gql`
    query PriceSeasonFilterOptions {
        filterOptions: price_season(
            order_by: { title: asc }
        ) {
            value: id,
            label: title
        }
    }
`;

export const PRICE_SEASON_QUERY = gql`
    query Season($id: String!) {
        priceSeason: price_season_by_pk(id: $id) {
            id
            title
            start_at
            finish_at
            is_active
        }
    }
`;

export const PRICE_SEASONS_QUERY = gql`
    query Seasons(
        $limit: Int,
        $offset: Int,
        $orderBy: price_season_order_by! = {},
        $where: price_season_bool_exp! = {}
    ) {
        priceSeasons: price_season(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            title
            start_at
            finish_at
            is_active
        }
        priceSeasonsCount: price_season_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
