import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { ClientViewsT } from 'types/ClientView';

import { ClientViewDTO } from './ClientViewDTO';

const DTO = t.type({
    clientViews: t.array(ClientViewDTO),
    clientViewsCount: AggregateDTO,
});

type ClientViewsDTOT = t.TypeOf<typeof DTO>;

export const ClientViewsDTO = tPromise.extendDecoder<ClientViewsDTOT, ClientViewsT>(
    DTO,
    ({
        clientViews,
        clientViewsCount,
    }) => ({
        clientViews,
        clientViewsCount,
    }),
) as t.Type<ClientViewsT, ClientViewsDTOT>;
