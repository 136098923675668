import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { GROUP_VIEW_QUERY, GroupViewResponseT } from 'shared/graphql/query/groupView/GroupViewQuery';
import { EMPTY_GROUP_VIEW_RESULT, GroupViewT } from 'types/GroupView';

import { GroupViewDTO } from '../DTO/GroupViewDTO';

import { GroupViewDataT } from './GroupViewData';

const useGroupView = (): GroupViewDataT => {
    const [fetch, { loading, error }] = useLazyQuery<GroupViewResponseT>(
        GROUP_VIEW_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchGroupView = async (id: string): Promise<GroupViewT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(
                GroupViewDTO,
                EMPTY_GROUP_VIEW_RESULT,
                (groupView) => groupView,
                data?.groupView,
            );
        } catch {
            return EMPTY_GROUP_VIEW_RESULT;
        }
    };

    return {
        fetchGroupView,
        loading,
        error,
    };
};

export default useGroupView;
