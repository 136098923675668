import { gql } from '@apollo/client';

export const PERSONAL_MANAGER_FILTER_OPTIONS_QUERY = gql`
    query PersonalManagerFilterOptions {
        filterOptions: personal_manager(
            order_by: {
                employee: { full_name: asc }
            }
        ) {
            value: id
            employee {
                full_name
            }
        }
    }
`;
