import { gql } from '@apollo/client';

import { MemberCardTypeCreateFields } from './MemberCardTypeCreate';

type MemberCardTypeUpdateMutationPayloadT = MemberCardTypeCreateFields;

export type MemberCardTypeUpdateVar = {
    id: string;
    set: MemberCardTypeUpdateMutationPayloadT;
};

export type MemberCardTypeUpdateResponseDataT = {
    updateMemberCardType: {
        id: string;
    };
} | null | undefined;

export const MEMBER_CARD_TYPE_UPDATE = gql`
    mutation UpdateMemberCardType($set: member_card_type_set_input = {}, $id: String = "") {
        updateMemberCardType: update_member_card_type_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
