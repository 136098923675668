import { IUser } from 'shared/graphql/interface/User';

import { NormalizedUser } from './Type';

export const normalizer = (user: IUser): NormalizedUser => {
    return {
        id: user.id,
        store: user.store,
        username: user.username,
        role: user.role,
    };
};
