import { makeAutoObservable } from 'mobx';
import { FilterOptionT } from 'types/DataGrid';
import { FilterOptions, FilterOptionsT } from 'types/filterOptions/FilterOptions';

export default class FilterOptionsStore {
    private filterOptions = new Map<FilterOptionsT, FilterOptionT[]>(
        Object.keys(FilterOptions)
            .map((item): [FilterOptionsT, FilterOptionT[]] =>
                [FilterOptions[item as keyof typeof FilterOptions], []]),
    );

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setFilterOption(option: FilterOptionsT, data: FilterOptionT[]) {
        this.filterOptions.set(option, data);
    }

    public getFilterOption(option: FilterOptionsT): FilterOptionT[] {
        return this.filterOptions.get(option) || [];
    }
}
