import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { GroupViewT } from 'types/GroupView';

const DTO = t.type({
    id: t.string,
    name: t.string,
    group_category: Nullable(t.partial({
        id: t.string,
        name: t.string,
    })),
    age_type: Nullable(t.partial({
        id: t.string,
        name: t.string,
    })),
    min_size: Nullable(t.number),
    avg_size: Nullable(t.number),
    max_size: Nullable(t.number),
    clients_for_main_coach: t.number,
    ignore_busy_coaches: t.boolean,
    group_constant_price: t.boolean,
    correction_factors: t.array(t.record(t.string, t.number)),
});

export type GroupViewDTOT = t.TypeOf<typeof DTO>;

export const GroupViewDTO = tPromise.extendDecoder<GroupViewDTOT, GroupViewT>(
    DTO,
    ({
        id,
        name,
        group_category,
        age_type,
        min_size,
        avg_size,
        max_size,
        clients_for_main_coach,
        ignore_busy_coaches,
        group_constant_price,
        correction_factors,
    }) => ({
        id,
        name,
        categoryId: group_category?.id || group_category?.name || '',
        ageTypeId: age_type?.id || age_type?.name || '',
        minSize: min_size,
        avgSize: avg_size,
        maxSize: max_size,
        clientsForMainCoach: clients_for_main_coach,
        ignoreBusyCoaches: ignore_busy_coaches,
        groupConstantPrice: group_constant_price,
        correctionFactors: correction_factors.map((factor) => {
            const label = Object.keys(factor)[0];

            return {
                label,
                value: factor[label].toString(),
            };
        }) ?? [],
    }),
) as t.Type<GroupViewT, GroupViewDTOT>;
