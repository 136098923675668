import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { GroupViewsT } from 'types/GroupView';

import { GroupViewDTO } from './GroupViewDTO';

const DTO = t.type({
    groupViews: t.array(GroupViewDTO),
    groupViewsCount: AggregateDTO,
});

type GroupViewsDTOT = t.TypeOf<typeof DTO>;

export const GroupViewsDTO = tPromise.extendDecoder<GroupViewsDTOT, GroupViewsT>(
    DTO,
    ({
        groupViews,
        groupViewsCount,
    }) => ({
        groupViews,
        groupViewsCount,
    }),
) as t.Type<GroupViewsT, GroupViewsDTOT>;
