import { LOGIN } from 'constants/routes';

import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useCurrentUser } from 'shared/data-hook/user';

import { Spinner } from '../Spinner';

type PropsT = {
    children: React.ReactElement<any, any> | null;
};

const AuthGuard: FC<PropsT> = (props) => {
    const { children } = props;
    const { user } = useCurrentUser();

    if (user === undefined) {
        return <Spinner />;
    }

    if (user) {
        return children;
    }

    return <Navigate to={ LOGIN } replace={ true } />;
};

export default AuthGuard;
