import { gql } from '@apollo/client';

export const COACH_FILTER_OPTIONS_QUERY = gql`
    query CoachFilterOptions {
        filterOptions: coach(
            order_by: {
                employee: { full_name: asc }
            }
        ) {
            value: id
            employee {
                full_name
            }
        }
    }
`;
