import { useEffect } from 'react';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { CustomHeaderData } from 'types/DataGrid';

import { addParamsToFilterModel } from '../../utils/addParamsToFilterModel';
import { addParamsToSortModel } from '../../utils/addParamsToSortModel';

const useFetchDataForGrid = (
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    fetch: Function,
    trigger: boolean,
    columns: CustomHeaderData[],
) => {
    useEffect(() => {
        fetch({
            limit: pageSize,
            offset: page === 0 ? 0 : page * pageSize,
            orderBy: addParamsToSortModel(sortModel, columns),
            where: addParamsToFilterModel(filterModel, columns),
        }).then();
    }, [sortModel, filterModel, page, pageSize, trigger]);
};

export default useFetchDataForGrid;
