import { gql } from '@apollo/client';
import { BulkOperationsT } from 'types/BulkOperation';
import { AggregateT } from 'types/Graphql';

export type BulkOperationsResponseT = {
    bulkOperations: BulkOperationsT[];
    bulkOperationsCount: AggregateT;
};

export const BULK_OPERATIONS_QUERY = gql`
    query BulkOperationsQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: bulk_operation_order_by! = {},
        $where: bulk_operation_bool_exp! = {}
    ) {
        bulkOperations: bulk_operation(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            type
            created_at
            finished_at
            status
            error_message
            error_data
            input_data
        }
        bulkOperationsCount: bulk_operation_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
