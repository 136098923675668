import {
    Box, FormControl, InputLabel, MenuItem, Select as BaseSelect, SelectChangeEvent, Skeleton,
} from '@mui/material';
import { FilterOptionT } from 'types/DataGrid';

type PropsT = {
    label: string;
    labelId: string;
    loading?: boolean;
    options: FilterOptionT[];
    value: string;
    onChange: (event: SelectChangeEvent) => void;
    hasEmptyValue?: boolean;
    disabled?: boolean;
}

const Select = (props: PropsT) => {
    const {
        label, labelId, loading, options, disabled, value, onChange, hasEmptyValue = false,
    } = props;

    return (
        <Box>
            {
                loading ? <Skeleton variant="rounded" sx={ { mr: 1 } } width={ 240 } height={ 40 } />
                    : (
                        <FormControl sx={ { mr: 1, minWidth: 240 } } size="small">
                            { label && <InputLabel id={ labelId }>{ label }</InputLabel> }
                            <BaseSelect
                                disabled={ disabled }
                                label={ label }
                                labelId={ labelId }
                                value={ value }
                                onChange={ onChange }
                                size="small"
                            >
                                {
                                    hasEmptyValue && (
                                        <MenuItem
                                            value=""
                                        >
                                            &nbsp;
                                        </MenuItem>
                                    )
                                }
                                {
                                    options.map((item) => (
                                        <MenuItem
                                            key={ item.value }
                                            value={ item.value }
                                        >
                                            { item.label }
                                        </MenuItem>
                                    ))
                                }
                            </BaseSelect>
                        </FormControl>
                    )
            }
        </Box>
    );
};

export default Select;
