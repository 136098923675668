export const OPERATION_SELL = 'Покупка';
export const OPERATION_CANCEL = 'Отмена';
export const OPERATION_TRANSFER = 'Перенос';

export const OPERATIONS_OPTIONS = [
    {
        value: OPERATION_CANCEL,
        label: OPERATION_CANCEL,
    },
    {
        value: OPERATION_SELL,
        label: OPERATION_SELL,
    },
];
