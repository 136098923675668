import { makeAutoObservable } from 'mobx';
import { ClientT } from 'types/Client';
import { EMPTY_MEMBER_CARD_RESULT, MemberCardT } from 'types/MemberCard';
import { MemberCardTypeT } from 'types/MemberCardType';
import { PriceSeasonT } from 'types/PriceSeason';

export default class MemberCardStore {
    public memberCardList: MemberCardT[] = [];

    public memberCard: MemberCardT = EMPTY_MEMBER_CARD_RESULT;

    public priceSeasons: PriceSeasonT[] = [];

    public clients: ClientT[] = [];

    public memberCardTypes: MemberCardTypeT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get memberCards(): MemberCardT[] {
        return this.memberCardList;
    }

    public setMemberCards(memberCards: MemberCardT[], count?: number) {
        this.memberCardList = memberCards;
        this.count = count ?? 0;
    }

    public setMemberCard(memberCard: MemberCardT) {
        this.memberCard = memberCard;
    }
}
