import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';

import { useLogin } from './domain/hooks/UseLogin';
import { LoginForm, LoginFormFooter, LoginFormWrapper } from './domain/Styled';

const Login: FC = () => {
    const { form, loading, onLogin } = useLogin();
    const { control, handleSubmit } = form;

    return (
        <LoginFormWrapper>
            <LoginForm>
                <Typography variant="h4">Champion Admin</Typography>
                <form onSubmit={ handleSubmit(onLogin) }>
                    <Controller
                        name="username"
                        control={ control }
                        rules={ { required: true } }
                        render={ ({ field }) => (
                            <TextField
                                { ...field }
                                disabled={ loading }
                                required={ true }
                                label="Логин"
                                size="medium"
                                fullWidth
                                margin="normal"
                            />
                        ) }
                    />
                    <Controller
                        name="password"
                        control={ control }
                        rules={ { required: true } }
                        render={ ({ field }) => (
                            <TextField
                                { ...field }
                                disabled={ loading }
                                required={ true }
                                label="Пароль"
                                size="medium"
                                fullWidth
                                type="password"
                                margin="normal"
                            />
                        ) }
                    />
                    <LoginFormFooter>
                        <LoadingButton
                            loading={ loading }
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            Войти
                        </LoadingButton>
                    </LoginFormFooter>
                </form>
            </LoginForm>
        </LoginFormWrapper>
    );
};

export default Login;
