import { USER_ITEM } from 'constants/localStorage';

import React, { useEffect, useRef, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { camelizeKeys } from 'humps';
import { USER, UserVar } from 'shared/graphql/subscription/User';
import logoutAction from 'shared/helper/logout/logoutAction';

import { LocalUserType, UserSubscriptionHookType, UserSubscriptionType } from './Type';

export const useUserSubscription = (): UserSubscriptionHookType => {
    const client = useApolloClient();
    const subscriptionRef = useRef<any>(null);

    const [localUserState, setLocalUserState] = useState<LocalUserType | null>(null);
    const [data, setData] = useState<UserSubscriptionType | null | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApolloError | null>(null);

    const getLocalStorageData = () => {
        const localUserJson = localStorage.getItem(USER_ITEM);

        if (localUserJson) {
            return JSON.parse(localUserJson) as LocalUserType;
        }

        return null;
    };

    useEffect(() => {
        const localUser = getLocalStorageData();

        if (localUser?.id) {
            setLocalUserState(localUser);

            return;
        }

        setData(null);
    }, []);

    useEffect(() => {
        if (localUserState) {
            localStorage.setItem(USER_ITEM, JSON.stringify(localUserState));
        }

        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }

        if (localUserState) {
            setLoading(true);
            subscriptionRef.current = client
                .subscribe<UserSubscriptionType, UserVar>({
                    query: USER,
                    variables: { id: localUserState.id },
                })
                .subscribe(
                    ({ data: userData }) => {
                        setData(camelizeKeys(userData as object) as UserSubscriptionType);
                        setError(null);
                        setLoading(false);
                    },
                    (reject) => {
                        setError(reject);
                        setLoading(false);
                        logoutAction();
                        // TODO: Sentry log
                    },
                );
        }
    }, [localUserState]);

    const setUserId = (id: string | null) => {
        setData(undefined);

        if (!id) {
            setLocalUserState(null);

            return;
        }

        const nextLocalUserState: LocalUserType = { id };

        setLocalUserState(nextLocalUserState);
    };

    const currentUser = React.useMemo(() => {
        if (data) {
            return data.user;
        }

        if (loading) {
            return undefined;
        }

        return data === null ? null : undefined;
    }, [data, loading]);

    return {
        user: currentUser,
        loadingSubscription: loading || false,
        errorSubscription: error || null,
        setUserId,
    };
};
