import React, { FC } from 'react';
import { DialogContent, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Modal from 'shared/component/Modal/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';

type PropsT = {
    readonly inputData: any;
    readonly onOpen: any;
    readonly isOpen: boolean;
}

const BulkOperationInputData: FC<PropsT> = observer((props) => {
    const { inputData, onOpen, isOpen } = props;

    return (
        <Modal open={ isOpen }>
            <ModalTitle onClose={ onOpen }>Данные</ModalTitle>
            <DialogContent>
                { Object.keys(inputData).map((key: string) => (
                    <Typography key={ key }>{ `${key}: ${inputData[key]}` }</Typography>
                )) }
            </DialogContent>
        </Modal>
    );
});

export default BulkOperationInputData;
