import { gql } from '@apollo/client';
import { CourtTypeT } from 'types/CourtType';

export type CourtCategoryDeleteResponseDataT = {
    deleteCourtType: {
        id: CourtTypeT['id'];
    };
} | null | undefined;

export type CourtCategoryDeleteVarT = {
    id: CourtTypeT['id'];
};

export const COURT_CATEGORY_DELETE = gql`
    mutation DeleteCourtCategory($id: String = "") {
        deleteCourtType: delete_court_category_by_pk(id: $id) {
            id
        }
    }
`;
