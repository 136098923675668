import { PROPERTY_TITLE } from 'constants/propertyNames';

import { object, string } from 'yup';

export type OrganizationLocationFormFields = {
    name: string;
}

export enum ORGANIZATION_LOCATION_FIELD_KEYS {
    name = 'name',
}

export const ORGANIZATION_LOCATION_DEFAULT_VALUES: OrganizationLocationFormFields = {
    name: '',
};

export const ORGANIZATION_LOCATION_LABEL_DATA = {
    name: PROPERTY_TITLE,
};

export const ORGANIZATION_LOCATION_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
}).required();

export const getOrganizationLocationValidationSchema = () => ORGANIZATION_LOCATION_VALIDATION_SCHEMA;
