export const LINK_OPERATOR = {
    and: '_and',
    or: '_or',
};

export const COMPARE_OPERATOR = {
    '=': '_eq',
    '!=': '_neq',
    '>': '_gt',
    '<': '_lt',
    '>=': '_gte',
    '<=': '_lte',
    is: '_eq',
    equals: '_eq',
    not: '_neq',
    contains: '_ilike',
    startsWith: '_similar',
    endsWith: '',
    isEmpty: '',
    isNotEmpty: '',
    isAnyOf: '_in',
};
export type LinkOperatorT = keyof typeof LINK_OPERATOR;
export type HasuraOperatorT = typeof LINK_OPERATOR[LinkOperatorT];
export type CompareOperatorT = keyof typeof COMPARE_OPERATOR;
