import { ERROR_LOGIN_COMMON } from 'constants/notificationMessage';

import LoginStatus from 'enums/LoginStatus';

type LoginResultT = {
    message: string;
    status: LoginStatus;
    userId: string | null;
};

export const EMPTY_LOGIN_RESULT: LoginResultT = {
    message: ERROR_LOGIN_COMMON,
    status: LoginStatus.Fail,
    userId: '',
};

export default LoginResultT;
