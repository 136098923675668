export type BulkOperationT = {
    id: string;
    type: string;
    createdAt: Date | null;
    finishedAt: Date | null;
    status: string;
    errorMessage: string | null;
    errorData: string[];
    inputData: Record<string, (string | number | boolean)>;
};

export type BulkOperationsT = {
    bulkOperations: BulkOperationT[];
    bulkOperationsCount: number;
};

export const EMPTY_BULK_OPERATIONS_RESULT: BulkOperationsT = {
    bulkOperations: [],
    bulkOperationsCount: 0,
};
