import { gql } from '@apollo/client';
import { CourtTypeT } from 'types/CourtType';
import { AggregateT } from 'types/Graphql';

export type CourtTypesResponseT = {
    courtTypes: CourtTypeT[];
    courtTypesCount: AggregateT;
};

export type CourtTypeResponseT = {
    courtType: CourtTypeT;
}

export const COURT_TYPE_FILTER_OPTIONS_QUERY = gql`
    query CourtTypeFilterOptions {
        filterOptions: court_type(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;

export const COURT_TYPES_QUERY = gql`
    query CourtTypes(
        $limit: Int,
        $offset: Int,
        $orderBy: court_type_order_by! = {},
        $where: court_type_bool_exp! = {}
    ) {
        courtTypes: court_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            name
            duration
            use_halves
            periods
            courts (order_by: {order: asc}) {
                id
                name
                order
                bonus_classes
                schedule
                organization_location {
                    id
                    name
                }
                organization {
                    id
                    name
                }
                court_type {
                    id
                    name
                }
                camera_id
            }
        }
        courtTypesCount: court_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const COURT_TYPE_QUERY = gql`
    query CourtType($id: String!) {
        courtType: court_type_by_pk(id: $id) {
            id
            name
            duration
            use_halves
            periods
        }
    }
`;

export const COURT_TYPES_OPTIONS_QUERY = gql`
    query CourtTypesOptions {
        filterOptions: court_type(
            order_by: { name: asc }
        ) {
            value: id
            label: name
        }
    }
`;
