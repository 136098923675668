import { gql } from '@apollo/client';

export type OrganizationLocationDeleteResponseDataT = {
    deleteOrganizationLocation: {
        id: string;
    };
} | null | undefined;

export type OrganizationLocationDeleteVarT = {
    id: string;
};

export const ORGANIZATION_LOCATION_DELETE = gql`
    mutation DeleteOrganizationLocation($id: String = "") {
        deleteOrganizationLocation: delete_organization_location_by_pk(id: $id) {
            id
        }
    }
`;
