import { CoachT } from './Coach';
import { GroupT } from './Group';

export type CoachesInGroupT = {
    id: string;
    mainCoach: CoachT;
    group: GroupT | null;
    datetime: Date;
    coaches: CoachT[];
};

export type CoachesInGroupsT = {
    coachesInGroups: CoachesInGroupT[];
    coachesInGroupsCount: number;
};

export const EMPTY_COACHES_IN_GROUPS_RESULT: CoachesInGroupsT = {
    coachesInGroups: [],
    coachesInGroupsCount: 0,
};

export const COACHES_IN_GROUP_DATETIME_ASC = {
    implementations_aggregate: {
        max: {
            datetime: 'asc',
        },
    },
};
