import { ClientFullNameT } from './Client';

export type PaymentT = {
    id: string;
    paymentDocument: {
        number: string;
    };
    datetime: Date;
    clientId: string | null;
    payer: string | null;
    paymentMethod: {
        title: string;
    } | null;
    performer: string | null;
    userId: string | null;
    comment: string | null;
    organizationLocation: {
        name: string;
    } | null;
    total: number;
    paymentInFiat: number | null;
    paymentInBonus: number | null;
    paymentType: {
        title: string;
    } | null;
    recipient: ClientFullNameT | null;
};

export type PaymentsT = {
    payments: PaymentT[];
    paymentsCount: number;
};

export const EMPTY_PAYMENTS: PaymentsT = {
    payments: [],
    paymentsCount: 0,
};
