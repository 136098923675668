import React, { FC, MouseEventHandler } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import { useStore } from 'store';

import DeleteModal from '../../Modal/DeleteModal';

type PropsT = {
    id: string;
    title: string;
    actionDelete: (id: string) => Promise<any>;
};

const DeleteButton: FC<PropsT> = (props) => {
    const {
        id, title, actionDelete,
    } = props;
    const { onOpen } = useStore('deleteItemModal');

    const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id || 'id');
    };

    const onDeleteButtonClick = () => {
        actionDelete(id).then();
    };

    return (
        <>
            <Tooltip title="Удалить" placement="top">
                <IconButton onClick={ handleButtonClick }>
                    <DeleteIcon color="error" />
                </IconButton>
            </Tooltip>
            <DeleteModal
                id={ id || 'id' }
                title={ title }
                onDeleteButtonClick={ onDeleteButtonClick }
            />
        </>
    );
};

export default DeleteButton;
