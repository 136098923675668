import { useMemo } from 'react';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { CLIENT_HEADER_DATA } from './HeaderData';

export const prepareClientHeaderData = (personalManagers: FilterOptionT[]) =>
    useMemo(() => mapFields(CLIENT_HEADER_DATA, {
        personalManagerFullName: personalManagers,
    }), [personalManagers]);
