import { useEffect } from 'react';
import useOrganization from 'shared/data-hook/organization/fetch/fetchOne/useOrganization';
import { useStore } from 'store';

const useFetchOrganization = () => {
    const { fetchOrganization, loading } = useOrganization();
    const { updateOrganizationId, isOpen, setOrganization } = useStore('updateOrganizationModal');

    useEffect(() => {
        if (isOpen && updateOrganizationId) {
            fetchOrganization(updateOrganizationId).then((organization) => {
                setOrganization(organization);
            });
        }
    }, [isOpen, updateOrganizationId]);

    return { loading };
};

export default useFetchOrganization;
