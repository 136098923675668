import { makeAutoObservable } from 'mobx';
import { EMPTY_MEMBER_CARD_RESULT, MemberCardT } from 'types/MemberCard';

export default class UpdateMemberCardModal {
    public isOpen: boolean = false;

    public id: string = '';

    public memberCard: MemberCardT = EMPTY_MEMBER_CARD_RESULT;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public open() {
        this.isOpen = true;
    }

    public close() {
        this.isOpen = false;
    }

    public setId(id: string) {
        this.id = id;
    }
}
