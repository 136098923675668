import { useMutation } from '@apollo/client';
import { CourtTypeFormFields } from 'page/Court/components/formData';
import { CreateCourtTypeDataT } from 'shared/data-hook/courtType/create/CreateCourtTypeData';
import {
    COURT_TYPE_CREATE,
    CourtTypeCreateResponseDataT,
    CourtTypeCreateVarT,
} from 'shared/graphql/mutation/courtType/CourtTypeCreate';

const useCreateCourtType = (): CreateCourtTypeDataT => {
    const [create, { loading, error }] = useMutation<CourtTypeCreateResponseDataT, CourtTypeCreateVarT>(
        COURT_TYPE_CREATE,
    );

    const createAction = async (input: CourtTypeFormFields) => {
        try {
            const { data } = await create({
                variables: {
                    object: {
                        name: input.name,
                        duration: input.duration,
                        use_halves: input.useHalves,
                        periods: input.periods,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message); // TODO: use Sentry
            throw error;
        }
    };

    return {
        loading,
        error,
        courtTypeCreate: createAction,
    };
};

export default useCreateCourtType;
