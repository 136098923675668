import React, { FC } from 'react';
import {
    Box, InputLabel, ToggleButton as BaseToggleButton, ToggleButtonGroup,
} from '@mui/material';
import parse from 'html-react-parser';

type PropT = {
    buttons: Array<{
        value: any;
        label: string;
    }>;
    formAlignment: any;
    setFormAlignment: Function;
    empty?: boolean;
    label?: string;
    fullWidth?: boolean;
}

const ToggleButton: FC<PropT> = (props) => {
    const {
        buttons, formAlignment, setFormAlignment, empty, label, fullWidth,
    } = props;

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (!newAlignment && !empty) {
            return;
        }

        setFormAlignment(newAlignment);
    };

    return (
        <Box>
            <InputLabel>{ label }</InputLabel>
            <ToggleButtonGroup
                color="primary"
                value={ formAlignment }
                exclusive
                onChange={ handleChange }
                size="small"
                sx={ { width: '100%' } }
            >
                {
                    buttons.map((button) => (
                        <BaseToggleButton sx={ { ...(fullWidth && { width: `calc(100% / ${buttons.length})` }) } } value={ button.value } key={ `${button.value}-${button.label}` }>{ parse(button.label) }</BaseToggleButton>
                    ))
                }
            </ToggleButtonGroup>
        </Box>
    );
};

export default ToggleButton;
