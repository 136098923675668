import { MODAL_TITLE_UPDATE_COURT_CATEGORY } from 'constants/modalTitles';

import { FC, memo, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import {
    COURT_CATEGORY_DEFAULT_VALUES,
    COURT_CATEGORY_FIELD_KEYS, COURT_CATEGORY_LABEL_DATA,
    CourtCategoryFormFields, getCourtCategoryValidationSchema,
} from 'page/CourtCategory/components/formData';
import MultipleSelect from 'shared/component/Form/MultipleSelect';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import { COURT_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/court/CourtQuery';
import useFetchCourtCategory from 'shared/hook/courtCategory/fetch/fetchOne/useFetchCourtCategory';
import useUpdateCourtCategory from 'shared/hook/courtCategory/update/useUpdateCourtCategory';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

const UpdateCourtCategoryModal: FC = observer(() => {
    const {
        id, onClose, courtCategory, isOpen,
    } = useStore('updateCourtCategoryModal');
    const { updateCourtCategory, loading: loadingSave } = useUpdateCourtCategory();

    const { loading: loadingFetch } = useFetchCourtCategory(id);
    const { loading: loadingCourt } = useFetchFilterOptions(COURT_FILTER_OPTIONS_QUERY, 'court');

    const { getFilterOption } = useStore('filterOptions');
    const courtOptions = getFilterOption('court');

    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<CourtCategoryFormFields>({
        defaultValues: COURT_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getCourtCategoryValidationSchema()),
    });

    const { field: courtsField } = useController({ control, name: COURT_CATEGORY_FIELD_KEYS.courts });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateCourt = (data: CourtCategoryFormFields) => {
        updateCourtCategory(data, id).then(() => {
            handleCloseModal();
        });
    };

    useEffect(() => {
        if (!courtCategory) {
            return;
        }

        Object.keys(courtCategory).forEach((key) => {
            if (key !== 'courts') {
                setValue(key as keyof CourtCategoryFormFields, (courtCategory[key as keyof CourtCategoryFormFields] as string));
            }
        });

        setValue('courts', courtCategory.courts.map(({ value: courtId }) => courtId));
    }, [courtCategory]);

    return (
        <Modal open={ isOpen } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { loadingFetch ?
                    (
                        <Skeleton height={ 32 } />
                    ) : (
                        `${MODAL_TITLE_UPDATE_COURT_CATEGORY} ${courtCategory?.name}`
                    ) }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <DialogContent dividers={ true }>
                    { loadingFetch ? <Spinner /> : (
                        <Grid2 spacing={ 2 } container padding={ 2 }>
                            <Grid2 xs={ 12 }>
                                <TextField
                                    control={ control }
                                    name={ COURT_CATEGORY_FIELD_KEYS.name }
                                    key={ `key-court-category-${courtCategory?.name} ` }
                                    label={ COURT_CATEGORY_LABEL_DATA.name }
                                    type="text"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <TextField
                                    control={ control }
                                    name={ COURT_CATEGORY_FIELD_KEYS.position }
                                    key={ `key-court-category-${courtCategory?.position}` }
                                    label={ COURT_CATEGORY_LABEL_DATA.position }
                                    type="number"
                                />
                            </Grid2>
                            <Grid2 xs={ 12 }>
                                <MultipleSelect
                                    control={ control }
                                    name={ COURT_CATEGORY_FIELD_KEYS.courts }
                                    selectedValues={ courtsField.value }
                                    loading={ loadingCourt }
                                    label={ COURT_CATEGORY_LABEL_DATA.courts }
                                    options={ courtOptions }
                                />
                            </Grid2>
                        </Grid2>
                    ) }
                </DialogContent>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdateCourt) }
                    loading={ loadingSave }
                    disabled={ !isDirty || !isValid }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default memo(UpdateCourtCategoryModal);
