import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PriceTypeT } from 'types/PriceType';

export type PriceTypeResponseT = {
    priceType: PriceTypeT;
};

export type PriceTypesResponseT = {
    priceTypes: PriceTypeT[];
    priceTypesCount: AggregateT;
};

export const PRICE_TYPE_FILTER_OPTIONS_QUERY = gql`
    query PriceTypeFilterOptions {
        filterOptions: price_type(
            order_by: { title: asc }
        ) {
            value: id
            label: title
        }
    }
`;

export const PRICE_TYPES_QUERY = gql`
    query PriceTypes(
        $limit: Int,
        $offset: Int,
        $orderBy: price_type_order_by! = {},
        $where: price_type_bool_exp! = {}
    ) {
        priceTypes: price_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            title
            position
            is_active
            is_group_available
            is_coach_available
            price_sales_rounds(order_by: {position: asc}) {
                id
                position
                title
                price_type_id
            }
        }
        priceTypesCount: price_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const PRICE_TYPE_QUERY = gql`
    query PriceType($id: String!) {
        priceType: price_type_by_pk(id: $id) {
            id
            is_active
            position
            title
            is_group_available
            is_coach_available
        }
    }
`;
