import { makeAutoObservable } from 'mobx';
import { CoachCategoryT } from 'types/CoachCategory';
import { GroupViewT } from 'types/GroupView';

export default class CreatePriceModal {
    public isOpen: boolean = false;

    public priceTypeId: string = '';

    public priceSeasonId: string = '';

    public groupViews: GroupViewT[] = [];

    public coachCategories: CoachCategoryT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }

    public setGroupViews(groupViews: GroupViewT[]) {
        this.groupViews = groupViews;
    }

    public setCoachCategories(coachCategories: CoachCategoryT[]) {
        this.coachCategories = coachCategories;
    }

    public setPriceTypeId(priceTypeId: string) {
        this.priceTypeId = priceTypeId;
    }

    public setPriceSeasonId(priceSeasonId: string) {
        this.priceSeasonId = priceSeasonId;
    }
}
