import { MODAL_TITLE_UPDATE_ORGANIZATION_LOCATION } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchOrganizationLocation
    from 'shared/hook/organizationLocation/fetch/fetchOne/useFetchOrganizationLocation';
import useUpdateOrganizationLocation
    from 'shared/hook/organizationLocation/update/useUpdateOrganizationLocation';
import { useStore } from 'store';

import {
    getOrganizationLocationValidationSchema,
    ORGANIZATION_LOCATION_DEFAULT_VALUES, ORGANIZATION_LOCATION_FIELD_KEYS, ORGANIZATION_LOCATION_LABEL_DATA,
    OrganizationLocationFormFields,
} from './formData';

const UpdateOrganizationLocationModal: FC = observer(() => {
    const {
        isOpen, onClose, updateOrganizationLocationId, organizationLocation,
    } = useStore('updateOrganizationLocationModal');
    const { updateOrganizationLocation } = useUpdateOrganizationLocation();
    const { loading: loadingFetch } = useFetchOrganizationLocation();

    const {
        control, handleSubmit, setValue, reset, formState: { isValid, isDirty },
    } = useForm<OrganizationLocationFormFields>({
        defaultValues: ORGANIZATION_LOCATION_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getOrganizationLocationValidationSchema()),
    });

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleUpdateOrganizationLocation = (data: OrganizationLocationFormFields) => {
        updateOrganizationLocation(data, updateOrganizationLocationId).then(() => handleClose());
    };

    useEffect(() => {
        setValue(ORGANIZATION_LOCATION_FIELD_KEYS.name, organizationLocation?.name || '');
    }, [organizationLocation]);

    return (
        <Modal open={ isOpen }>
            <ModalTitle onClose={ handleClose }>{ MODAL_TITLE_UPDATE_ORGANIZATION_LOCATION }</ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            label={ ORGANIZATION_LOCATION_LABEL_DATA.name }
                            name={ ORGANIZATION_LOCATION_FIELD_KEYS.name }
                        />
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    color="success"
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    onClick={ handleSubmit(handleUpdateOrganizationLocation) }
                >
                    Добавить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateOrganizationLocationModal;
