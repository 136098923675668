import { makeAutoObservable } from 'mobx';

export default class CreateItemModal {
    public createdItemId = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(createdItemId: string) {
        this.createdItemId = createdItemId;
    }

    public onClose() {
        this.createdItemId = '';
    }
}
