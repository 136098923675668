import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    BULK_OPERATIONS_QUERY,
    BulkOperationsResponseT,
} from 'shared/graphql/query/bulkOperation/BulkOperationQuery';
import { EMPTY_BULK_OPERATIONS_RESULT } from 'types/BulkOperation';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';

import { filterData } from '../../../utils/filter/filterData';
import { BulkOperationsDTO } from '../DTO/BulkOperationsDTO';

import { BulkOperationsDataT } from './BulkOperationsData';

const useBulkOperations = (): BulkOperationsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<BulkOperationsResponseT, QueryVarsT>(
        BULK_OPERATIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedBulkOperationData = decodeDTO(
        BulkOperationsDTO,
        EMPTY_BULK_OPERATIONS_RESULT,
        (bulkOperations) => bulkOperations,
        {
            bulkOperations: data?.bulkOperations,
            bulkOperationsCount: data?.bulkOperationsCount,
        },
    );

    const fetchBulkOperations = async ({
        limit, offset, orderBy = { created_at: 'desc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedBulkOperationData.bulkOperations,
        count: decodedBulkOperationData.bulkOperationsCount,
        fetchBulkOperations,
        loading,
    };
};

export default useBulkOperations;
