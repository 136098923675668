import { TIME_24_HOURS_30_STEP } from 'constants/hours';
import { MODAL_TITLE_UPDATE_COURT } from 'constants/modalTitles';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_SHORT_OPTIONS } from 'constants/weekdays';

import React, {
    FC, useEffect, useMemo,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { COURT_TYPES_OPTIONS_QUERY } from 'shared/graphql/query/courtType/CourtTypeQuery';
import { ORGANIZATION_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/organization/OrganizationQuery';
import {
    ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY,
} from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import useFetchCourt from 'shared/hook/court/fetch/fetchOne/useFetchCourt';
import useUpdateCourt from 'shared/hook/court/update/useUpdateCourt';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import { useStore } from 'store';

import {
    COURT_DEFAULT_VALUES, COURT_FIELD_KEYS, COURT_LABEL_DATA, COURT_VALIDATION_SCHEMA,
    CourtFormFields,
} from './formData';

const UpdateCourtModal: FC = observer(() => {
    const {
        isOpen, onClose, updateItemId, court,
    } = useStore('updateCourtModal');
    const { getFilterOption } = useStore('filterOptions');
    const { updateCourt, loading: loadingUpdate } = useUpdateCourt();

    const courtTypeOptions = getFilterOption('courtType');
    const organizationOptions = getFilterOption('organization');
    const organizationLocationOptions = getFilterOption('organizationLocation');

    const { loading: loadingFetch } = useFetchCourt(updateItemId);
    const { loading: loadingCourtTypes } = useFetchFilterOptions(COURT_TYPES_OPTIONS_QUERY, 'courtType');
    const { loading: loadingOrganizations } = useFetchFilterOptions(ORGANIZATION_FILTER_OPTIONS_QUERY, 'organization');
    const { loading: loadingOrganizationLocations } = useFetchFilterOptions(ORGANIZATION_LOCATION_FILTER_OPTIONS_QUERY, 'organizationLocation');

    const {
        control, handleSubmit, reset, setValue, formState: { isDirty, isValid },
    } = useForm<CourtFormFields>({
        defaultValues: COURT_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(COURT_VALIDATION_SCHEMA),
    });
    const { fields } = useFieldArray<CourtFormFields>({
        name: 'schedule',
        control,
    });

    const courtInfo: CourtFormFields = useMemo(
        () => {
            const info = { ...COURT_DEFAULT_VALUES };

            if (court) {
                Object.keys(court).forEach((key) => {
                    info.organizationLocationId = court.organizationLocationId?.id;
                    info.organizationId = court.organizationId?.id || '';
                    info.courtTypeId = court.courtTypeId?.id;
                    info[key as keyof CourtFormFields] = court[key as keyof CourtFormFields] as never;
                });
            }

            return info;
        },
        [court],
    );

    const handleClose = () => {
        onClose();
        reset();
    };

    const handleUpdateCourt = (data: CourtFormFields) => {
        updateCourt(data, updateItemId).then(() => handleClose());
    };

    useEffect(() => {
        Object.keys(courtInfo).forEach((key: string) => {
            setValue(key as keyof CourtFormFields, courtInfo[key as keyof CourtFormFields]);
        });
    }, [court, courtInfo]);

    return (
        <Modal maxWidth="lg" open={ isOpen }>
            <ModalTitle onClose={ handleClose }>{ loadingFetch ? <Skeleton height={ 32 } /> : `${MODAL_TITLE_UPDATE_COURT} ${court?.name}` }</ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? (
                    <Skeleton height={ 200 } />
                ) : (
                    <Grid2 container spacing={ 2 } padding={ 2 } alignItems="center" justifyContent="center">
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COURT_FIELD_KEYS.name as keyof CourtFormFields }
                                label={ COURT_LABEL_DATA.name }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COURT_FIELD_KEYS.order as keyof CourtFormFields }
                                label={ COURT_LABEL_DATA.order }
                                type="number"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COURT_FIELD_KEYS.cameraId }
                                label={ COURT_LABEL_DATA.cameraId }
                            />
                        </Grid2>
                        <Grid2 xs={ 4 }>
                            <Select
                                options={ courtTypeOptions }
                                loading={ loadingCourtTypes }
                                control={ control }
                                name={ COURT_FIELD_KEYS.courtTypeId }
                                label={ COURT_LABEL_DATA.courtTypeId }
                            />
                        </Grid2>
                        <Grid2 xs={ 4 }>
                            <Select
                                options={ organizationOptions }
                                loading={ loadingOrganizations }
                                control={ control }
                                name={ COURT_FIELD_KEYS.organizationId }
                                label={ COURT_LABEL_DATA.organizationId }
                            />
                        </Grid2>
                        <Grid2 xs={ 4 }>
                            <Select
                                options={ organizationLocationOptions }
                                loading={ loadingOrganizationLocations }
                                control={ control }
                                name={ COURT_FIELD_KEYS.organizationLocationId }
                                label={ COURT_LABEL_DATA.organizationLocationId }
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Switch
                                size="small"
                                control={ control }
                                name={ COURT_FIELD_KEYS.bonusClasses }
                                label={ COURT_LABEL_DATA.bonusClasses }
                                type="boolean"
                            />
                        </Grid2>
                        { fields.map((field, index) => (
                            <Grid2 container key={ field.id } alignItems="flex-start" xs={ 12 }>
                                <Grid2 pt={ 4.5 }>
                                    <Typography fontSize={ 18 } textAlign="center">{ WEEK_DAYS_VALUE_SHORT_OPTIONS[index].label }</Typography>
                                </Grid2>
                                <Grid2 flex={ 1 }>
                                    <Select
                                        label={ PROPERTY_START }
                                        options={ TIME_24_HOURS_30_STEP }
                                        control={ control }
                                        name={ `schedule.${index}.start` }
                                        customError
                                    />
                                </Grid2>
                                <Grid2 flex={ 1 }>
                                    <Select
                                        label={ PROPERTY_END }
                                        options={ TIME_24_HOURS_30_STEP }
                                        control={ control }
                                        name={ `schedule.${index}.end` }
                                        customError
                                    />
                                </Grid2>
                            </Grid2>
                        )) }
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ loadingUpdate }
                    disabled={ !isValid || !isDirty }
                    onClick={ handleSubmit(handleUpdateCourt) }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateCourtModal;
