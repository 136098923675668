import {
    ERROR_ORGANIZATION_LOCATION_CREATE,
    SUCCESS_ORGANIZATION_LOCATION_CREATE,
} from 'constants/notificationMessage';

import { OrganizationLocationFormFields } from 'page/OrganizationLocation/components/formData';
import useCreateOrganizationLocation
    from 'shared/data-hook/organizationLocation/create/useCreateOrganizationLocation';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

const useOrganizationLocationCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { organizationLocationCreate, loading, error } = useCreateOrganizationLocation();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const createOrganizationLocation = async (input: OrganizationLocationFormFields) => {
        const response = await organizationLocationCreate(input);

        if (!error || !response || !response.createOrganizationLocation) {
            showSuccess(SUCCESS_ORGANIZATION_LOCATION_CREATE);
        } else {
            showError(ERROR_ORGANIZATION_LOCATION_CREATE);
        }

        toggleRefetchTrigger();
    };

    return {
        createOrganizationLocation,
        loading,
    };
};

export default useOrganizationLocationCreate;
