import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ImplementationsT } from 'types/Implementation';

import { AggregateDTO } from '../../../utils/DTO/AggregateDTO';

import { ImplementationDTO } from './ImplementationDTO';

const DTO = t.type({
    implementations: t.array(ImplementationDTO),
    implementationsCount: AggregateDTO,
});

type ImplementationsDTOT = t.TypeOf<typeof DTO>;

export const ImplementationsDTO = tPromise.extendDecoder<ImplementationsDTOT, ImplementationsT>(
    DTO,
    ({
        implementations,
        implementationsCount,
    }) => ({
        implementations,
        implementationsCount,
    }),
) as t.Type<ImplementationsT, ImplementationsDTOT>;
