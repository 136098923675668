import { COURT_VIDEO_ANALYTICS_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareDataGrid } from '../domain/prepareDataGrid';

type PropsT = {
    loading: boolean;
    date: Dayjs;
} & DataGridPropsT;

const CourtVideoAnalyticsList: FC<PropsT> = observer((props) => {
    const {
        date, loading, pageSize, page, sortModel, setPageSize, setPage, setSortModel, filterModel, setFilterModel,
    } = props;

    const {
        columns, columnGroupingModel, rows,
    } = prepareDataGrid(date);

    return (
        <DataGrid
            rows={ rows }
            rowCount={ rows.length }
            columns={ columns }
            columnGroupingModel={ columnGroupingModel }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            sortModel={ sortModel }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            filterModel={ filterModel }
            setFilterModel={ setFilterModel }
            getRowClassName={ (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? '' : 'data-grid-row-odd') }
            hideFooter
            initialState={ COURT_VIDEO_ANALYTICS_DATA_GRID_INITIAL_STATE }
            rowAutoHeight={ true }
        />
    );
});

export default CourtVideoAnalyticsList;
