import { BONUS } from 'constants/currency';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_BONUSES_SUM,
    PROPERTY_CLIENT,
    PROPERTY_DATETIME_OPERATION,
    PROPERTY_DOCUMENT,
    PROPERTY_FIAT_SUM,
    PROPERTY_ORGANIZATION,
    PROPERTY_PAYMENT_METHOD,
    PROPERTY_PAYMENT_TYPE,
    PROPERTY_PERFORMER,
    PROPERTY_RECIPIENT_FIRST_NAME,
    PROPERTY_RECIPIENT_LAST_NAME,
    PROPERTY_RECIPIENT_MIDDLE_NAME,
    PROPERTY_TRANSACTION_SUM,
} from 'constants/propertyNames';

import React from 'react';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const renderCost = (value: number, currency?: string) => {
    const { serviceApp } = useStore('serviceApp');

    return (
        <span>{ value } { currency ?? serviceApp.fiatCurrencyTitle }</span>
    );
};

export const PAYMENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT,
        field: 'payer',
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['payer'],
        hasuraFilterFields: ['payer'],
        renderCell: (params) => renderStringCell(params.row?.payer),
    },
    {
        headerName: PROPERTY_RECIPIENT_LAST_NAME,
        field: 'recipientLastName',
        minWidth: 175,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['client_datum', 'client', 'last_name'],
        hasuraFilterFields: ['client_datum', 'client', 'last_name'],
        renderCell: (params) => renderStringCell(params.row?.recipient?.lastName),
    },
    {
        headerName: PROPERTY_RECIPIENT_FIRST_NAME,
        field: 'recipientFirstName',
        minWidth: 175,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['client_datum', 'client', 'first_name'],
        hasuraFilterFields: ['client_datum', 'client', 'first_name'],
        renderCell: (params) => renderStringCell(params.row?.recipient?.firstName),
    },
    {
        headerName: PROPERTY_RECIPIENT_MIDDLE_NAME,
        field: 'recipientMiddleName',
        minWidth: 175,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['client_datum', 'client', 'middle_name'],
        hasuraFilterFields: ['client_datum', 'client', 'middle_name'],
        renderCell: (params) => renderStringCell(params.row?.recipient?.middleName),
    },
    {
        headerName: PROPERTY_DOCUMENT,
        field: 'paymentDocument',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['payment_document', 'number'],
        hasuraFilterFields: ['payment_document', 'number'],
        renderCell: (params) => renderStringCell(params.row?.paymentDocument.number),
    },
    {
        headerName: PROPERTY_DATETIME_OPERATION,
        field: 'datetime',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraSortFields: ['datetime'],
        hasuraFilterFields: ['datetime'],
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_PAYMENT_METHOD,
        field: 'paymentMethod',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['payment_method', 'title'],
        hasuraFilterFields: ['payment_method', 'title'],
        renderCell: (params) => renderStringCell(params.row?.paymentMethod?.title),
    },
    {
        headerName: PROPERTY_PERFORMER,
        field: 'performer',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraSortFields: ['performer'],
        hasuraFilterFields: ['performer'],
        renderCell: (params) => renderStringCell(params.row?.performer),
    },
    {
        headerName: PROPERTY_ORGANIZATION,
        field: 'organizationLocation',
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['organization_location', 'name'],
        hasuraFilterFields: ['organization_location', 'name'],
        renderCell: (params) => renderStringCell(params.row?.organizationLocation?.name),
    },
    {
        headerName: PROPERTY_PAYMENT_TYPE,
        field: 'paymentType',
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraSortFields: ['payment_type', 'title'],
        hasuraFilterFields: ['payment_type', 'title'],
        renderCell: (params) => renderStringCell(params.row?.paymentType?.title),
    },
    {
        headerName: PROPERTY_FIAT_SUM,
        field: 'paymentInFiat',
        minWidth: 75,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['payment_in_fiat'],
        hasuraFilterFields: ['payment_in_fiat'],
        renderCell: (params) => renderCost(params.row?.paymentInFiat),
    },
    {
        headerName: PROPERTY_BONUSES_SUM,
        field: 'paymentInBonus',
        minWidth: 75,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['payment_in_bonus'],
        hasuraFilterFields: ['payment_in_bonus'],
        renderCell: (params) => renderCost(params.row?.paymentInBonus, BONUS),
    },
    {
        headerName: PROPERTY_TRANSACTION_SUM,
        field: 'total',
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraSortFields: ['total'],
        hasuraFilterFields: ['total'],
        renderCell: (params) => renderCost(params.row?.total),
    },
];
