import { makeAutoObservable } from 'mobx';
import { PaymentT } from 'types/Payment';

export default class CreatePaymentTypeModal {
    public isOpen: boolean = false;

    public paymentId: PaymentT['id'] = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onClose() {
        this.isOpen = false;
        this.paymentId = '';
    }

    public onOpen(paymentId: PaymentT['id'] = '') {
        this.isOpen = true;
        this.paymentId = paymentId;
    }
}
