import { MODAL_TITLE_CREATE_COURT_TYPE } from 'constants/modalTitles';

import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCourtTypes from 'shared/data-hook/courtType/fetch/fetchAll/useCourtTypes';
import useCourtTypeCreate from 'shared/hook/courtType/create/useCourtTypeCreate';
import { useStore } from 'store';

import {
    COURT_TYPE_DEFAULT_VALUES,
    COURT_TYPE_FIELD_KEYS, COURT_TYPE_LABEL_DATA,
    CourtTypeFormFields,
    getCourtTypeValidationSchema,
} from './formData';

const CreateCourtTypeSettingModal: FC = observer(() => {
    const { isOpen, onClose, setCourtTypes } = useStore('createCourtTypeModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { createCourtType } = useCourtTypeCreate();
    const {
        data: courtTypesData,
        loading: courtTypesLoading,
    } = useCourtTypes();
    const { control, handleSubmit, reset } = useForm<CourtTypeFormFields>({
        defaultValues: COURT_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getCourtTypeValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleCreateCourtType = (data: CourtTypeFormFields) => {
        createCourtType(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    useEffect(() => {
        if (courtTypesData) {
            setCourtTypes();
        }
    }, [courtTypesData]);

    return (
        <Modal maxWidth="lg" open={ isOpen }>
            <ModalTitle onClose={ handleCloseModal }>{ MODAL_TITLE_CREATE_COURT_TYPE }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 container spacing={ 2 } padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_TYPE_FIELD_KEYS.name }
                            label={ COURT_TYPE_LABEL_DATA.name }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_TYPE_FIELD_KEYS.duration }
                            label={ COURT_TYPE_LABEL_DATA.duration }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Switch
                            size="small"
                            control={ control }
                            name={ COURT_TYPE_FIELD_KEYS.useHalves }
                            label={ COURT_TYPE_LABEL_DATA.useHalves }
                            type="boolean"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ courtTypesLoading }
                    onClick={ handleSubmit(handleCreateCourtType) }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateCourtTypeSettingModal;
