import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientFullNameOptions } from 'shared/data-hook/utils/DTO/Client';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ClientT } from 'types/Client';

const DTO = t.type({
    id: t.string,
    client: Nullable(t.type(ClientFullNameOptions())),
});

export type ClientDTOT = t.TypeOf<typeof DTO>;

export const ClientDTO = tPromise.extendDecoder<ClientDTOT, ClientT>(
    DTO,
    ({
        id, client,
    }) => ({
        id,
        firstName: client?.first_name || null,
        lastName: client?.last_name || null,
        middleName: client?.middle_name || null,
    }),
) as t.Type<ClientT, ClientDTOT>;
