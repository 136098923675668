import { gql } from '@apollo/client';
import { MemberCardTypeT } from 'types/MemberCardType';

export type MemberCardTypeDeleteResponseDataT = {
    deleteMemberCardType: {
        id: MemberCardTypeT['id'];
    };
} | null | undefined;

export type MemberCardTypeDeleteVarT = {
    id: MemberCardTypeT['id'];
};

export const MEMBER_CARD_TYPE_DELETE = gql`
    mutation DeleteMemberCardType($id: String = "") {
        deleteMemberCardType: delete_member_card_type_by_pk(id: $id) {
            id
        }
    }
`;
