import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { CoachesInGroupT } from 'types/CoachesInGroup';

const DTO = t.type({
    id: t.string,
    groupMainCoach: t.type({
        id: t.string,
        employee: t.type({
            full_name: t.string,
        }),
    }),
    group: Nullable(t.type({
        id: t.string,
        name: t.string,
    })),
    implementations: t.array(t.type({
        datetime: t.string,
    })),
    coaches: t.array(t.type({
        coach: t.type({
            id: t.string,
            employee: t.type({
                full_name: t.string,
            }),
        }),
    })),
});

export type CoachesInGroupDTOT = t.TypeOf<typeof DTO>;

export const CoachesInGroupDTO = tPromise.extendDecoder<CoachesInGroupDTOT, CoachesInGroupT>(
    DTO,
    ({
        id,
        groupMainCoach,
        group,
        implementations,
        coaches,
    }) => ({
        id,
        mainCoach: { id: groupMainCoach.id, name: groupMainCoach.employee.full_name },
        group,
        datetime: new Date(implementations[0].datetime),
        coaches: coaches.map((coach) => ({
            id: coach.coach.id,
            name: coach.coach.employee.full_name,
        })),
    }),
) as t.Type<CoachesInGroupT, CoachesInGroupDTOT>;
