import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { PaymentTypeT } from 'types/PaymentType';

const DTO = t.type({
    id: t.string,
    title: t.string,
    position: t.number,
    is_active: t.boolean,
    default_fiat_payment: Nullable(t.number),
    default_bonus_payment: Nullable(t.number),
    fiat_payment_divisible: t.number,
    bonus_payment_divisible: t.number,
    payment_method_id: Nullable(t.string),
    should_confirm_by_sms: t.boolean,
    allow_negative_payments: t.boolean,
});

export type PaymentTypeDTOT = t.TypeOf<typeof DTO>;

export const PaymentTypeDTO = tPromise.extendDecoder<PaymentTypeDTOT, PaymentTypeT>(
    DTO,
    ({
        id,
        title,
        position,
        is_active,
        default_fiat_payment,
        default_bonus_payment,
        fiat_payment_divisible,
        bonus_payment_divisible,
        payment_method_id,
        should_confirm_by_sms,
        allow_negative_payments,
    }) => ({
        id,
        title,
        position,
        isActive: is_active,
        defaultFiatPayment: default_fiat_payment,
        defaultBonusPayment: default_bonus_payment,
        fiatPaymentDivisible: fiat_payment_divisible,
        bonusPaymentDivisible: bonus_payment_divisible,
        paymentMethodId: payment_method_id ?? '',
        shouldConfirmBySms: should_confirm_by_sms,
        allowNegativePayments: allow_negative_payments,
    }),
) as t.Type<PaymentTypeT, PaymentTypeDTOT>;
