import { gql } from '@apollo/client';

export type CourtCategoryCreatePayloadT = {
    name: string;
    position: number;
};

export type CourtCategoryCreateResponseDataT = {
    createCourtCategory: {
        id: string;
    };
} | null | undefined;

export type CourtCategoryCreateVarT = {
    object: CourtCategoryCreatePayloadT;
};

export const COURT_CATEGORY_CREATE = gql`
    mutation CourtCategoryCreate($object: court_category_insert_input = {}) {
        createCourtCategory: insert_court_category_one(
            object: $object
        ) {
            id
        }
    }
`;
