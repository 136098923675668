import { makeAutoObservable } from 'mobx';

export default class UpdateItemModal {
    public updatedItemId = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedItemId: string) {
        this.updatedItemId = updatedItemId;
    }

    public onClose() {
        this.updatedItemId = '';
    }
}
