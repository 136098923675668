import { gql } from '@apollo/client';

export type GroupViewUpdateVarT = {
    id: string;
    set: GroupViewUpdateMutationPayloadT;
};

type GroupViewUpdateMutationPayloadT = {
    name: string;
    category_id: string | null;
    age_type_id: string | null;
    min_size: number;
    avg_size: number;
    max_size: number;
    clients_for_main_coach: number;
    ignore_busy_coaches: boolean;
    group_constant_price: boolean;
    correction_factors: Array<Record<string, number>>;
}

export type GroupViewUpdateResponseDataT = {
    updateGroupView: {
        id: string;
    };
} | null | undefined;

export const GROUP_VIEW_UPDATE = gql`
    mutation UpdateGroupView($set: group_view_set_input = {}, $id: String = "") {
        updateGroupView: update_group_view_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
