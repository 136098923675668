import { EMPTY_CELL } from 'constants/dataGrid';

import React, { FC } from 'react';
import { Box, TextField } from '@mui/material';
import { DateRange, DateRangePicker as BaseDateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';

type PropsT = {
    value: DateRange<Dayjs>;
    onChange: (newValue: DateRange<Dayjs>) => void;
};

const DateRangePicker: FC<PropsT> = (props) => {
    const {
        value, onChange,
    } = props;

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale="ru"
            localeText={ { start: 'Начало периода', end: 'Конец периода' } }
        >
            <BaseDateRangePicker
                onChange={ onChange }
                value={ value }
                renderInput={ (startProps, endProps) => (
                    <>
                        <TextField { ...startProps } size="small" />
                        <Box sx={ { mx: 2 } }>{ EMPTY_CELL }</Box>
                        <TextField { ...endProps } size="small" />
                    </>
                ) }
            />
        </LocalizationProvider>
    );
};

export default DateRangePicker;
