import { makeAutoObservable } from 'mobx';
import { PaymentTypeT } from 'types/PaymentType';

export default class UpdatePaymentTypeModal {
    public isOpen: boolean = false;

    public updatedItemId: string = '';

    public paymentType: PaymentTypeT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onClose() {
        this.updatedItemId = '';
        this.isOpen = false;
    }

    public onOpen(updatedItemId: string) {
        this.updatedItemId = updatedItemId;
        this.isOpen = true;
    }

    setPaymentType(paymentType: PaymentTypeT) {
        this.paymentType = paymentType;
    }
}
