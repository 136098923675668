import { gql } from '@apollo/client';

export type UserVar = {
    id: string;
};

export const USER = gql`
    subscription User($id: String!) {
        user: user_by_pk(id: $id) {
            id
            username
            role
        }
    }
`;
