import { DATE_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CARD_TYPE,
    PROPERTY_CLIENT_FIRST_NAME, PROPERTY_CLIENT_LAST_NAME, PROPERTY_CLIENT_MIDDLE_NAME,
    PROPERTY_END, PROPERTY_START, PROPERTY_STATUS,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import SwitchIsActiveButton from 'shared/component/Button/SwitchIsActiveButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useSwitchIsActiveMemberCard from 'shared/hook/memberCard/switchIsActive/useSwitchIsActiveMemberCard';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

import MemberCardEditButton from '../components/MemberCardEditButton';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { switchIsActiveMemberCard, loading } = useSwitchIsActiveMemberCard();

    return (
        <>
            <MemberCardEditButton id={ id } />
            <SwitchIsActiveButton id={ id } title={ title } actionDelete={ switchIsActiveMemberCard } loading={ loading } />
        </>
    );
};

export const MEMBER_CARD_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['client', 'last_name'],
        hasuraSortFields: ['client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['client', 'first_name'],
        hasuraSortFields: ['client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['client', 'middle_name'],
        hasuraSortFields: ['client', 'middle_name'],
    },
    {
        headerName: PROPERTY_CARD_TYPE,
        field: 'title',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['member_card_type', 'id'],
        hasuraSortFields: ['member_card_type', 'title'],
    },
    {
        headerName: PROPERTY_START,
        field: 'datetimeStart',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['datetime_start'],
        hasuraSortFields: ['datetime_start'],
        renderCell: (params) => renderDateToGrid(params.row.datetimeStart, DATE_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_END,
        field: 'datetimeEnd',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['datetime_end'],
        hasuraSortFields: ['datetime_end'],
        renderCell: (params) => renderDateToGrid(params.row.datetimeEnd, DATE_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_STATUS,
        field: 'isActive',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
];
