import LogoutResult from 'enums/LogoutResult';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

const DTO_TO_LOGOUT_RESULT_MAP: Record<LogoutResultDTOT, LogoutResult> = {
    error: LogoutResult.Fail,
    ok: LogoutResult.Success,
};

const LOGOUT_RESULT_TO_DTO_MAP: Record<LogoutResult, LogoutResultDTOT> = {
    [LogoutResult.Success]: 'ok',
    [LogoutResult.Fail]: 'error',
};

const DTO = t.union([t.literal('ok'), t.literal('error')]);

export type LogoutResultDTOT = t.TypeOf<typeof DTO>;

export const LogoutResultDTO = tPromise.extendType<LogoutResultDTOT, LogoutResult>(
    DTO,
    (result) => DTO_TO_LOGOUT_RESULT_MAP[result],
    (result) => LOGOUT_RESULT_TO_DTO_MAP[result],
) as t.Type<LogoutResult, LogoutResultDTOT>;
