import * as t from 'io-ts';

import Nullable from './Nullable';

export const ClientFullNameOptions = () => ({
    first_name: Nullable(t.string),
    middle_name: Nullable(t.string),
    last_name: Nullable(t.string),
});

export default function Client() {
    return Nullable(t.type({
        client: Nullable(t.type(ClientFullNameOptions())),
    }));
}

export type ClientDataT = {
    client: {
        first_name: string | null;
        middle_name: string | null;
        last_name: string | null;
    } | null;
} | null;
