import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { HEADER_DATA_COACH_HOURS } from './HeaderData';

export const prepareCoachHoursHeaderData = (priceTypes: FilterOptionT[]): GridColumns =>
    useMemo(() => mapFields(HEADER_DATA_COACH_HOURS, {
        priceType: priceTypes,
    }), [priceTypes]);
