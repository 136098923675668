import {
    PROPERTY_DURATION,
    PROPERTY_PERIODS,
    PROPERTY_TITLE,
    PROPERTY_USE_HALVES,
} from 'constants/propertyNames';

import { object, string } from 'yup';

export type CourtTypeFormFields = {
    name: string;
    duration: string;
    useHalves: boolean;
    periods: Array<{
        start: string;
        end: string;
    }>;
}

export enum COURT_TYPE_FIELD_KEYS {
    name = 'name',
    duration = 'duration',
    useHalves = 'useHalves',
    periods = 'periods',
}

export const COURT_TYPE_DEFAULT_VALUES = {
    name: '',
    duration: '',
    useHalves: false,
    periods: [],
};

type CourtTypeLabelData = Record<keyof CourtTypeFormFields, string>

export const COURT_TYPE_LABEL_DATA: CourtTypeLabelData = {
    name: PROPERTY_TITLE,
    duration: PROPERTY_DURATION,
    useHalves: PROPERTY_USE_HALVES,
    periods: PROPERTY_PERIODS,
};

export const COURT_TYPE_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
}).required();

export const getCourtTypeValidationSchema = () => COURT_TYPE_VALIDATION_SCHEMA;
