import {
    PROPERTY_BONUS_CLASSES,
    PROPERTY_CAMERA_ID,
    PROPERTY_ORDER,
    PROPERTY_ORGANIZATION, PROPERTY_ORGANIZATION_LOCATION, PROPERTY_SCHEDULE,
    PROPERTY_TITLE, PROPERTY_TYPE,
} from 'constants/propertyNames';

import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';
import {
    array, number, object, string,
} from 'yup';

export type CourtFormFields = {
    name: string;
    order: number;
    schedule: Array<{
        day: number;
        end: string;
        start: string;
    }>;
    organizationLocationId: string;
    organizationId: string;
    bonusClasses: boolean;
    courtTypeId: string;
    cameraId: string;
}

export const COURT_DEFAULT_VALUES = {
    name: '',
    order: 0,
    schedule: [],
    bonusClasses: false,
    organizationId: '',
    organizationLocationId: '',
    courtTypeId: '',
    cameraId: '',
};

export enum COURT_FIELD_KEYS {
    name = 'name',
    order = 'order',
    schedule = 'schedule',
    bonusClasses = 'bonusClasses',
    organizationId = 'organizationId',
    organizationLocationId = 'organizationLocationId',
    courtTypeId = 'courtTypeId',
    cameraId = 'cameraId',
}

type CourtLabelDataT = Record<keyof CourtFormFields, string>

export const COURT_LABEL_DATA: CourtLabelDataT = {
    name: PROPERTY_TITLE,
    order: PROPERTY_ORDER,
    schedule: PROPERTY_SCHEDULE,
    cameraId: PROPERTY_CAMERA_ID,
    organizationId: PROPERTY_ORGANIZATION,
    bonusClasses: PROPERTY_BONUS_CLASSES,
    organizationLocationId: PROPERTY_ORGANIZATION_LOCATION,
    courtTypeId: PROPERTY_TYPE,
};

const SCHEDULE_SCHEMA = object().shape({
    day: number().required(),
    start: string().required(COMMON_FORM_ERRORS[FormErrorMessages.Required]).test({
        name: 'isLarger',
        message: 'Время начала >= время окончания',
        test: (value, testContext) => {
            const { end } = testContext.parent;

            if (end && value) {
                return end >= value;
            }

            return true;
        },
    }),
    end: string().required(COMMON_FORM_ERRORS[FormErrorMessages.Required]).test({
        name: 'isLarger',
        message: 'Время окончания <= время начала',
        test: (value, testContext) => {
            const { start } = testContext.parent;

            if (start && value) {
                return start <= value;
            }

            return true;
        },
    }),
});

export const COURT_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
    organizationLocationId: string().required(),
    courtTypeId: string().required(),
    organizationId: string().required(),
    schedule: array().of(SCHEDULE_SCHEMA),
}).required();
