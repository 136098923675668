import { PAGE_TITLE_IMPLEMENTATION_DOCUMENT } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useImplementationDocuments from 'shared/data-hook/implementationDocument/fetch/fetchAll/useImplementationDocuments';
import { EMPLOYEE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/employee/EmployeeQuery';
import { GROUP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/group/GroupQuery';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import ImplementationDocumentList from './components/ImplementationDocumentList';
import { IMPLEMENTATION_DOCUMENT_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: implementationDocumentsData, loading: implementationDocumentsLoading, count: rowCount, fetchImplementationDocuments,
    } = useImplementationDocuments();
    const { setImplementationDocuments } = useStore('implementationDocument');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');
    const { loading: groupsLoading } = useFetchFilterOptions(GROUP_FILTER_OPTIONS_QUERY, 'group');
    const { loading: employeesLoading } = useFetchFilterOptions(EMPLOYEE_FILTER_OPTIONS_QUERY, 'employee');
    const loading = implementationDocumentsLoading || serviceCategoriesLoading || groupsLoading || employeesLoading;

    usePageTitle(PAGE_TITLE_IMPLEMENTATION_DOCUMENT);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchImplementationDocuments, refetchTrigger, IMPLEMENTATION_DOCUMENT_HEADER_DATA);

    useEffect(() => {
        setImplementationDocuments(implementationDocumentsData, rowCount);
    }, [implementationDocumentsData, implementationDocumentsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <ImplementationDocumentList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
