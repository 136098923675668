import { ERROR_COURT_TYPE_CREATE, SUCCESS_COURT_TYPE_CREATE } from 'constants/notificationMessage';

import { CourtTypeFormFields } from 'page/Court/components/formData';
import useCreateCourtType from 'shared/data-hook/courtType/create/useCreateCourtType';
import { useNotification } from 'shared/hook/useNotification';

const useCourtTypeCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, courtTypeCreate } = useCreateCourtType();

    const createCourtType = async (input: CourtTypeFormFields) => {
        const response = await courtTypeCreate(input);

        if (error || !response || !response.createCourtType) {
            showError(ERROR_COURT_TYPE_CREATE);
        } else {
            showSuccess(SUCCESS_COURT_TYPE_CREATE);
        }
    };

    return {
        createCourtType,
        loading,
    };
};

export default useCourtTypeCreate;
