export type OrganizationT = {
    id: string;
    name: string;
    serviceApp: {
        id: string;
        label: string;
    } | null;
    workTime: WorkTime[];
};

export type OrganizationsT = {
    organizations: OrganizationT[];
    organizationsCount: number;
}

export type WorkTime = {
    day: number;
    start: string;
    end: string;
};

export const EMPTY_ORGANIZATIONS_RESULT: OrganizationsT = {
    organizations: [],
    organizationsCount: 0,
};

export const EMPTY_ORGANIZATION_RESULT: OrganizationT = {
    id: '',
    name: '',
    serviceApp: null,
    workTime: [],
};
