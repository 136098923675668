import { makeAutoObservable } from 'mobx';
import { EmployeeT } from 'types/Employee';
import { GroupT } from 'types/Group';
import { ImplementationDocumentT } from 'types/ImplementationDocument';
import { ServiceCategoryT } from 'types/ServiceCategory';

export default class ImplementationDocumentStore {
    public implementationDocumentList: ImplementationDocumentT[] = [];

    public count: number = 0;

    public serviceCategories: ServiceCategoryT[] = [];

    public groups: GroupT[] = [];

    public employees: EmployeeT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get implementationDocuments(): ImplementationDocumentT[] {
        return this.implementationDocumentList;
    }

    public setImplementationDocuments(
        implementationDocument: ImplementationDocumentT[],
        count?: number,
    ) {
        this.implementationDocumentList = implementationDocument;
        this.count = count ?? 0;
    }

    public setServiceCategories(serviceCategories: ServiceCategoryT[]) {
        this.serviceCategories = serviceCategories;
    }

    public setGroups(groups: GroupT[]) {
        this.groups = groups;
    }

    public setEmployees(employees: EmployeeT[]) {
        this.employees = employees;
    }
}
