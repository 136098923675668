import { PAGE_TITLE_MEMBER_CARD_TYPE_UPDATE } from 'constants/pageTitles';
import { MEMBER_CARD_TYPE } from 'constants/routes';

import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Skeleton, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import NoResult from 'shared/component/NoResult';
import useFetchMemberCardType from 'shared/hook/memberCardType/fetch/useFetchMemberCardType';
import useUpdateMemberCardType from 'shared/hook/memberCardType/update/useUpdateMemberCardType';
import { useStore } from 'store';

import {
    MEMBER_CARD_TYPE_DEFAULT_VALUES, MEMBER_CARD_TYPE_VALIDATION_SCHEMA,
    MemberCardTypeFormFields,
} from './components/formData';
import MemberCardTypeForm from './components/MemberCardTypeForm';

const Update = observer(() => {
    const params = useParams();
    const { fetch: fetchMemberCardType, loading: loadingFetch } = useFetchMemberCardType();
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_MEMBER_CARD_TYPE_UPDATE);
    }, []);

    if (!params.id) {
        return <NoResult />;
    }

    fetchMemberCardType(params.id);

    const { updateMemberCardType, loading: loadingSave } = useUpdateMemberCardType();
    const { memberCardType } = useStore('memberCardType');

    const {
        control, handleSubmit, setValue,
    } = useForm<MemberCardTypeFormFields>({
        defaultValues: MEMBER_CARD_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(MEMBER_CARD_TYPE_VALIDATION_SCHEMA),
    });

    const memberCardTypeValues: MemberCardTypeFormFields = useMemo(() => {
        const values = MEMBER_CARD_TYPE_DEFAULT_VALUES;

        if (memberCardType) {
            Object.keys(MEMBER_CARD_TYPE_DEFAULT_VALUES).forEach((key) => {
                values[key as keyof MemberCardTypeFormFields] = memberCardType[
                    key as keyof MemberCardTypeFormFields
                ] as never;
            });
        }

        return values;
    }, [memberCardType]);
    const {
        title, isActive, expirationPeriod, cashbackFiatOperationsLimit, cashbackFiatSumLimit,
    } = memberCardTypeValues;

    useEffect(() => {
        Object.keys(MEMBER_CARD_TYPE_DEFAULT_VALUES).forEach((key) => {
            setValue(
                key as keyof MemberCardTypeFormFields,
                memberCardTypeValues[key as keyof MemberCardTypeFormFields],
            );
        });
    }, [title, isActive, expirationPeriod, cashbackFiatOperationsLimit, cashbackFiatSumLimit]);

    const handleUpdateSeason = (data: MemberCardTypeFormFields) => {
        updateMemberCardType(data, memberCardType?.id as string).then();
    };

    if (!memberCardType && !loadingFetch) {
        return <NoResult />;
    }

    return (
        <Box p={ 3 }>
            <Typography component="div" variant="h5">
                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                    {
                        loadingFetch ? (<Skeleton width={ 300 } height={ 32 } />) : (<> { title } </>)
                    }
                </Box>
            </Typography>
            <form onSubmit={ handleSubmit(handleUpdateSeason) }>
                <MemberCardTypeForm control={ control } loading={ loadingFetch } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={ loadingSave }
                    >
                        Сохранить
                    </LoadingButton>
                    <Link to={ MEMBER_CARD_TYPE }>
                        <Button
                            variant="outlined"
                        >
                            Отменить
                        </Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Update;
