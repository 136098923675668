import { makeAutoObservable } from 'mobx';
import { CourtT } from 'types/Court';
import { OrganizationT } from 'types/Organization';

export default class CourtStore {
    public courtList: CourtT[] = [];

    public organizations: OrganizationT[] = [];

    public count: number = 0;

    public court: CourtT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get courts(): CourtT[] {
        return this.courtList;
    }

    public setCourts(courts: CourtT[], count?: number) {
        this.courtList = courts;
        this.count = count ?? 0;
    }
}
