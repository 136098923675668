import React, { FC, MouseEventHandler } from 'react';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal/index';
import { useStore } from 'store';

type PropsT = {
    id: string;
    title: string;
    onDeleteButtonClick: () => void;
};

const DeleteModal: FC<PropsT> = observer((props) => {
    const { id, title, onDeleteButtonClick } = props;
    const { deletedItemId, onClose } = useStore('deleteItemModal');

    const handleDeleteButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        onDeleteButtonClick();
        onClose();
    };

    return (
        <Modal open={ deletedItemId === id } onClose={ onClose }>
            <DialogTitle>Вы действительно хотите удалить { title || 'выбранный элемент' }?</DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={ onClose }>Отмена</Button>
                <Button onClick={ handleDeleteButtonClick } variant="contained" autoFocus color="error">Удалить</Button>
            </DialogActions>
        </Modal>
    );
});

export default DeleteModal;
