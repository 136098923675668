import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PaymentDocumentT } from 'types/PaymentDocument';

export type PaymentDocumentsResponseT = {
    paymentDocuments: PaymentDocumentT[];
    paymentDocumentsCount: AggregateT;
}

export const PAYMENT_DOCUMENTS_QUERY = gql`
    query PaymentDocuments(
        $limit: Int,
        $offset: Int,
        $orderBy: payment_document_order_by!,
        $where: payment_document_bool_exp!,
    ) {
        paymentDocuments: payment_document(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            number
            datetime
            payments {
                id
            }
            organization {
                name
            }
        }
        paymentDocumentsCount: payment_document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
