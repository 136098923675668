import { PAGE_TITLE_COACH_HOURS_REPORT } from 'constants/pageTitles';
import { PROPERTY_COACH } from 'constants/propertyNames';

import { FC, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import DateRangePicker from 'shared/component/DateRangePicker';
import Select from 'shared/component/Select';
import useCoachHours from 'shared/data-hook/coachHours/fetch/fetchAll/useCoachHours';
import useImplementations from 'shared/data-hook/implementation/fetch/fetchAll/useImplementations';
import { PRICE_TYPE_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/priceType/PriceTypeQuery';
import useFetchCoachFilterOptions from 'shared/hook/coach/useFetchCoachFilterOptions';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useFetchDataForCoachHours from 'shared/hook/useFetchDataForCoachHours';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CoachHoursList from './components/CoachHoursList';
import { HEADER_DATA_COACH_HOURS } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { loading: coachLoading } = useFetchCoachFilterOptions();
    const { getFilterOption } = useStore('filterOptions');
    const coachFilterOptions = getFilterOption('coach');
    const {
        data: implementationsData, loading: implementationsLoading, count: rowCount, fetchImplementations,
    } = useImplementations();
    const { data: coachHoursData, loading: coachHoursLoading, fetchCoachHours } = useCoachHours();
    const { setImplementations } = useStore('implementation');
    const { setCoachHours } = useStore('coachHours');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { loading: priceTypeLoading } = useFetchFilterOptions(PRICE_TYPE_FILTER_OPTIONS_QUERY, 'priceType');
    const [selectedCoachId, setSelectedCoachId] = useState<string>('');
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs().subtract(1, 'month'), dayjs()]);
    const loading = implementationsLoading || coachHoursLoading || priceTypeLoading;

    usePageTitle(PAGE_TITLE_COACH_HOURS_REPORT);

    useFetchDataForCoachHours(sortModel, filterModel, page, pageSize, fetchImplementations, fetchCoachHours, refetchTrigger, HEADER_DATA_COACH_HOURS, dateRange, selectedCoachId);

    useEffect(() => {
        if (!coachFilterOptions.length) {
            return;
        }
        setSelectedCoachId(coachFilterOptions[0].value);
    }, [coachFilterOptions]);

    useEffect(() => {
        setImplementations(implementationsData, rowCount);
    }, [implementationsData, implementationsLoading]);

    useEffect(() => {
        setCoachHours(coachHoursData);
    }, [coachHoursData, coachHoursLoading]);

    const handleCoachChange = (event: SelectChangeEvent) => {
        setSelectedCoachId(event.target.value);
    };

    const handleDateRangeChange = (newValue: DateRange<Dayjs>) => {
        setDateRange([newValue[0], newValue[1]]);
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <DateRangePicker
                    value={ dateRange }
                    onChange={ handleDateRangeChange }
                />
                <Select
                    label={ PROPERTY_COACH }
                    labelId="coach-select"
                    options={ coachFilterOptions }
                    value={ selectedCoachId ?? '' }
                    onChange={ handleCoachChange }
                    loading={ coachLoading }
                />
            </ControlPanel>
            <CoachHoursList
                loading={ loading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                sortModel={ sortModel }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
