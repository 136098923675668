export type SelectOptionT = {
    value: string;
    label: string;
}

export const parseSelectOptions = (data: any[]): SelectOptionT[] => {
    return data.map((item) => (
        {
            value: item.id,
            label: item.name || item.title,
        }
    ));
};
