import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { MemberCardT } from 'types/MemberCard';

export type MemberCardsResponseT = {
    memberCards: MemberCardT[];
    memberCardsCount: AggregateT;
};

export type MemberCardResponseT = {
    memberCard: MemberCardT;
};

export const MEMBER_CARD_QUERY = gql`
    query MemberCard($id: String!) {
        memberCard: member_card_by_pk(id: $id) {
            id
            is_active
            member_card_type {
                title
            }
            datetime_start
            datetime_end
            client {
                first_name
                last_name
                middle_name
            }
        }
    }
`;

export const MEMBER_CARDS_QUERY = gql`
    query MemberCards(
        $limit: Int,
        $offset: Int,
        $orderBy: member_card_order_by! = {},
        $where: member_card_bool_exp! = {}
    ) {
        memberCards: member_card(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            is_active
            member_card_type {
                title
            }
            datetime_start
            datetime_end
            client {
                first_name
                last_name
                middle_name
            }
        }
        memberCardsCount: member_card_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
