import { useEffect } from 'react';
import useFetchCoachFilterOptionsData from 'shared/data-hook/filterOptions/useFetchCoachFilterOptionsData';
import { useStore } from 'store';

const useFetchCoachFilterOptions = () => {
    const { loading, data: coachData } = useFetchCoachFilterOptionsData();
    const { setFilterOption } = useStore('filterOptions');

    useEffect(() => {
        if (!loading) {
            setFilterOption('coach', coachData);
        }
    }, [loading]);

    return { loading };
};

export default useFetchCoachFilterOptions;
