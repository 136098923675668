import { makeAutoObservable } from 'mobx';

export class UpdatePriceSalesRoundModal {
    public isOpen = false;

    public priceSalesRoundId: string = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(priceSalesRoundId: string) {
        this.priceSalesRoundId = priceSalesRoundId;
        this.isOpen = true;
    }

    public onClose() {
        this.priceSalesRoundId = '';
        this.isOpen = false;
    }
}
