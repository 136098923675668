import {
    ERROR_GROUP_VIEW_DELETE,
    SUCCESS_GROUP_VIEW_DELETE,
} from 'constants/notificationMessage';

import useGroupViewDelete from 'shared/data-hook/groupView/delete/single/useGroupViewDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { GroupViewT } from 'types/GroupView';

const useDeleteGroupView = () => {
    const { error, deleteGroupView } = useGroupViewDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const groupViewDelete = async (groupViewId: GroupViewT['id']) => {
        const response = await deleteGroupView(groupViewId);

        if (error || !response) {
            showError(ERROR_GROUP_VIEW_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_GROUP_VIEW_DELETE);
        }
    };

    return {
        groupViewDelete,
    };
};

export default useDeleteGroupView;
