import { useMutation } from '@apollo/client';
import { COURT_DELETE, CourtDeleteResponseDataT, CourtDeleteVarT } from 'shared/graphql/mutation/court/CourtDelete';
import { CourtT } from 'types/Court';

import { CourtDeleteDataT } from './CourtDeleteData';

const useCourtDelete = (): CourtDeleteDataT => {
    const [deleteCourt, { loading, error }] = useMutation<
        CourtDeleteResponseDataT,
        CourtDeleteVarT
    >(COURT_DELETE);

    const deleteAction = async (courtId: CourtT['id']) => {
        try {
            const { data } = await deleteCourt({
                variables: {
                    id: courtId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteCourt: deleteAction,
        loading,
        error,
    };
};

export default useCourtDelete;
