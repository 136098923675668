import { makeAutoObservable } from 'mobx';
import { PriceSeasonT } from 'types/PriceSeason';

export default class PriceSeasonStore {
    public priceSeasonList: PriceSeasonT[] = [];

    public priceSeason: PriceSeasonT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get priceSeasons(): PriceSeasonT[] {
        return this.priceSeasonList;
    }

    public setPriceSeasons(priceSeasons: PriceSeasonT[], count?: number) {
        this.priceSeasonList = priceSeasons;
        this.count = count ?? 0;
    }

    public setPriceSeason(priceSeason: PriceSeasonT) {
        this.priceSeason = priceSeason;
    }
}
