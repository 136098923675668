import { fold as baseFold } from 'fp-ts/Either';
import { Type } from 'io-ts';

export default function decodeDTO<Out, A, O>(
    DTO: Type<A, O>,
    left: Out,
    onRight: (i: A) => Out,
    value: unknown,
) {
    const decoded = DTO.decode(value);

    return baseFold<unknown, A, Out>(() => left, onRight)(decoded);
}
