import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { OrganizationLocationT } from 'types/OrganizationLocation';

const DTO = t.type({
    id: t.string,
    name: t.string,
    personal_manager_locations: t.array(t.type({
        personal_manager: t.type({
            employee: t.type({
                full_name: t.string,
            }),
        }),
    })),
});

export type OrganizationLocationDTOT = t.TypeOf<typeof DTO>;

export const OrganizationLocationDTO = tPromise.extendDecoder<OrganizationLocationDTOT, OrganizationLocationT>(
    DTO,
    ({
        id, name, personal_manager_locations,
    }) => ({
        id,
        name,
        personalManagers: personal_manager_locations.map((manager) => manager.personal_manager.employee.full_name),
    }),
) as t.Type<OrganizationLocationT, OrganizationLocationDTOT>;
