import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { ClientViewT } from 'types/ClientView';

const DTO = t.type({
    id: t.string,
    first_name: Nullable(t.string),
    last_name: Nullable(t.string),
    middle_name: Nullable(t.string),
    birthday_year: Nullable(t.string),
    birthday_month: Nullable(t.string),
    birthday_day: Nullable(t.string),
    phone: Nullable(t.string),
    extension_phone: Nullable(t.string),
    manager_id: Nullable(t.string),
    personal_manager_full_name: Nullable(t.string),
});

export type ClientViewDTOT = t.TypeOf<typeof DTO>;

export const ClientViewDTO = tPromise.extendDecoder<ClientViewDTOT, ClientViewT>(
    DTO,
    ({
        id,
        first_name,
        last_name,
        middle_name,
        birthday_day,
        birthday_month,
        birthday_year,
        phone,
        extension_phone,
        manager_id,
        personal_manager_full_name,
    }) => ({
        id,
        firstName: first_name,
        lastName: last_name,
        middleName: middle_name,
        birthdayYear: birthday_year,
        birthdayMonth: birthday_month,
        birthdayDay: birthday_day,
        phone,
        extensionPhone: extension_phone,
        managerId: manager_id,
        personalManagerFullName: personal_manager_full_name,
    }),
) as t.Type<ClientViewT, ClientViewDTOT>;
