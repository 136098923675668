import { useEffect, useState } from 'react';
import {
    Control, Controller, FieldValues, UseFormSetValue,
} from 'react-hook-form';
import { SxProps, TextField, Theme } from '@mui/material';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { DatePicker as BaseDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';

import { getErrorMessage } from '../../utils/getErrorMessage';

type PropsT = {
    setValue: UseFormSetValue<any>;
    value: Dayjs;
    sx?: SxProps<Theme>;
    fullWidth?: boolean;
    label?: string;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
    loading?: boolean;
    control: Control<any>;
};

const DatePicker = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, value, name, loading, setValue, sx, fullWidth = false, label, size, withoutError, customError,
    } = props;
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setValue(name, value);
    }, [value]);

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState }) => (
                <LocalizationProvider
                    dateAdapter={ AdapterDayjs }
                    adapterLocale="ru"
                >
                    <BaseDatePicker
                        open={ open }
                        onOpen={ () => setOpen(true) }
                        onClose={ () => setOpen(false) }
                        label={ label }
                        onChange={ (newValue) => {
                            setValue(name, newValue);
                        } }
                        value={ field.value }
                        renderInput={ (params) => (
                            <TextField
                                fullWidth={ fullWidth }
                                onClick={ () => setOpen(true) }
                                sx={ sx }
                                size={ size || 'small' }
                                { ...field }
                                { ...params }
                                inputProps={ {
                                    ...params.inputProps,
                                    readOnly: true,
                                } }
                                error={ !!fieldState.error }
                                helperText={
                                    withoutError ? null : (
                                        (customError && fieldState.error?.message)
                                        || getErrorMessage(fieldState)
                                        || fieldState.error?.message
                                    )
                                }
                            />
                        ) }
                    />
                </LocalizationProvider>
            ) }
        />
    );
};

export default DatePicker;
