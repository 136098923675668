import { useEffect } from 'react';
import useOrganizationLocation from 'shared/data-hook/organizationLocation/fetch/fetchOne/useOrganizationLocation';
import { useStore } from 'store';

const useFetchOrganizationLocation = () => {
    const { fetchOrganizationLocation, loading } = useOrganizationLocation();
    const { updateOrganizationLocationId, isOpen, setOrganizationLocation } = useStore('updateOrganizationLocationModal');

    useEffect(() => {
        if (isOpen && updateOrganizationLocationId) {
            fetchOrganizationLocation(updateOrganizationLocationId).then((fetchedOrganizationLocation) => {
                setOrganizationLocation(fetchedOrganizationLocation);
            });
        }
    }, [isOpen, updateOrganizationLocationId]);

    return { loading };
};

export default useFetchOrganizationLocation;
