import { DATA_GRID_PAGE_SIZE_SMALL } from 'constants/dataGrid';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY,
    IMPLEMENTATION_DOCUMENT_TYPE_OPTIONS,
} from 'constants/implementationDocumentTypes';
import {
    PROPERTY_ACTIONS,
    PROPERTY_CATEGORY,
    PROPERTY_CLIENT_FIRST_NAME,
    PROPERTY_CLIENT_LAST_NAME, PROPERTY_CLIENT_MIDDLE_NAME,
    PROPERTY_COACH,
    PROPERTY_COMMENT,
    PROPERTY_DATETIME,
    PROPERTY_DOCUMENT_NUMBER,
    PROPERTY_GROUP,
    PROPERTY_TYPE,
} from 'constants/propertyNames';

import React from 'react';
import { Link } from 'react-router-dom';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { IconButton, Tooltip } from '@mui/material';
import { GridLinkOperator, GridRenderCellParams } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell = (props: GridRenderCellParams) => {
    const { row } = props;
    const iconLabel = 'Посмотреть список резервирований';
    const date = encodeURIComponent(dayjs(row.datetime).format('DD.MM.YYYY HH:mm:ss'));

    return (
        <Link
            to={ `/implementation?filter=document%3Bequals%3BРезервирование%20${row.number}%20от%20${date}&linkOperator=${GridLinkOperator.And}&page=1&pageSize=${DATA_GRID_PAGE_SIZE_SMALL}` }
            target="_blank"
        >
            <Tooltip title={ iconLabel } placement="left">
                <IconButton>
                    <ReceiptLongIcon color="info" />
                </IconButton>
            </Tooltip>
        </Link>
    );
};

export const IMPLEMENTATION_DOCUMENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_DOCUMENT_NUMBER,
        field: 'number',
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_CLIENT_LAST_NAME,
        field: 'clientLastName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.lastName),
        hasuraFilterFields: ['client_datum', 'client', 'last_name'],
        hasuraSortFields: ['client_datum', 'client', 'last_name'],
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME,
        field: 'clientFirstName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.firstName),
        hasuraFilterFields: ['client_datum', 'client', 'first_name'],
        hasuraSortFields: ['client_datum', 'client', 'first_name'],
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME,
        field: 'clientMiddleName',
        flex: 1,
        minWidth: 120,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.client?.middleName),
        hasuraFilterFields: ['client_datum', 'client', 'middle_name'],
        hasuraSortFields: ['client_datum', 'client', 'middle_name'],
    },
    {
        headerName: PROPERTY_DATETIME,
        field: 'datetime',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        minWidth: 250,
        renderCell: (params) => renderDateToGrid(params.row?.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
        type: 'date',
    },
    {
        headerName: PROPERTY_TYPE,
        field: 'type',
        flex: 0.5,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        valueOptions: IMPLEMENTATION_DOCUMENT_TYPE_OPTIONS,
        renderCell: (params) => <span>{ IMPLEMENTATION_DOCUMENT_TYPE_DICTIONARY[params.row?.type] }</span>,
    },
    {
        headerName: PROPERTY_CATEGORY,
        field: 'serviceCategory',
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['service_category', 'id'],
        hasuraSortFields: ['service_category', 'name'],
    },
    {
        headerName: PROPERTY_GROUP,
        field: 'group',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['group', 'id'],
        hasuraSortFields: ['group', 'name'],
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.group),
    },
    {
        headerName: PROPERTY_COACH,
        field: 'coach',
        flex: 1,
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['groupMainCoach', 'employee_id'],
        hasuraSortFields: ['groupMainCoach', 'employee', 'full_name'],
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.groupMainCoach),
    },
    {
        headerName: PROPERTY_COMMENT,
        field: 'comment',
        flex: 1,
        minWidth: 250,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params: GridRenderCellParams<String | null>) => renderStringCell(params.row?.comment),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        renderCell: RenderActionCell,
        flex: 0.5,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
    },
];
