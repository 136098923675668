import { gql } from '@apollo/client';

export type CreateMemberCardPayloadT = {
    client_data_id: string;
    member_card_type_id: string;
    organization_id: string;
    payment_type_id: string;
    payment_method_id: string;
}

export type CreateMemberCardResponseT = {
    status: string;
    reason: null | string;
}

export type CreateMemberCardVarT = {
    appId: string;
    payload: CreateMemberCardPayloadT;
};

export type CreateMemberCardT = {
    adminMemberCardCreate: CreateMemberCardResponseT;
}

export const EMPTY_CREATE_MEMBER_CARD_RESPONSE: CreateMemberCardResponseT = {
    status: 'error',
    reason: '',
};

export const CREATE_MEMBER_CARD = gql`
    mutation CreateMemberCard($appId: String!, $payload: json!) {
        adminMemberCardCreate(app_id: $appId, payload: $payload) {
            status
            reason
        }
    }
`;
