import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PaymentTypeT } from 'types/PaymentType';

export type PaymentTypesResponseT = {
    paymentTypes: PaymentTypeT[];
    paymentTypesCount: AggregateT;
};

export type PaymentTypeResponseT = {
    paymentType: PaymentTypeT;
}

export const PAYMENT_TYPE_FILTER_OPTIONS_QUERY = gql`
    query PaymentTypeFilterOptions {
        filterOptions: payment_type(
            order_by: { title: asc }
        ) {
            value: id
            label: title
        }
    }
`;

export const PAYMENT_TYPES_QUERY = gql`
    query PaymentTypes(
        $limit: Int,
        $offset: Int,
        $orderBy: payment_type_order_by!,
        $where: payment_type_bool_exp!,
    ) {
        paymentTypes: payment_type(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            title
            position
            is_active
            default_fiat_payment
            default_bonus_payment
            fiat_payment_divisible
            bonus_payment_divisible
            payment_method_id
            should_confirm_by_sms
            allow_negative_payments
        }
        paymentTypesCount: payment_type_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const PAYMENT_TYPE_QUERY = gql`
    query PaymentType($id: String!) {
        paymentType: payment_type_by_pk(id: $id) {
            id
            title
            position
            is_active
            default_fiat_payment
            default_bonus_payment
            fiat_payment_divisible
            bonus_payment_divisible
            payment_method_id
            should_confirm_by_sms
            allow_negative_payments
        }
    }
`;
