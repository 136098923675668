import { makeAutoObservable } from 'mobx';
import { CoachCategoryT } from 'types/CoachCategory';
import { GroupViewT } from 'types/GroupView';
import { PriceTimePeriodT } from 'types/Price';
import { PriceSalesRoundT } from 'types/PriceSalesRound';

export default class UpdatePriceRateModal {
    public isOpen: boolean = false;

    public priceSalesRounds: PriceSalesRoundT[] = [];

    public mutableValues: any = {};

    public groupViews: GroupViewT[] = [];

    public coachCategories: CoachCategoryT[] = [];

    public timePeriods: PriceTimePeriodT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPriceSalesRounds(priceSalesRounds: PriceSalesRoundT[]) {
        this.priceSalesRounds = priceSalesRounds;
    }

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }

    public setMutableValues(mutableValues: any) {
        this.mutableValues = mutableValues;
    }

    public setGroupViews(groupViews: GroupViewT[]) {
        this.groupViews = groupViews;
    }

    public setCoachCategories(coachCategories: CoachCategoryT[]) {
        this.coachCategories = coachCategories;
    }

    public setTimePeriods(timePeriods: PriceTimePeriodT[]) {
        this.timePeriods = timePeriods;
    }
}
