import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { UserSubscriptionContext } from 'contexts/UserSubscriptionContext';
import LoginStatus from 'enums/LoginStatus';
import { LoginForm } from 'page/Login/domain/Types';
import useFetchServiceApp from 'shared/data-hook/serviceApp/fetch/fetchAll/useFetchServiceApp';
import { useCurrentUser } from 'shared/data-hook/user';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';

export const useLogin = () => {
    const { setUserId } = useContext(UserSubscriptionContext);
    const { user, login, loading } = useCurrentUser();
    const navigate = useNavigate();
    const params = useParams();
    const { showError } = useNotification();
    const { fetch: fetchServiceApp } = useFetchServiceApp();
    const { setServiceApp } = useStore('serviceApp');
    const form = useForm<LoginForm>({
        defaultValues: {
            username: '',
            password: '',
        },
        mode: 'onBlur',
    });

    useEffect(() => {
        if (params['*'] !== '') {
            navigate('/login');
        }
    }, []);

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user]);

    const goRestorePassword = () => {
        form.setValue('password', '');
        navigate('./restore');
    };

    const onLogin = async ({ username, password }: LoginForm) => {
        const { userId, message, status } = await login(username, password);

        if (status === LoginStatus.Success) {
            setUserId(userId);
            fetchServiceApp().then((result) => {
                setServiceApp(result);
                navigate('/');
            });
        }

        if (status === LoginStatus.Fail) {
            showError(message);
        }
    };

    const onRestore = () => {};

    return {
        loading,
        form,
        goRestorePassword,
        onLogin,
        onRestore,
    };
};
