import { ReactElement } from 'react';
import { makeAutoObservable } from 'mobx';

export default class PageTitleStore {
    public pageTitle: string | ReactElement = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPageTitle(pageTitle: string | ReactElement) {
        this.pageTitle = pageTitle;
    }
}
