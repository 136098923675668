import { PAGE_TITLE_PAYMENT_TYPE } from 'constants/pageTitles';

import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
    Box, Button, Paper, Stack, Typography,
} from '@mui/material';
import { DataGridPremium, GridColumns, ruRU } from '@mui/x-data-grid-premium';
import { useStore } from 'store';
import { CourtTypeT } from 'types/CourtType';
import { PaymentMethodT } from 'types/PaymentMethod';
import { PriceTypeT } from 'types/PriceType';

type DataType = CourtTypeT | PriceTypeT | PaymentMethodT;

interface DetailPanelContentProps {
    row: DataType;
    columns: GridColumns;
}

export const DetailPanelContent: FC<DetailPanelContentProps> = ({
    row: rowProp, columns,
}) => {
    const { onOpen: courtCreateModal } = useStore('createCourtModal');
    const { onOpen: paymentTypeCreateModal } = useStore('createPaymentType');
    const { onOpen: PriceSalesRoundCreateModal } = useStore('createPriceSalesRound');

    return (
        <Stack sx={ { py: 1, boxSizing: 'border-box' } } direction="column">
            <Paper sx={ {
                mx: 'auto', width: '90%', p: 1,
            } }
            >
                <Stack direction="column" spacing={ 1 } sx={ { height: 1 } }>
                    { ('priceSalesRounds' in rowProp) && (
                        <>
                            <Typography variant="h6">Раунды продаж</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => PriceSalesRoundCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="compact"
                                autoHeight
                                columns={ columns }
                                rows={ rowProp.priceSalesRounds }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { ('courts' in rowProp) && (
                        <>
                            <Typography variant="h6">Корты</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => courtCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="comfortable"
                                autoHeight
                                rows={ rowProp.courts }
                                hideFooter
                                columns={ columns }
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                    { ('paymentTypes' in rowProp) && (
                        <>
                            <Typography variant="h6">{ PAGE_TITLE_PAYMENT_TYPE }</Typography>
                            <Box>
                                <Button variant="outlined" size="small" onClick={ () => paymentTypeCreateModal(rowProp.id) } startIcon={ <AddIcon /> }>
                                    Добавить
                                </Button>
                            </Box>
                            <DataGridPremium
                                density="compact"
                                autoHeight
                                columns={ columns }
                                rows={ rowProp.paymentTypes }
                                hideFooter
                                localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                            />
                        </>
                    ) }
                </Stack>
            </Paper>
        </Stack>
    );
};
