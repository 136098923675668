import { ERROR_ORGANIZATION_DELETE, SUCCESS_ORGANIZATION_DELETE } from 'constants/notificationMessage';

import useOrganizationDelete from 'shared/data-hook/organization/delete/useOrganizationDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { OrganizationT } from 'types/Organization';

const useDeleteOrganization = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteOrganization } = useOrganizationDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const organizationDelete = async (organizationId: OrganizationT['id']) => {
        const response = await deleteOrganization(organizationId);

        if (error || !response || !response.deleteOrganization) {
            showError(ERROR_ORGANIZATION_DELETE);

            return;
        }

        showSuccess(SUCCESS_ORGANIZATION_DELETE);
        toggleRefetchTrigger();
    };

    return {
        organizationDelete,
    };
};

export default useDeleteOrganization;
