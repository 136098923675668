import { EMPTY_CELL } from 'constants/dataGrid';
import { MONTHS_LIST } from 'constants/MonthsList';
import {
    PROPERTY_BIRTHDAY_DAY,
    PROPERTY_BIRTHDAY_MONTH,
    PROPERTY_BIRTHDAY_YEAR,
    PROPERTY_CLIENT_FIRST_NAME_SHORT,
    PROPERTY_CLIENT_LAST_NAME_SHORT,
    PROPERTY_CLIENT_MIDDLE_NAME_SHORT,
    PROPERTY_EXTENSION_PHONE_NUMBER,
    PROPERTY_PERSONAL_MANAGER,
    PROPERTY_PHONE_NUMBER,
} from 'constants/propertyNames';

import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { CustomHeaderData } from 'types/DataGrid';

export const CLIENT_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_CLIENT_LAST_NAME_SHORT,
        field: 'lastName',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['last_name'],
        hasuraSortFields: ['last_name'],
        renderCell: (params) => renderStringCell(params.row.lastName),
    },
    {
        headerName: PROPERTY_CLIENT_FIRST_NAME_SHORT,
        field: 'firstName',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['first_name'],
        hasuraSortFields: ['first_name'],
        renderCell: (params) => renderStringCell(params.row.firstName),
    },
    {
        headerName: PROPERTY_CLIENT_MIDDLE_NAME_SHORT,
        field: 'middleName',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['middle_name'],
        hasuraSortFields: ['middle_name'],
        renderCell: (params) => renderStringCell(params.row.middleName),
    },
    {
        headerName: PROPERTY_BIRTHDAY_DAY,
        field: 'birthdayDay',
        flex: 0.75,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['birthday_day'],
        hasuraSortFields: ['birthday_day'],
        renderCell: (params) => renderStringCell(params.row?.birthdayDay),
    },
    {
        headerName: PROPERTY_BIRTHDAY_MONTH,
        field: 'birthdayMonth',
        flex: 0.75,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['birthday_month'],
        hasuraSortFields: ['birthday_month'],
        valueOptions: MONTHS_LIST,
        renderCell: (params) => MONTHS_LIST.find((month) =>
            month.value === params.row?.birthdayMonth)?.label ?? EMPTY_CELL,
    },
    {
        headerName: PROPERTY_BIRTHDAY_YEAR,
        field: 'birthdayYear',
        flex: 0.75,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['birthday_year'],
        hasuraSortFields: ['birthday_year'],
        renderCell: (params) => renderStringCell(params.row?.birthdayYear),
    },
    {
        headerName: PROPERTY_PHONE_NUMBER,
        field: 'phone',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => renderStringCell(params.row.phone),
    },
    {
        headerName: PROPERTY_EXTENSION_PHONE_NUMBER,
        field: 'extensionPhone',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: (params) => renderStringCell(params.row.extensionPhone),
    },
    {
        headerName: PROPERTY_PERSONAL_MANAGER,
        field: 'personalManagerFullName',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['manager_id'],
        hasuraSortFields: ['personal_manager_full_name'],
        renderCell: (params) => renderStringCell(params.row.personalManagerFullName),
    },
];
