import { HasuraOperatorT } from 'constants/gqlOperators';

import { buildNestedObject } from '../../../utils/buildNestedObject';

enum numberGridOperators {
    '=' = '=',
    '!=' = '!=',
    '>=' = '>=',
    '>' = '>',
    '<=' = '<=',
    '<' = '<',
    isEmpty = 'isEmpty',
    isNotEmpty = 'isNotEmpty',
    isAnyOf = 'isAnyOf',
}

type numberGridOperatorsT = keyof typeof numberGridOperators

export const filterNumberField = (filter: any, value: number | number[], gridOperator: numberGridOperatorsT, hasuraOperator: HasuraOperatorT, hasuraFilterFields: string[], field: string) => {
    const updatedFilter = { ...filter };
    let filterValue = {};

    if (!value && gridOperator !== numberGridOperators.isEmpty && gridOperator !== numberGridOperators.isNotEmpty) {
        return;
    }

    switch (gridOperator) {
        case numberGridOperators['=']:
            filterValue = { [field]: { _eq: value } };
            break;
        case numberGridOperators['!=']:
            filterValue = { [field]: { _neq: value } };
            break;
        case numberGridOperators['>=']:
            filterValue = { [field]: { _gte: value } };
            break;
        case numberGridOperators['>']:
            filterValue = { [field]: { _gt: value } };
            break;
        case numberGridOperators['<=']:
            filterValue = { [field]: { _lte: value } };
            break;
        case numberGridOperators['<']:
            filterValue = { [field]: { _lt: value } };
            break;
        case numberGridOperators.isEmpty:
            filterValue = { [field]: { _is_null: true } };
            break;
        case numberGridOperators.isNotEmpty:
            filterValue = { [field]: { _is_null: false } };
            break;
        case numberGridOperators.isAnyOf:
            if (!Array.isArray(value) || value.length === 0) {
                break;
            }
            filterValue = { [field]: { _in: value } };
            break;
    }

    if (!updatedFilter[hasuraOperator][hasuraOperator]) {
        updatedFilter[hasuraOperator][hasuraOperator] = [];
    }

    updatedFilter[hasuraOperator][hasuraOperator].push(buildNestedObject(hasuraFilterFields, { ...filterValue }));
};
