import { makeAutoObservable } from 'mobx';

export default class DeleteItemModal {
    public deletedItemId = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(deletedItemId: string) {
        this.deletedItemId = deletedItemId;
    }

    public onClose() {
        this.deletedItemId = '';
    }
}
