import { gql } from '@apollo/client';

export type CourtCategoryUpdateVarT = {
    onConflict: {
        constraint: string;
        update_columns: string[];
    };
    courtCategoryPayload: Array<{
        id: string;
        is_enabled_for_client?: boolean;
        name: string;
        position: number;
        court_court_categories: {
            data: Array<{
                court_id: string;
            }>;
        };
    }>;
};

export type CourtCategoryUpdateResponseDataT = {
    updateCourtCategory: {
        id: string;
    };
} | null | undefined;

export const COURT_CATEGORY_UPDATE = gql`
    mutation UpdateCourtCategory(
        $onConflict: court_category_on_conflict = {}
        $courtCategoryPayload: [court_category_insert_input!]!,
    ) {
        updateCourtCategory: insert_court_category(
            on_conflict: $onConflict
            objects: $courtCategoryPayload,
        ) {
            affected_rows
        }
    }
`;
