import { useMutation } from '@apollo/client';
import { OrganizationLocationFormFields } from 'page/OrganizationLocation/components/formData';
import {
    ORGANIZATION_LOCATION_UPDATE,
    OrganizationLocationUpdateResponseDataT, OrganizationLocationUpdateVarT,
} from 'shared/graphql/mutation/organizationLocation/OrganizationLocationUpdate';

import { OrganizationLocationUpdateDataT } from './OrganizationLocationUpdateData';

const useOrganizationLocationUpdate = (): OrganizationLocationUpdateDataT => {
    const [update, {
        loading,
        error,
    }] = useMutation<OrganizationLocationUpdateResponseDataT, OrganizationLocationUpdateVarT>(ORGANIZATION_LOCATION_UPDATE);

    const updateAction = async (input: OrganizationLocationFormFields, organizationLocationId: string) => {
        if (!organizationLocationId) {
            throw new Error('Organization Location is not found');
        }

        try {
            const { data } = await update({
                variables: {
                    id: organizationLocationId,
                    set: {
                        name: input.name,
                    },
                },
            });

            return data;
        } catch (errorUpdate) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw errorUpdate;
        }
    };

    return {
        organizationLocationUpdate: updateAction,
        loading,
        error,
    };
};

export default useOrganizationLocationUpdate;
