import { gql } from '@apollo/client';
import { AggregateT } from 'types/Graphql';
import { PaymentT } from 'types/Payment';

export type PaymentsResponseT = {
    payments: PaymentT[];
    paymentsCount: AggregateT;
}

export const PAYMENTS_QUERY = gql`
    query Payments(
        $limit: Int,
        $offset: Int,
        $orderBy: payment_order_by!,
        $where: payment_bool_exp!
    ) {
        payments: payment(
            limit: $limit
            offset: $offset,
            order_by: [$orderBy]
            where: $where
        ) {
            id
            payment_document {
                number
            }
            datetime
            client_id
            payer
            payment_method {
                title
            }
            performer
            user_id
            comment
            organization_location {
                name
            }
            total
            payment_in_fiat
            payment_in_bonus
            payment_type {
                title
            }
            client_datum {
                client {
                    first_name
                    middle_name
                    last_name
                }
            }
        }
        paymentsCount: payment_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
