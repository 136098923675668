import { ERROR_COURT_CATEGORY_DELETE, SUCCESS_COURT_CATEGORY_DELETE } from 'constants/notificationMessage';

import useCourtCategoryDelete from 'shared/data-hook/courtCategory/delete/single/useCourtCategoryDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtCategoryT } from 'types/CourtCategory';

const useDeleteCourtCategory = () => {
    const { error, deleteCourtCategory } = useCourtCategoryDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const courtCategoryDelete = async (courtCategoryId: CourtCategoryT['id']) => {
        const response = await deleteCourtCategory(courtCategoryId);

        if (error || !response) {
            showError(ERROR_COURT_CATEGORY_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_COURT_CATEGORY_DELETE);
        }
    };

    return {
        courtCategoryDelete,
    };
};

export default useDeleteCourtCategory;
