import { gql } from '@apollo/client';
import { CourtTypeT } from 'types/CourtType';

export type DeleteMultipleCourtTypeResponseT = {
    deleteMultipleCourtType: DeleteMultipleCourtTypeResponseDataT;
};

export type DeleteMultipleCourtTypeResponseDataT = {
    affectedRows: number;
};

export type DeleteMultipleCourtTypeVarT = {
    ids: [CourtTypeT['id']];
};

export const DELETE_MULTIPLE_COURT_TYPE = gql`
    mutation DeleteMultipleCourtType($ids: [String!] = []) {
        deleteMultipleCourtType: delete_court_type(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
