import { useMutation } from '@apollo/client';
import {
    MEMBER_CARD_TYPE_DELETE,
    MemberCardTypeDeleteResponseDataT,
    MemberCardTypeDeleteVarT,
} from 'shared/graphql/mutation/memberCardType/MemberCardTypeDelete';
import { MemberCardTypeT } from 'types/MemberCardType';

import { MemberCardTypeDeleteDataT } from './MemberCardTypeDeleteData';

const useMemberCardTypeDelete = (): MemberCardTypeDeleteDataT => {
    const [memberCardTypeDelete, { loading, error }] = useMutation<
        MemberCardTypeDeleteResponseDataT,
        MemberCardTypeDeleteVarT
    >(MEMBER_CARD_TYPE_DELETE);

    const deleteAction = async (memberCardTypeId: MemberCardTypeT['id']) => {
        try {
            const { data } = await memberCardTypeDelete({
                variables: {
                    id: memberCardTypeId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteMemberCardType: deleteAction,
        loading,
        error,
    };
};

export default useMemberCardTypeDelete;
