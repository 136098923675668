import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { ORGANIZATION_LOCATIONS_QUERY, OrganizationLocationsResponseT } from 'shared/graphql/query/organizationLocation/OrganizationLocationQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_ORGANIZATION_LOCATIONS_RESULT } from 'types/OrganizationLocation';

import { OrganizationLocationsDTO } from '../DTO/OrganizationLocationsDTO';

import { OrganizationLocationsDataT } from './OrganizationLocationsData';

const useOrganizationLocations = (): OrganizationLocationsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<OrganizationLocationsResponseT>(
        ORGANIZATION_LOCATIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );
    const decodedOrganizationLocationData = decodeDTO(
        OrganizationLocationsDTO,
        EMPTY_ORGANIZATION_LOCATIONS_RESULT,
        (organizationLocations) => organizationLocations,
        {
            organizationLocations: data?.organizationLocations,
            organizationLocationsCount: data?.organizationLocationsCount,
        },
    );

    const fetchOrganizationLocations = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        const updatedItems = where.items.map((item) => {
            if (item.columnField === 'personalManagers' && item.operatorValue === 'isEmpty') {
                return {
                    id: item.id,
                    type: item.type,
                    columnField: item.columnField,
                    hasuraFilterFields: ['personal_manager_locations_aggregate', 'count', 'predicate'],
                    value: '0',
                    operatorValue: 'jsonIsEmptyOperator',
                };
            }

            return item;
        });

        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData({ items: updatedItems, linkOperator: where.linkOperator }),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchOrganizationLocations,
        loading,
        data: decodedOrganizationLocationData.organizationLocations,
        count: decodedOrganizationLocationData.organizationLocationsCount,
    };
};

export default useOrganizationLocations;
