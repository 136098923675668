import { gql } from '@apollo/client';
import { CourtTypePeriodT } from 'types/CourtType';

export type CourtTypeCreatePayloadT = {
    name: string;
    duration: string;
    use_halves: boolean;
    periods: CourtTypePeriodT[];
};

export type CourtTypeCreateResponseDataT = {
    createCourtType: {
        id: string;
    };
} | null | undefined;

export type CourtTypeCreateVarT = {
    object: CourtTypeCreatePayloadT;
};

export const COURT_TYPE_CREATE = gql`
    mutation CourtTypeCreate($object: court_type_insert_input = {}) {
        createCourtType: insert_court_type_one(
            object: $object
        ) {
            id
        }
    }
`;
