import {
    PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT, PROPERTY_CASHBACK_FIAT_SUM_LIMIT,
    PROPERTY_EXPIRATION_PERIOD,
    PROPERTY_IS_ACTIVE,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import { number, object, string } from 'yup';

export type MemberCardTypeFormFields = {
    title: string;
    isActive: boolean;
    expirationPeriod: number;
    cashbackFiatOperationsLimit: number;
    cashbackFiatSumLimit: number;
};

export const MEMBER_CARD_TYPE_DEFAULT_VALUES: MemberCardTypeFormFields = {
    title: '',
    isActive: false,
    expirationPeriod: 0,
    cashbackFiatOperationsLimit: 0,
    cashbackFiatSumLimit: 0,
};

export const CREATE_MEMBER_CARD_TYPE_DEFAULT_VALUES = { ...MEMBER_CARD_TYPE_DEFAULT_VALUES };

export enum MEMBER_CARD_TYPE_FIELDS_KEYS {
    title = 'title',
    isActive = 'isActive',
    expirationPeriod = 'expirationPeriod',
    cashbackFiatOperationsLimit = 'cashbackFiatOperationsLimit',
    cashbackFiatSumLimit = 'cashbackFiatSumLimit',
}

type MemberCardTypeLabelDataT = Record<keyof MemberCardTypeFormFields, string>;

export const MEMBER_CARD_TYPE_LABEL_DATA: MemberCardTypeLabelDataT = {
    title: PROPERTY_TITLE,
    isActive: PROPERTY_IS_ACTIVE,
    expirationPeriod: PROPERTY_EXPIRATION_PERIOD,
    cashbackFiatOperationsLimit: PROPERTY_CASHBACK_FIAT_OPERATIONS_LIMIT,
    cashbackFiatSumLimit: PROPERTY_CASHBACK_FIAT_SUM_LIMIT,
};

export const MEMBER_CARD_TYPE_VALIDATION_SCHEMA = object().shape({
    title: string().required(),
    expirationPeriod: number().required(),
}).required();
