import { ERROR_COURT_DELETE, SUCCESS_COURT_DELETE } from 'constants/notificationMessage';

import useCourtDelete from 'shared/data-hook/court/delete/single/useCourtDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtT } from 'types/Court';

const useDeleteCourt = () => {
    const { error, deleteCourt } = useCourtDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const courtDelete = async (courtId: CourtT['id']) => {
        const response = await deleteCourt(courtId);

        if (error || !response) {
            showError(ERROR_COURT_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_COURT_DELETE);
        }
    };

    return {
        courtDelete,
    };
};

export default useDeleteCourt;
