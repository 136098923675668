import { makeAutoObservable } from 'mobx';
import { CoachesInGroupT } from 'types/CoachesInGroup';

export default class CoachesInGroupStore {
    public coachesInGroups: CoachesInGroupT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setCoachesInGroups(coachesInGroups: CoachesInGroupT[], count?: number) {
        this.coachesInGroups = coachesInGroups;
        this.count = count ?? 0;
    }
}
