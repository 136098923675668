import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { MemberCardTypesT } from 'types/MemberCardType';

import { MemberCardTypeDTO } from './MemberCardTypeDTO';

const DTO = t.type({
    memberCardTypes: t.array(MemberCardTypeDTO),
    memberCardTypesCount: AggregateDTO,
});

type MemberCardTypesDTOT = t.TypeOf<typeof DTO>;

export const MemberCardTypesDTO = tPromise.extendDecoder<MemberCardTypesDTOT, MemberCardTypesT>(
    DTO,
    ({
        memberCardTypes,
        memberCardTypesCount,
    }) => ({
        memberCardTypes,
        memberCardTypesCount,
    }),
) as t.Type<MemberCardTypesT, MemberCardTypesDTOT>;
