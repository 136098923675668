import { makeAutoObservable } from 'mobx';
import { PaymentMethodT } from 'types/PaymentMethod';

export default class CreatePaymentMethodModal {
    public isOpen: boolean = false;

    public paymentMethod: PaymentMethodT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onClose() {
        this.isOpen = false;
    }

    public onOpen() {
        this.isOpen = true;
    }
}
