import { makeAutoObservable } from 'mobx';
import { CourtTypeT } from 'types/CourtType';

export default class CreateCourtModal {
    public isOpen: boolean = false;

    public courtTypeId: CourtTypeT['id'] = '';

    public onOpen(courtTypeId: CourtTypeT['id'] = '') {
        this.isOpen = true;
        this.courtTypeId = courtTypeId;
    }

    public onClose() {
        this.isOpen = false;
        this.courtTypeId = '';
    }

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }
}
