import { PAGE_TITLE_IMPLEMENTATION } from 'constants/pageTitles';

import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useImplementations from 'shared/data-hook/implementation/fetch/fetchAll/useImplementations';
import { SERVICE_CATEGORY_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceCategory/ServiceCategoryQuery';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import ImplementationList from './components/ImplementationList';
import { IMPLEMENTATION_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: implementationsData, loading: implementationsLoading, count: rowCount, fetchImplementations,
    } = useImplementations();
    const { setImplementations } = useStore('implementation');
    const { refetchTrigger } = useStore('refetchTrigger');

    const { loading: serviceCategoriesLoading } = useFetchFilterOptions(SERVICE_CATEGORY_FILTER_OPTIONS_QUERY, 'serviceCategory');

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchImplementations, refetchTrigger, IMPLEMENTATION_HEADER_DATA);

    usePageTitle(PAGE_TITLE_IMPLEMENTATION);

    useEffect(() => {
        setImplementations(implementationsData, rowCount);
    }, [implementationsData, implementationsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <ImplementationList
                loading={ implementationsLoading || serviceCategoriesLoading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
