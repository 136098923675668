import {
    BULK_OPERATIONS_ERROR_MESSAGE,
    BULK_OPERATIONS_STATUS_ICONS,
    BULK_OPERATIONS_STATUS_OPTIONS,
    BULK_OPERATIONS_TYPE_DICTIONARY,
    BULK_OPERATIONS_TYPE_OPTIONS,
    BulkOperationErrorMessages,
    BulkOperationsType,
} from 'constants/bulkOperations';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_BULK_OPERATION_TYPE,
    PROPERTY_END,
    PROPERTY_ERROR_MESSAGE,
    PROPERTY_INPUT_DATA,
    PROPERTY_START,
    PROPERTY_STATUS,
} from 'constants/propertyNames';

import React, { FC, useState } from 'react';
import { Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import { CustomHeaderData } from 'types/DataGrid';

import BulkOperationInputData from '../components/BulkOperationInputData';

const RenderCellErrorMessage: FC<GridRenderCellParams> = (props) => {
    const { row: { errorMessage, errorData } } = props;

    return (
        <Grid2 display="flex" flexDirection="column">
            <Typography fontSize="small">{ BULK_OPERATIONS_ERROR_MESSAGE[errorMessage as BulkOperationErrorMessages] || errorMessage }</Typography>
            { errorData.map((errorDataItem: string) => (
                <Typography fontSize="small" key={ errorDataItem }>{ dayjs(errorDataItem).format(DATE_TIME_FORMAT_FOR_DATA_GRID) }</Typography>
            )) }
        </Grid2>
    );
};

const RenderCellInputData: FC<GridRenderCellParams> = (props) => {
    const { row: { inputData } } = props;
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Button onClick={ onOpen }>
                Данные
            </Button>
            <BulkOperationInputData inputData={ inputData } isOpen={ isOpen } onOpen={ onOpen } />
        </>
    );
};

export const BULK_OPERATIONS_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_STATUS,
        field: 'status',
        flex: 0.4,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => <span>{ BULK_OPERATIONS_STATUS_ICONS[params.row.status] }</span>,
        type: 'singleSelect',
        valueOptions: BULK_OPERATIONS_STATUS_OPTIONS,
    },
    {
        headerName: PROPERTY_BULK_OPERATION_TYPE,
        field: 'type',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => <span>{ BULK_OPERATIONS_TYPE_DICTIONARY[params.row.type as BulkOperationsType] }</span>,
        type: 'singleSelect',
        hasuraFilterFields: ['type'],
        hasuraSortFields: ['type'],
        valueOptions: BULK_OPERATIONS_TYPE_OPTIONS,
    },
    {
        headerName: PROPERTY_START,
        field: 'createdAt',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => renderDateToGrid(params.row.createdAt, DATE_TIME_FORMAT_FOR_DATA_GRID),
        type: 'date',
    },
    {
        headerName: PROPERTY_END,
        field: 'finishedAt',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => renderDateToGrid(params.row.finishedAt, DATE_TIME_FORMAT_FOR_DATA_GRID),
        type: 'date',
    },
    {
        headerName: PROPERTY_ERROR_MESSAGE,
        field: 'errorMessage',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        renderCell: RenderCellErrorMessage,
    },
    {
        headerName: PROPERTY_INPUT_DATA,
        field: 'inputData',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'object',
        renderCell: RenderCellInputData,
    },
];
