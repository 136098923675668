import { useMemo } from 'react';
import { GridColumns } from '@mui/x-data-grid-premium';
import { mapFields } from 'shared/utils/headerData/mapFields';
import { FilterOptionT } from 'types/DataGrid';

import { COACHES_IN_GROUPS_HEADER_DATA } from './HeaderData';

export const prepareCoachesInGroupsHeaderData = (groups: FilterOptionT[], coaches: FilterOptionT[]): GridColumns =>
    useMemo(() => mapFields(COACHES_IN_GROUPS_HEADER_DATA, {
        group: groups,
        mainCoach: coaches,
    }), [groups]);
