import { FC } from 'react';
import { TextField as BaseTextField } from '@mui/material';
import { TextFieldPropsSizeOverrides } from '@mui/material/TextField/TextField';
import { OverridableStringUnion } from '@mui/types';
import { DatePicker as BaseDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';

import 'dayjs/locale/ru';

type PropsT = {
    setValue: any;
    value: Dayjs | null;
    label?: string;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
};

const DatePicker: FC<PropsT> = (props) => {
    const {
        setValue, value, label, size,
    } = props;

    return (
        <LocalizationProvider
            dateAdapter={ AdapterDayjs }
            adapterLocale="ru"
        >
            <BaseDatePicker
                label={ label }
                value={ value }
                onChange={ (newValue: any) => {
                    setValue(newValue);
                } }
                renderInput={ (params) => (
                    <BaseTextField
                        size={ size || 'small' }
                        { ...params }
                        inputProps={ {
                            ...params.inputProps,
                            readOnly: true,
                        } }
                    />
                ) }
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
