import { makeAutoObservable } from 'mobx';
import { ServiceCategoryT } from 'types/ServiceCategory';

export default class ServiceCategoryStore {
    public serviceCategoryList: ServiceCategoryT[] = [];

    public serviceCategory: ServiceCategoryT | undefined = undefined;

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get serviceCategories(): ServiceCategoryT[] {
        return this.serviceCategoryList;
    }

    public setServiceCategories(serviceCategories: ServiceCategoryT[], count?: number) {
        this.serviceCategoryList = serviceCategories;
        this.count = count ?? 0;
    }
}
