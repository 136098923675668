import { FormErrorMessages } from 'enums/FormErrorMessages';
import { FormErrors } from 'types/FormErrors';

export const COMMON_FORM_ERRORS: FormErrors = {
    [FormErrorMessages.Required]: 'Поле обязательно для заполнения',
    [FormErrorMessages.TypeError]: 'Некорректный тип данных',
    [FormErrorMessages.Min]: (min: number) => `Введите не менее ${min} символов`,
    [FormErrorMessages.MinSelected]: (min: number) => `Выберите не менее ${min}`,
    [FormErrorMessages.SamePassword]: 'Пароли должны совпадать',
};
