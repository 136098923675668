import { makeAutoObservable } from 'mobx';
import { NormalizedUser } from 'shared/data-hook/user';

export default class UserStore {
    public activeUser: NormalizedUser = null;

    public isActiveUserFetching = false;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setActiveUser(activeUser: NormalizedUser) {
        this.activeUser = activeUser;
    }

    public setIsActiveUserFetching(isActiveUserFetching: boolean) {
        this.isActiveUserFetching = isActiveUserFetching;
    }
}
