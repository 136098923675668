export type OrganizationLocationT = {
    id: string;
    name: string;
    personalManagers: string[];
};

export type OrganizationLocationsT = {
    organizationLocations: OrganizationLocationT[];
    organizationLocationsCount: number;
}

export const EMPTY_ORGANIZATION_LOCATIONS_RESULT: OrganizationLocationsT = {
    organizationLocations: [],
    organizationLocationsCount: 0,
};

export const EMPTY_ORGANIZATION_LOCATION_RESULT: OrganizationLocationT = {
    id: '',
    name: '',
    personalManagers: [],
};
