import { useLazyQuery } from '@apollo/client';
import { CourtTypesDTO } from 'shared/data-hook/courtType/fetch/DTO/CourtTypesDTO';
import { CourtTypesDataT } from 'shared/data-hook/courtType/fetch/fetchAll/CourtTypesData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { COURT_TYPES_QUERY, CourtTypesResponseT } from 'shared/graphql/query/courtType/CourtTypeQuery';
import { EMPTY_COURT_TYPES_RESULT } from 'types/CourtType';
import { FetchDataParams } from 'types/DataGrid';

const useCourtTypes = (): CourtTypesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CourtTypesResponseT>(
        COURT_TYPES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCourtTypeData = decodeDTO(
        CourtTypesDTO,
        EMPTY_COURT_TYPES_RESULT,
        (courtTypes) => courtTypes,
        {
            courtTypes: data?.courtTypes,
            courtTypesCount: data?.courtTypesCount,
        },
    );

    const fetchCourtTypes = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchCourtTypes,
        loading,
        data: decodedCourtTypeData?.courtTypes,
        count: decodedCourtTypeData?.courtTypesCount,
    };
};

export default useCourtTypes;
