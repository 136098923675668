export type ClientViewT = {
    id: string;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    birthdayYear: string | null;
    birthdayMonth: string | null;
    birthdayDay: string | null;
    phone: string | null;
    extensionPhone: string | null;
    managerId: string | null;
    personalManagerFullName: string | null;
};

export type ClientViewsT = {
    clientViews: ClientViewT[];
    clientViewsCount: number;
};

export const EMPTY_CLIENT_VIEWS_RESULT: ClientViewsT = {
    clientViews: [],
    clientViewsCount: 0,
};
