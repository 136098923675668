import { makeAutoObservable } from 'mobx';
import { OrganizationLocationT } from 'types/OrganizationLocation';

export default class OrganizationLocationStore {
    public organizationLocationList: OrganizationLocationT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get organizationLocations(): OrganizationLocationT[] {
        return this.organizationLocationList;
    }

    public setOrganizationLocations(
        organizationLocations: OrganizationLocationT[],
        count?: number,
    ) {
        this.organizationLocationList = organizationLocations;
        this.count = count ?? 0;
    }
}
