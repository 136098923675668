import { ERROR_COURT_CATEGORY_CREATE, SUCCESS_COURT_CATEGORY_CREATE } from 'constants/notificationMessage';

import { CourtCategoryFormFields } from 'page/CourtCategory/components/formData';
import useCreateCourtCategory from 'shared/data-hook/courtCategory/create/useCreateCourtCategory';
import { useNotification } from 'shared/hook/useNotification';

const useCourtCategoryCreate = () => {
    const { showError, showSuccess } = useNotification();
    const { loading, error, courtCategoryCreate } = useCreateCourtCategory();

    const createCourtCategory = async (input: CourtCategoryFormFields) => {
        const response = await courtCategoryCreate(input);

        if (error || !response || !response.createCourtCategory) {
            showError(ERROR_COURT_CATEGORY_CREATE);
        } else {
            showSuccess(SUCCESS_COURT_CATEGORY_CREATE);
        }
    };

    return {
        createCourtCategory,
        loading,
    };
};

export default useCourtCategoryCreate;
