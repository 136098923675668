import {
    PROPERTY_IS_COACH_AVAILABLE_HELPER_TEXT,
    PROPERTY_IS_GROUP_AVAILABLE_HELPER_TEXT,
} from 'constants/propertyNames';

import { FC } from 'react';
import { Control } from 'react-hook-form';
import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import AuxiliaryPopover from 'shared/component/AuxiliaryPopover';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';

import {
    PRICE_TYPE_DEFAULT_VALUES, PRICE_TYPE_FIELDS_KEYS, PRICE_TYPE_LABEL_DATA, PriceTypeFormFields,
} from './formData';

type PropsT = {
    control: Control<PriceTypeFormFields>;
    loading?: boolean;
};

const PriceTypeForm: FC<PropsT> = (props) => {
    const { control, loading } = props;

    return (
        <Box my={ 1 }>
            <Grid2 direction="column" spacing={ 1 }>
                <Grid2 container xs={ 12 } spacing={ 2 }>
                    <Grid2 xs={ 6 }>
                        <TextField
                            key={ PRICE_TYPE_FIELDS_KEYS.title }
                            loading={ loading }
                            name={ PRICE_TYPE_FIELDS_KEYS.title as keyof PriceTypeFormFields }
                            type={ typeof PRICE_TYPE_DEFAULT_VALUES[PRICE_TYPE_FIELDS_KEYS.title as keyof PriceTypeFormFields] }
                            control={ control }
                            label={ PRICE_TYPE_LABEL_DATA[PRICE_TYPE_FIELDS_KEYS.title as keyof PriceTypeFormFields] }
                        />
                    </Grid2>
                    <Grid2 xs={ 6 }>
                        <TextField
                            key={ PRICE_TYPE_FIELDS_KEYS.position }
                            loading={ loading }
                            name={ PRICE_TYPE_FIELDS_KEYS.position as keyof PriceTypeFormFields }
                            type={ typeof PRICE_TYPE_DEFAULT_VALUES[PRICE_TYPE_FIELDS_KEYS.position as keyof PriceTypeFormFields] }
                            control={ control }
                            label={ PRICE_TYPE_LABEL_DATA[PRICE_TYPE_FIELDS_KEYS.position as keyof PriceTypeFormFields] }
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={ 2 } direction="column">
                    <Grid2 xs={ 5 }>
                        <Switch
                            loading={ loading }
                            key={ PRICE_TYPE_FIELDS_KEYS.isActive }
                            name={ PRICE_TYPE_FIELDS_KEYS.isActive as keyof PriceTypeFormFields }
                            control={ control }
                            label={ PRICE_TYPE_LABEL_DATA[PRICE_TYPE_FIELDS_KEYS.isActive as keyof PriceTypeFormFields] }
                        />
                    </Grid2>
                    <Grid2 xs={ 5 }>
                        <Box sx={ { display: 'flex', alignItems: 'baseline' } }>
                            <Switch
                                loading={ loading }
                                key={ PRICE_TYPE_FIELDS_KEYS.isGroupAvailable }
                                name={ PRICE_TYPE_FIELDS_KEYS.isGroupAvailable as keyof PriceTypeFormFields }
                                control={ control }
                                label={ PRICE_TYPE_LABEL_DATA[PRICE_TYPE_FIELDS_KEYS.isGroupAvailable as keyof PriceTypeFormFields] }
                            />
                            {
                                !loading && (
                                    <AuxiliaryPopover
                                        text={ PROPERTY_IS_GROUP_AVAILABLE_HELPER_TEXT }
                                    />
                                )
                            }
                        </Box>
                    </Grid2>
                    <Grid2 xs={ 5 }>
                        <Box sx={ { display: 'flex', alignItems: 'baseline' } }>
                            <Switch
                                loading={ loading }
                                key={ PRICE_TYPE_FIELDS_KEYS.isCoachAvailable }
                                name={ PRICE_TYPE_FIELDS_KEYS.isCoachAvailable as keyof PriceTypeFormFields }
                                control={ control }
                                label={ PRICE_TYPE_LABEL_DATA[PRICE_TYPE_FIELDS_KEYS.isCoachAvailable as keyof PriceTypeFormFields] }
                            />
                            {
                                !loading && (
                                    <AuxiliaryPopover
                                        text={ PROPERTY_IS_COACH_AVAILABLE_HELPER_TEXT }
                                    />
                                )
                            }
                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Box>
    );
};

export default PriceTypeForm;
