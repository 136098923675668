import { HasuraOperatorT, LINK_OPERATOR, LinkOperatorT } from 'constants/gqlOperators';

import { decamelize } from 'humps';
import { WhereT } from 'types/DataGrid';

import { filterBooleanField } from './filterBooleanField';
import { filterDateField } from './filterDateField';
import { filterNumberField } from './filterNumberField';
import { filterSingleSelectField } from './filterSingleSelectField';
import { filterStringField } from './filterStringField';

export const filterData = (where: WhereT = { items: [], linkOperator: 'and' }) => {
    const { items, linkOperator } = where;

    if (items.length === 0) {
        return {};
    }

    const hasuraLinkOperator: HasuraOperatorT = LINK_OPERATOR[linkOperator as LinkOperatorT];

    const filterModel: any = { [hasuraLinkOperator]: {} };

    items.forEach((item: any) => {
        const field: string = item.hasuraFilterFields.length > 0 ? item.hasuraFilterFields.pop() : decamelize(item.columnField);

        switch (item.type) {
            case 'string':
                filterStringField(filterModel, item.value, item.operatorValue, hasuraLinkOperator, item.hasuraFilterFields, field);
                break;
            case 'number':
                filterNumberField(filterModel, item.value, item.operatorValue, hasuraLinkOperator, item.hasuraFilterFields, field);
                break;
            case 'singleSelect':
                filterSingleSelectField(filterModel, item.value, item.operatorValue, hasuraLinkOperator, item.hasuraFilterFields, field);
                break;
            case 'date':
                filterDateField(filterModel, item.value, item.operatorValue, hasuraLinkOperator, item.hasuraFilterFields, field);
                break;
            case 'boolean':
                filterBooleanField(filterModel, item.value, item.operatorValue, hasuraLinkOperator, item.hasuraFilterFields, field);
                break;
        }
    });

    return {
        ...filterModel,
    };
};
