import { MODAL_TITLE_UPDATE_MEMBER_CARD } from 'constants/modalTitles';

import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent,
} from '@mui/material';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import useFetchMemberCard from 'shared/hook/memberCard/fetch/fetchOne/useFetchMemberCard';
import useUpdateMemberCard from 'shared/hook/memberCard/update/useUpdateMemberCard';
import { useStore } from 'store';

import {
    getUpdateMemberCardValidationSchema,
    UPDATE_MEMBER_CARD_DEFAULT_VALUES,
    UpdateMemberCardFormFields,
} from './updateFormData';
import UpdateMemberCardForm from './UpdateMemberCardForm';

const UpdateMemberCardModal: FC = observer(() => {
    const { isOpen, close, id } = useStore('updateMemberCardModal');
    const { memberCard, memberCard: { id: memberCardId, datetimeEnd } } = useStore('memberCard');
    const { fetch: fetchMemberCard, loading } = useFetchMemberCard();
    const { updateMemberCard, loading: updateLoading } = useUpdateMemberCard();

    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<UpdateMemberCardFormFields>({
        defaultValues: UPDATE_MEMBER_CARD_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getUpdateMemberCardValidationSchema(datetimeEnd)),
    });

    const handleCloseModal = () => {
        reset({ datetimeEnd: dayjs(datetimeEnd), isActive: false });
        close();
    };

    const handleUpdateMemberCard = (data: UpdateMemberCardFormFields) => {
        updateMemberCard(data, memberCardId).then(
            handleCloseModal,
        );
    };

    useEffect(() => {
        fetchMemberCard();
    }, [id]);

    return (
        <Modal open={ isOpen } maxWidth="sm">
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_UPDATE_MEMBER_CARD }
            </ModalTitle>
            <DialogContent dividers={ true }>
                {
                    loading && <Spinner />
                }
                <UpdateMemberCardForm
                    control={ control }
                    setValue={ setValue }
                    memberCard={ memberCard }
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={ () => reset({ datetimeEnd: dayjs(datetimeEnd), isActive: memberCard.isActive }) }
                >
                    Сбросить
                </Button>
                <Button variant="outlined" onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    variant="contained"
                    onClick={ handleSubmit(handleUpdateMemberCard) }
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    loading={ updateLoading }
                >
                    Обновить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateMemberCardModal;
