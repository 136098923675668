import { gql } from '@apollo/client';
import { ClientViewT } from 'types/ClientView';
import { AggregateT } from 'types/Graphql';

export type ClientViewsResponseT = {
    clientViews: ClientViewT[];
    clientViewsCount: AggregateT;
};

export const CLIENT_VIEWS_QUERY = gql`
    query ClientViews(
        $limit: Int,
        $offset: Int,
        $orderBy: client_view_order_by!,
        $where: client_view_bool_exp!
    ) {
        clientViews: client_view(
            limit: $limit,
            offset: $offset
            order_by: [$orderBy]
            where: $where
        ) {
            id
            first_name
            last_name
            middle_name
            birthday_year
            birthday_month
            birthday_day
            phone
            extension_phone
            manager_id
            personal_manager_full_name
        }
        clientViewsCount: client_view_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
