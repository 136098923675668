import { useMemo } from 'react';
import { GridColDef, GridColumns } from '@mui/x-data-grid-premium';
import { FilterOptionT } from 'types/DataGrid';

import { ORGANIZATION_HEADER_DATA } from './HeaderData';

export const prepareOrganizationsHeaderData = (serviceApps: FilterOptionT[]): GridColumns => useMemo(() => (
    ORGANIZATION_HEADER_DATA.map((item: GridColDef) => (
        item.field === 'serviceApp' ? { ...item, valueOptions: serviceApps } : item
    ))
), [serviceApps]);
