import {
    TIME_24_HOURS_15_STEP,
} from 'constants/hours';
import { MODAL_TITLE_UPDATE_ORGANIZATION } from 'constants/modalTitles';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import React, {
    FC, useEffect, useMemo,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { Spinner } from 'shared/component/Spinner';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useFetchOrganization from 'shared/hook/organization/fetch/fetchOne/useFetchOrganization';
import useUpdateOrganization from 'shared/hook/organization/update/useUpdateOrganization';
import { useStore } from 'store';

import {
    ORGANIZATION_DEFAULT_VALUES, ORGANIZATION_FIELD_KEYS,
    ORGANIZATION_LABEL_DATA,
    ORGANIZATION_VALIDATION_SCHEMA, OrganizationFormFields,
} from './formdata';

const UpdateOrganizationModal: FC = observer(() => {
    const {
        isOpen, onClose, organization, updateOrganizationId,
    } = useStore('updateOrganizationModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const { loading: loadingFetch } = useFetchOrganization();
    const { updateOrganization, loading: loadingUpdate } = useUpdateOrganization();
    const { getFilterOption } = useStore('filterOptions');
    const serviceAppFilterOptions = getFilterOption('serviceApp');
    const { loading: serviceAppLoading } = useFetchFilterOptions(SERVICE_APP_FILTER_OPTIONS_QUERY, 'serviceApp');

    const {
        control, handleSubmit, setValue, reset, formState: { isValid, isDirty },
    } = useForm<OrganizationFormFields>({
        defaultValues: ORGANIZATION_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(ORGANIZATION_VALIDATION_SCHEMA),
    });

    const organizationInfo: OrganizationFormFields = useMemo(() => {
        const info = ORGANIZATION_DEFAULT_VALUES;

        if (organization) {
            Object.keys(organization).forEach((key) => {
                if (key === 'serviceApp') {
                    info[key as keyof OrganizationFormFields] = organization.serviceApp?.id as never;
                } else {
                    info[key as keyof OrganizationFormFields] = organization[key as keyof OrganizationFormFields] as never;
                }
            });
        }

        return info;
    }, [organization]);

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleUpdateOrganization = (data: OrganizationFormFields) => {
        updateOrganization(data, updateOrganizationId).then(() => handleClose());
    };

    useEffect(() => {
        Object.keys(organizationInfo).forEach((key) => {
            setValue(key as keyof OrganizationFormFields, organizationInfo[key as keyof OrganizationFormFields]);
        });

        toggleRefetchTrigger();
    }, [organization]);

    return (
        <Modal maxWidth="md" open={ isOpen }>
            <ModalTitle onClose={ handleClose }> { MODAL_TITLE_UPDATE_ORGANIZATION }</ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Spinner /> : (
                    <Grid2 xs={ 12 } container spacing={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                size="small"
                                label={ ORGANIZATION_LABEL_DATA.name }
                                name={ ORGANIZATION_FIELD_KEYS.name }
                                control={ control }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Select
                                label={ ORGANIZATION_LABEL_DATA.serviceApp }
                                name={ ORGANIZATION_FIELD_KEYS.serviceApp }
                                loading={ serviceAppLoading }
                                options={ serviceAppFilterOptions }
                                control={ control }
                            />
                        </Grid2>
                        <Grid2 xs={ 12 } display="flex" justifyContent="space-between">
                            <Typography variant="h6">Рабочее время</Typography>
                        </Grid2>
                        { WEEK_DAYS_VALUE_OPTIONS.map((day, index) => (
                            <Grid2 xs={ 12 } key={ day.value } container>
                                <Grid2 xs={ 2 } display="flex" alignItems="center" pt="30px">
                                    <Typography>{ day.label }</Typography>
                                </Grid2>
                                <Grid2 xs={ 5 }>
                                    <Select
                                        label={ PROPERTY_START }
                                        name={ `workTime.${index}.start` }
                                        options={ TIME_24_HOURS_15_STEP }
                                        control={ control }
                                    />
                                </Grid2>
                                <Grid2 xs={ 5 }>
                                    <Select
                                        label={ PROPERTY_END }
                                        name={ `workTime.${index}.end` }
                                        options={ TIME_24_HOURS_15_STEP }
                                        control={ control }
                                    />
                                </Grid2>
                            </Grid2>
                        )) }
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>
                    Отмена
                </Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    disabled={ !isValid || !isDirty }
                    autoFocus
                    onClick={ handleSubmit(handleUpdateOrganization) }
                    loading={ loadingUpdate }
                >
                    Добавить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateOrganizationModal;
