import { useEffect } from 'react';
import useCourt from 'shared/data-hook/court/fetch/fetchOne/useCourt';
import { useStore } from 'store';

const useFetchCourt = (id: string) => {
    const { fetchCourt, loading } = useCourt();
    const { updateItemId, isOpen, setCourt } = useStore('updateCourtModal');

    useEffect(() => {
        if (isOpen && updateItemId && id) {
            fetchCourt(updateItemId).then((fetchedCourt) => {
                setCourt(fetchedCourt);
            });
        }
    }, [isOpen, updateItemId, id]);

    return {
        loading,
    };
};

export default useFetchCourt;
