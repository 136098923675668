import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

const NoResult: FC = () => {
    return (
        <Box p={ 3 }>
            <Typography component="div" variant="h5">
                <Box sx={ { display: 'flex', alignItems: 'center' } }>
                    По вашему запросу ничего не найдено
                </Box>
            </Typography>
        </Box>
    );
};

export default NoResult;
