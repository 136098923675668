import {
    ERROR_ORGANIZATION_LOCATION_DELETE,
    SUCCESS_ORGANIZATION_LOCATION_DELETE,
} from 'constants/notificationMessage';

import useOrganizationLocationDelete
    from 'shared/data-hook/organizationLocation/delete/useOrganizationLocationDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { OrganizationLocationT } from 'types/OrganizationLocation';

const useDeleteOrganizationLocation = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteOrganizationLocation } = useOrganizationLocationDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const organizationLocationDelete = async (organizationLocationId: OrganizationLocationT['id']) => {
        const response = await deleteOrganizationLocation(organizationLocationId);

        if (error || !response || !response.deleteOrganizationLocation) {
            showError(ERROR_ORGANIZATION_LOCATION_DELETE);

            return;
        }

        showSuccess(SUCCESS_ORGANIZATION_LOCATION_DELETE);
        toggleRefetchTrigger();
    };

    return {
        organizationLocationDelete,
    };
};

export default useDeleteOrganizationLocation;
