import { makeAutoObservable } from 'mobx';
import { GroupViewT } from 'types/GroupView';

export default class UpdateGroupViewModal {
    public isOpen: boolean = false;

    public groupView: GroupViewT | undefined = undefined;

    public id: string = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(id: string) {
        this.id = id;
        this.isOpen = true;
    }

    public onClose() {
        this.id = '';
        this.isOpen = false;
    }

    public setGroupView(groupView: GroupViewT) {
        this.groupView = groupView;
    }
}
