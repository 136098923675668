import { makeAutoObservable } from 'mobx';
import { BulkOperationT } from 'types/BulkOperation';

export default class BulkOperationStore {
    public bulkOperationsList: BulkOperationT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get bulkOperations(): BulkOperationT[] {
        return this.bulkOperationsList;
    }

    public setBulkOperations(bulkOperations: BulkOperationT[], count?: number) {
        this.bulkOperationsList = bulkOperations;
        this.count = count ?? 0;
    }
}
