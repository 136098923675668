import { useLazyQuery } from '@apollo/client';
import {
    COACH_CATEGORIES_QUERY,
    CoachCategoriesResponseT,
} from 'shared/graphql/query/coachCategory/CoachCategoryQuery';

import { CoachCategoriesDataT } from './CoachCategoriesData';

const useCoachCategories = (): CoachCategoriesDataT => {
    const [fetch, { data: coachCategoriesData, loading }] = useLazyQuery<CoachCategoriesResponseT>(
        COACH_CATEGORIES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetchCoachCategories = async (): Promise<void> => {
        try {
            await fetch();
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchCoachCategories,
        loading,
        data: coachCategoriesData?.coachCategories || [],
    };
};

export default useCoachCategories;
