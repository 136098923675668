import { useEffect } from 'react';
import { useStore } from 'store';

const usePageTitle = (title: string) => {
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(title);
    }, []);
};

export default usePageTitle;
