import {
    ERROR_LOGIN_COMMON,
    ERROR_LOGIN_INCORRECT_USERNAME_OR_PASSWORD,
} from 'constants/notificationMessage';

import LoginStatus from 'enums/LoginStatus';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import LoginResultT from 'types/LoginResult';

const MESSAGE_TO_ERROR_MESSAGE_MAP: Record<string, string> = {
    'Username or password incorrect!': ERROR_LOGIN_INCORRECT_USERNAME_OR_PASSWORD,
};

const DTO = t.string;

type LoginErrorDTOT = t.TypeOf<typeof DTO>;

export const LoginErrorDTO = tPromise.extendType<LoginErrorDTOT, LoginResultT>(
    DTO,
    (message) => ({
        userId: '',
        status: LoginStatus.Fail,
        message: MESSAGE_TO_ERROR_MESSAGE_MAP[message || ''] ?? message ?? ERROR_LOGIN_COMMON,
    }),
    ({ message }) => message,
) as t.Type<LoginResultT, LoginErrorDTOT>;
