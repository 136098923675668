import { makeAutoObservable } from 'mobx';
import { CourtVideoAnalyticsT } from 'types/CourtVideoAnalytics';

export default class CourtVideoAnalyticsStore {
    public courtsVideoAnalytics: CourtVideoAnalyticsT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get courtVideoAnalytics(): CourtVideoAnalyticsT[] {
        return this.courtsVideoAnalytics;
    }

    public setCourtVideoAnalytics(courtVideoAnalytics: CourtVideoAnalyticsT[]) {
        this.courtsVideoAnalytics = courtVideoAnalytics;
    }
}
