import React from 'react';
import { Navigate } from 'react-router-dom';
import { useCurrentUser } from 'shared/data-hook/user';

import { Spinner } from '../Spinner';

const LoginGuard: React.FC<{ children: React.ReactElement<any, any> | null }> = ({ children }) => {
    const { user } = useCurrentUser();

    if (user === undefined) {
        return <Spinner />;
    }

    if (user) {
        return <Navigate to="/" replace={ true } />;
    }

    return children;
};

export default LoginGuard;
