import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { COURT_TYPE_SETTING_HEADER_DATA } from 'page/Court/domain/HeaderData';
import DataGrid from 'shared/component/DataGrid';
import useDeleteMultipleCourtType from 'shared/hook/courtType/delete/useDeleteMultipleCourtType';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { COURT_HEADER_DATA } from './CourtSetting/domain/HeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const CourtTypeSettingList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { courtTypes, count } = useStore('courtType');
    const { deleteMultipleCourtType } = useDeleteMultipleCourtType();

    return (
        <DataGrid
            rows={ courtTypes }
            rowCount={ count }
            columns={ COURT_TYPE_SETTING_HEADER_DATA }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            detailPanelColumns={ COURT_HEADER_DATA }
            multipleDeleteAction={ deleteMultipleCourtType }
        />
    );
});

export default CourtTypeSettingList;
