import { makeAutoObservable } from 'mobx';
import { PriceMemberCardT } from 'types/PriceMemberCard';

export default class UpdatePriceMemberCardModal {
    public updatedItemId: string = '';

    public isOpen: boolean = false;

    public priceMemberCard: PriceMemberCardT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedItemId: string) {
        this.updatedItemId = updatedItemId;
        this.isOpen = true;
    }

    public onClose() {
        this.updatedItemId = '';
        this.isOpen = false;
    }

    public setPriceMemberCard(priceMemberCard: PriceMemberCardT) {
        this.priceMemberCard = priceMemberCard;
    }
}
