export type ClientT = {
    id: string;
} & ClientFullNameT;

export const EMPTY_CLIENTS_RESULT = {
    clients: [],
};

export type ClientFullNameT = {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
};
