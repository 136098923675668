import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { CourtCategoriesT } from 'types/CourtCategory';

import { CourtCategoryDTO } from './CourtCategoryDTO';

const DTO = t.type({
    courtCategories: t.array(CourtCategoryDTO),
    courtCategoriesCount: AggregateDTO,
});

type CourtCategoriesDTOT = t.TypeOf<typeof DTO>;

export const CourtCategoriesDTO = tPromise.extendDecoder<CourtCategoriesDTOT, CourtCategoriesT>(
    DTO,
    ({
        courtCategories,
        courtCategoriesCount,
    }) => ({
        courtCategories,
        courtCategoriesCount,
    }),
) as t.Type<CourtCategoriesT, CourtCategoriesDTOT>;
