import { PAGE_TITLE_MEMBER_CARD_TYPE } from 'constants/pageTitles';
import { MEMBER_CARD_TYPE } from 'constants/routes';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Box, Button, Stack, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import useCreateMemberCardType from 'shared/hook/memberCardType/create/useCreateMemberCardType';
import { useStore } from 'store';

import {
    CREATE_MEMBER_CARD_TYPE_DEFAULT_VALUES,
    MEMBER_CARD_TYPE_VALIDATION_SCHEMA,
    MemberCardTypeFormFields,
} from './components/formData';
import MemberCardTypeForm from './components/MemberCardTypeForm';

const Create = observer(() => {
    const { createMemberCardType, loading: createMemberCardTypeLoading } = useCreateMemberCardType();
    const { setPageTitle } = useStore('pageTitleStore');

    useEffect(() => {
        setPageTitle(PAGE_TITLE_MEMBER_CARD_TYPE);
    }, []);

    const { control, handleSubmit } = useForm<MemberCardTypeFormFields>({
        defaultValues: CREATE_MEMBER_CARD_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(MEMBER_CARD_TYPE_VALIDATION_SCHEMA),
    });

    const handleCreate = (data: MemberCardTypeFormFields) => {
        createMemberCardType(data).then();
    };

    return (
        <Box p={ 3 }>
            <Typography variant="h5">Создание членской карты</Typography>
            <form onSubmit={ handleSubmit(handleCreate) }>
                <MemberCardTypeForm control={ control } />
                <Stack direction="row" spacing={ 1 }>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={ createMemberCardTypeLoading }
                    >
                        Создать
                    </LoadingButton>
                    <Link to={ MEMBER_CARD_TYPE }>
                        <Button
                            variant="outlined"
                        >
                            Отменить
                        </Button>
                    </Link>
                </Stack>
            </form>
        </Box>
    );
});

export default Create;
