import { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { COLUMNS_GROUPING_COACH_HOURS } from '../domain/HeaderData';
import { prepareCoachHoursHeaderData } from '../domain/prepareCoachHoursHeaderData';
import { preparePinnedRow } from '../domain/preparePinnedRow';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const CoachHoursList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, setPageSize, setFilterModel, setPage, setSortModel, loading,
    } = props;

    const { implementations, count } = useStore('implementation');
    const { coachHours } = useStore('coachHours');
    const { getFilterOption } = useStore('filterOptions');
    const priceTypeFilterOptions = getFilterOption('priceType');
    const columns = prepareCoachHoursHeaderData(priceTypeFilterOptions);

    return (
        <DataGrid
            rows={ implementations }
            pinnedRows={ preparePinnedRow(coachHours) }
            rowCount={ count }
            columns={ columns }
            columnGroupingModel={ COLUMNS_GROUPING_COACH_HOURS }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
        />
    );
});

export default CoachHoursList;
