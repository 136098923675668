import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { ImplementationDocumentsT } from 'types/ImplementationDocument';

import { ImplementationDocumentDTO } from './ImplementationDocumentDTO';

const DTO = t.type({
    implementationDocuments: t.array(ImplementationDocumentDTO),
    implementationDocumentsCount: AggregateDTO,
});

type ImplementationDocumentsDTOT = t.TypeOf<typeof DTO>;

export const ImplementationDocumentsDTO = tPromise.extendDecoder<ImplementationDocumentsDTOT, ImplementationDocumentsT>(
    DTO,
    ({
        implementationDocuments,
        implementationDocumentsCount,
    }) => ({
        implementationDocuments,
        implementationDocumentsCount,
    }),
) as t.Type<ImplementationDocumentsT, ImplementationDocumentsDTOT>;
