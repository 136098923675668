import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    MEMBER_CARD_TYPES_QUERY,
    MemberCardTypesResponseT,
} from 'shared/graphql/query/memberCardType/MemberCardTypeQuery';
import { FetchDataParams } from 'types/DataGrid';
import { QueryVarsT } from 'types/Graphql';
import { EMPTY_MEMBER_CARD_TYPES_RESULT } from 'types/MemberCardType';

import { filterData } from '../../../utils/filter/filterData';
import { MemberCardTypesDTO } from '../DTO/MemberCardTypesDTO';

import { MemberCardTypesDataT } from './MemberCardTypesData';

const useMemberCardTypes = (): MemberCardTypesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<MemberCardTypesResponseT, QueryVarsT>(
        MEMBER_CARD_TYPES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedMemberCardTypeData = decodeDTO(
        MemberCardTypesDTO,
        EMPTY_MEMBER_CARD_TYPES_RESULT,
        (memberCardTypes) => memberCardTypes,
        {
            memberCardTypes: data?.memberCardTypes,
            memberCardTypesCount: data?.memberCardTypesCount,
        },
    );

    const fetchMemberCardTypes = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        data: decodedMemberCardTypeData.memberCardTypes,
        count: decodedMemberCardTypeData.memberCardTypesCount,
        fetchMemberCardTypes,
        loading,
    };
};

export default useMemberCardTypes;
