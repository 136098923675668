import { Controller, FieldValues } from 'react-hook-form';
import {
    FormControlLabel, Skeleton, Switch as BaseSwitch,
} from '@mui/material';

type PropsT = {
    label?: string;
};

const Switch = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, loading,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field }) => (
                loading ? <Skeleton variant="rounded" width="100%" height={ 38 } />
                    : <FormControlLabel { ...field } control={ <BaseSwitch checked={ field.value } /> } label={ label } />
            ) }
        />
    );
};

export default Switch;
