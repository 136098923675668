import { COLOR_DELETE_OPERATION } from 'constants/dataGrid';
import { PROPERTY_ACTIONS } from 'constants/propertyNames';

import * as React from 'react';
import { FC, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { GridSelectionModel } from '@mui/x-data-grid-premium';
import { CountForm } from 'shared/utils/CountForm';

import ConfirmModal from '../ConfirmModal';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={ 0 }
        anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'right',
        } }
        transformOrigin={ {
            vertical: 'top',
            horizontal: 'right',
        } }
        { ...props }
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

type PropsT = {
    disabled: boolean;
    deleteAction?: Function;
    refreshAction?: Function;
    selectedRows: GridSelectionModel;
}

const ActionsMenu: FC<PropsT> = (props) => {
    const {
        disabled, deleteAction, refreshAction, selectedRows,
    } = props;
    const [isOpenConfirmMenu, setOpenConfirmMenu] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteClick = () => {
        if (!deleteAction) {
            return;
        }

        deleteAction(selectedRows).then();
        handleClose();
        setOpenConfirmMenu(false);
    };

    const handleRefreshClick = () => {
        if (!refreshAction) {
            return;
        }

        refreshAction(selectedRows);
    };

    return (
        <>
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={ open ? 'demo-customized-menu' : undefined }
                    aria-haspopup="true"
                    aria-expanded={ open ? 'true' : undefined }
                    variant="contained"
                    disableElevation
                    disabled={ disabled }
                    onClick={ handleClick }
                    endIcon={ <KeyboardArrowDownIcon /> }
                    size="small"
                >
                    { PROPERTY_ACTIONS }
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={ {
                        'aria-labelledby': 'demo-customized-button',
                    } }
                    anchorEl={ anchorEl }
                    open={ open }
                    onClose={ handleClose }
                >
                    <MenuItem onClick={ () => setOpenConfirmMenu(true) }>
                        <DeleteIcon color="error" />
                        Удалить
                    </MenuItem>
                    { refreshAction &&
                        (
                            <MenuItem onClick={ handleRefreshClick }>
                                <RefreshIcon color="primary" />
                                Обновить
                            </MenuItem>
                        ) }
                    <MenuItem sx={ { display: 'none' } } onClick={ handleClose } disableRipple>
                        <FileCopyIcon color="info" />
                        Дублировать
                    </MenuItem>
                </StyledMenu>
            </div>
            <ConfirmModal
                isOpen={ isOpenConfirmMenu }
                title="Управление прайсами"
                operationType={ COLOR_DELETE_OPERATION }
                message={ `Вы действительно хотите удалить ${selectedRows.length} ${CountForm(selectedRows.length, ['строку', 'строки', 'строк'])}?` }
                onClose={ () => setOpenConfirmMenu(false) }
                submitForm={ handleDeleteClick }
            />
        </>
    );
};

export default ActionsMenu;
