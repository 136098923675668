import { PROPERTY_SERVICE_APP, PROPERTY_TITLE, PROPERTY_WORK_TIME } from 'constants/propertyNames';

import {
    array, number, object, string,
} from 'yup';

export type OrganizationFormFields = {
    name: string;
    serviceApp: {
        id: string;
        label: string;
    } | '';
    workTime: Array<{
        day: number;
        start: string;
        end: string;
    }>;
}

export enum ORGANIZATION_FIELD_KEYS {
    name = 'name',
    serviceApp = 'serviceApp',
    workTime = 'workTime',
    day = 'day',
    start = 'start',
    end = 'end',
}

export const ORGANIZATION_DEFAULT_VALUES: OrganizationFormFields = {
    name: '',
    serviceApp: '',
    workTime: [
        {
            day: 1,
            start: '',
            end: '',
        },
        {
            day: 2,
            start: '',
            end: '',
        },
        {
            day: 3,
            start: '',
            end: '',
        },
        {
            day: 4,
            start: '',
            end: '',
        },
        {
            day: 5,
            start: '',
            end: '',
        },
        {
            day: 6,
            start: '',
            end: '',
        },
        {
            day: 7,
            start: '',
            end: '',
        },
    ],
};

type OrganizationLabelData = Record<keyof OrganizationFormFields, string>;

export const ORGANIZATION_LABEL_DATA: OrganizationLabelData = {
    name: PROPERTY_TITLE,
    serviceApp: PROPERTY_SERVICE_APP,
    workTime: PROPERTY_WORK_TIME,
};

export const ORGANIZATION_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
    serviceApp: string().required(),
    workTime: array().of(
        object().shape({ day: number().required(), start: string().required(), end: string().required() }),
    ),
}).required();
