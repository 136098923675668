import { ControllerFieldState } from 'react-hook-form';
import { COMMON_FORM_ERRORS } from 'config/FormErrors';
import { FormErrorMessages } from 'enums/FormErrorMessages';

type ParamsT = {
    min?: number;
}

export const getErrorMessage = (fieldState: ControllerFieldState, params: ParamsT = {}) => {
    const minFn = COMMON_FORM_ERRORS[FormErrorMessages.MinSelected] as (param: number) => string;

    return (fieldState.error?.type === FormErrorMessages.Required && `${COMMON_FORM_ERRORS[FormErrorMessages.Required]}`)
        || (fieldState.error?.type === FormErrorMessages.TypeError && `${COMMON_FORM_ERRORS[FormErrorMessages.TypeError]}`)
        || (fieldState.error?.type === FormErrorMessages.MinSelected && minFn(params?.min ?? 1));
};
