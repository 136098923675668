import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CourtDTO } from 'shared/data-hook/court/fetch/DTO/CourtDTO';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { CourtsT } from 'types/Court';

const DTO = t.type({
    courts: t.array(CourtDTO),
    courtsCount: AggregateDTO,
});

type CourtDTOT = t.TypeOf<typeof DTO>;

export const CourtsDTO = tPromise.extendDecoder<CourtDTOT, CourtsT>(
    DTO,
    ({
        courts,
        courtsCount,
    }) => ({
        courts,
        courtsCount,
    }),
) as t.Type<CourtsT, CourtDTOT>;
