import { makeAutoObservable } from 'mobx';
import { PaymentMethodT } from 'types/PaymentMethod';

export default class PaymentMethodStore {
    public paymentMethods: PaymentMethodT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPaymentMethods(paymentMethods: PaymentMethodT[], count?: number) {
        this.paymentMethods = paymentMethods;
        this.count = count ?? 0;
    }
}
