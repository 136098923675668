import { useMutation } from '@apollo/client';
import {
    ORGANIZATION_LOCATION_DELETE, OrganizationLocationDeleteResponseDataT, OrganizationLocationDeleteVarT,
} from 'shared/graphql/mutation/organizationLocation/OrganizationLocationDelete';

import { OrganizationLocationDeleteDataT } from './OrganizationLocationDeleteData';

const useOrganizationLocationDelete = (): OrganizationLocationDeleteDataT => {
    const [organizationLocationDelete, { loading, error }] = useMutation<OrganizationLocationDeleteResponseDataT, OrganizationLocationDeleteVarT>(ORGANIZATION_LOCATION_DELETE);

    const deleteAction = async (organizationLocationId: string) => {
        try {
            const { data } = await organizationLocationDelete({
                variables: {
                    id: organizationLocationId,
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error('useOrganizationLocationDelete', errorCreate);
            throw errorCreate;
        }
    };

    return {
        deleteOrganizationLocation: deleteAction,
        loading,
        error,
    };
};

export default useOrganizationLocationDelete;
