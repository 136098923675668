const generateHours = (
    hour: number,
    minute: number,
    step: 15 | 30,
) => {
    const hours = [];

    for (let i = 0; i <= hour; i++) {
        const startMinutes = i === 0 ? 0 : minute;

        for (let j = startMinutes; j < (minute === 0 ? 60 : minute + step); j += step) {
            if (i === hour && j > minute) break;

            const time = `${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`;

            hours.push({ value: time, label: time });
        }
    }

    return hours;
};

export const TIME_24_HOURS_15_STEP = generateHours(24, 0, 15);
export const TIME_24_HOURS_30_STEP = generateHours(24, 0, 30);
export const TIME_23_HOURS_30_MINUTE_15_STEP = generateHours(23, 30, 15);
export const TIME_23_HOURS_30_MINUTE_30_STEP = generateHours(23, 30, 30);
