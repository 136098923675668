import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { ORGANIZATIONS_QUERY, OrganizationsResponseT } from 'shared/graphql/query/organization/OrganizationQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_ORGANIZATIONS_RESULT } from 'types/Organization';

import { OrganizationsDTO } from '../DTO/OrganizationsDTO';

import { OrganizationsDataT } from './OrganizationsData';

const useOrganizations = (): OrganizationsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<OrganizationsResponseT>(
        ORGANIZATIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedOrganizationData = decodeDTO(
        OrganizationsDTO,
        EMPTY_ORGANIZATIONS_RESULT,
        (organizations) => organizations,
        {
            organizations: data?.organizations,
            organizationsCount: data?.organizationsCount,
        },
    );

    const fetchOrganizations = async ({
        limit, offset, orderBy = {}, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchOrganizations,
        loading,
        data: decodedOrganizationData.organizations,
        count: decodedOrganizationData.organizationsCount,
    };
};

export default useOrganizations;
