import { PriceRateT } from './PriceRate';

export type PriceT = {
    id: string;
    day: number;
    timeStart: string;
    timeEnd: string;
    courtType: string;
    category: string;
    coachCategory: string | null;
    groupView: string | null;
    priceRates: PriceRateT[];
};

export type PricesT = {
    prices: PriceT[];
    pricesCount: number;
};

export type PriceTimePeriodT = {
    timeStart: string;
    timeEnd: string;
};

export type PriceTimePeriodFilterT = {
    priceTypeId: string;
    priceSeasonId: string;
    days: number[];
    courtTypeIds: string[];
    serviceCategoryIds: string[];
    coachCategoryIds: string[];
    groupViewIds: string[];
};

export const EMPTY_PRICES_RESULT: PricesT = {
    prices: [],
    pricesCount: 0,
};
