import {
    PROPERTY_ACTIONS,
    PROPERTY_SERVICE_APP,
    PROPERTY_TITLE, PROPERTY_WORK_TIME,
} from 'constants/propertyNames';

import React, { FC, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { EmptyCell } from 'shared/component/DataGrid/Cell/EmptyCell';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteOrganization from 'shared/hook/organization/delete/useDeleteOrganization';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import { getDayByNumber } from 'shared/utils/headerData/getDayByNumber';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';
import { WorkTime } from 'types/Organization';

const renderWorkTime: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { workTime, id } = row;

    if (workTime.length === 0) {
        return <EmptyCell />;
    }

    const renderWorkTimeItem = useMemo(() => workTime.map((time: WorkTime) => (
        <Chip
            label={ `${getDayByNumber(time.day)} | ${time.start} - ${time.end}` }
            variant="outlined"
            color="primary"
            size="small"
            key={ `${id}-${time.day}` }
        />
    )), [workTime]);

    return (
        <FlexWrapBox>
            { renderWorkTimeItem }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, name } } = props;
    const { organizationDelete } = useDeleteOrganization();
    const { onOpen } = useStore('updateOrganizationModal');

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ name } actionDelete={ organizationDelete } />
        </>
    );
};

export const ORGANIZATION_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 0.6,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_SERVICE_APP,
        field: 'serviceApp',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['service_app', 'id'],
        hasuraSortFields: ['service_app', 'label'],
        renderCell: (({ row }) => row.serviceApp.label),
    },
    {
        headerName: PROPERTY_WORK_TIME,
        field: 'workTime',
        flex: 2.5,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['work_time', '_cast', 'String'],
        filterOperators,
        renderCell: renderWorkTime,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: RenderActionCell,
    },
];
