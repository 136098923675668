import { useMemo } from 'react';
import { GridColumnGroupingModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Dayjs } from 'dayjs';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

import { COURT_VIDEO_ANALYTICS_HEADER_DATA } from './HeaderData';
import { prepareHeaderData } from './prepareHeaderData';
import { prepareNotifications } from './prepareNotifications';
import prepareTimeIntervals from './prepareTimeIntervals';

type PreparedDataGridT = {
    columns: CustomHeaderData[];
    columnGroupingModel: GridColumnGroupingModel;
    rows: GridValidRowModel[];
}

export const prepareDataGrid = (date: Dayjs): PreparedDataGridT => {
    const { courts } = useStore('court');
    const { organizations } = useStore('organization');
    const { courtsVideoAnalytics } = useStore('courtVideoAnalytics');

    const { headerData, columnGroupingModel } = useMemo(() => prepareHeaderData(courts), [courts]);

    const preparedTimeIntervals = useMemo(() => prepareTimeIntervals(organizations, date), [organizations, date]);
    const preparedNotifications = useMemo(() => prepareNotifications(preparedTimeIntervals, courtsVideoAnalytics), [courtsVideoAnalytics, preparedTimeIntervals, date]);

    return {
        columns: [...COURT_VIDEO_ANALYTICS_HEADER_DATA, ...headerData],
        columnGroupingModel,
        rows: preparedNotifications,
    };
};
