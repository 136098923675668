import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import {
    DELETE_MULTIPLE_MEMBER_CARD_TYPE,
    DeleteMultipleMemberCardTypeResponseDataT,
    DeleteMultipleMemberCardTypeResponseT,
    DeleteMultipleMemberCardTypeVarT,
} from 'shared/graphql/mutation/memberCardType/MemberCardTypeMultipleDelete';
import { MemberCardTypeT } from 'types/MemberCardType';

import { MemberCardTypeMultipleDeleteDataT } from './MemberCardTypeMultipleDeleteData';

const useMemberCardTypeMultipleDelete = (): MemberCardTypeMultipleDeleteDataT => {
    const [deleteMemberCardTypes, { loading, error }] = useMutation<
        DeleteMultipleMemberCardTypeResponseT,
        DeleteMultipleMemberCardTypeVarT
    >(DELETE_MULTIPLE_MEMBER_CARD_TYPE);

    const deleteAction = async (priceSeasonIds: [MemberCardTypeT['id']]) => {
        try {
            const { data } = await deleteMemberCardTypes({
                variables: {
                    ids: priceSeasonIds,
                },
            });

            return camelizeKeys(data?.deleteMultipleMemberCardType) as DeleteMultipleMemberCardTypeResponseDataT;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteMultipleMemberCardType: deleteAction,
        loading,
        error,
    };
};

export default useMemberCardTypeMultipleDelete;
