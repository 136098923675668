import React, { ReactElement } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import {
    Box, InputAdornment, InputLabel, Skeleton, TextField as BaseTextField,
} from '@mui/material';

import { getErrorMessage } from '../../utils/getErrorMessage';
import AuxiliaryPopover from '../AuxiliaryPopover';

type PropsT = {
    label?: string | ReactElement;
    loading?: boolean;
    size?: 'small' | 'medium';
    dateRangeProps?: any;
    customError?: boolean;
    inputAdornment?: string | ReactElement;
    withoutError?: boolean;
    auxiliaryPopoverText?: string;
};

const TextField = <TFieldValues extends FieldValues>(props: PropsT & TFieldValues) => {
    const {
        control, name, label, type, loading, size = 'medium', dateRangeProps, customError, inputAdornment, withoutError, auxiliaryPopoverText,
    } = props;

    return (
        <Controller
            name={ name }
            control={ control }
            render={ ({ field, fieldState }) => (
                <Box>
                    { label && (
                        <InputLabel sx={ {
                            display: 'flex',
                            alignItems: 'center',
                        } }
                        >
                            <Box
                                sx={ auxiliaryPopoverText ? { marginRight: '4px' } : {} }
                            >
                                { label }
                            </Box>
                            {
                                auxiliaryPopoverText && (<AuxiliaryPopover text={ auxiliaryPopoverText } />)
                            }
                        </InputLabel>
                    ) }
                    { loading ? <Skeleton variant="rounded" height={ size ? 40 : 56 } />
                        : (
                            <BaseTextField
                                { ...dateRangeProps }
                                { ...field }
                                type={ type }
                                error={ !!fieldState.error }
                                InputProps={ inputAdornment && {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            { inputAdornment }
                                        </InputAdornment>
                                    ),
                                } }
                                size={ size }
                                helperText={
                                    withoutError ? null : (customError && fieldState.error?.message)
                                    || getErrorMessage(fieldState)
                                }
                                fullWidth
                            />
                        ) }
                </Box>
            ) }
        />
    );
};

export default TextField;
