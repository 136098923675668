import { HasuraOperatorT } from 'constants/gqlOperators';

import { buildNestedObject } from '../../../utils/buildNestedObject';

enum booleanGridOperators {
    is = 'is',
}

type booleanGridOperatorsT = keyof typeof booleanGridOperators

export const filterBooleanField = (filter: any, value: string, gridOperator: booleanGridOperatorsT, hasuraOperator: HasuraOperatorT, hasuraFilterFields: string[], field: string) => {
    const updatedFilter = { ...filter };
    let filterValue = {};

    if (!value) {
        return;
    }

    switch (gridOperator) {
        case booleanGridOperators.is:
            filterValue = { [field]: { _eq: value } };
            break;
    }

    if (!updatedFilter[hasuraOperator][hasuraOperator]) {
        updatedFilter[hasuraOperator][hasuraOperator] = [];
    }

    updatedFilter[hasuraOperator][hasuraOperator].push(buildNestedObject(hasuraFilterFields, { ...filterValue }));
};
