import { useMutation } from '@apollo/client';
import { camelizeKeys } from 'humps';
import { CourtTypeMultipleDeleteDataT } from 'shared/data-hook/courtType/delete/multiple/CourtTypeMultipleDeleteData';
import {
    DELETE_MULTIPLE_COURT_TYPE, DeleteMultipleCourtTypeResponseDataT,
    DeleteMultipleCourtTypeResponseT,
    DeleteMultipleCourtTypeVarT,
} from 'shared/graphql/mutation/courtType/CourtTypeMultipleDelete';
import { CourtTypeT } from 'types/CourtType';

const useCourtTypeMultipleDelete = (): CourtTypeMultipleDeleteDataT => {
    const [deleteCourtTypes, { loading, error }] = useMutation<
        DeleteMultipleCourtTypeResponseT,
        DeleteMultipleCourtTypeVarT
    >(DELETE_MULTIPLE_COURT_TYPE);

    const deleteAction = async (courtTypeIds: [CourtTypeT['id']]) => {
        try {
            const { data } = await deleteCourtTypes({
                variables: {
                    ids: courtTypeIds,
                },
            });

            return camelizeKeys(data?.deleteMultipleCourtType) as DeleteMultipleCourtTypeResponseDataT;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteMultipleCourtType: deleteAction,
        loading,
        error,
    };
};

export default useCourtTypeMultipleDelete;
