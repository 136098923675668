import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { UserSubscriptionContext } from 'contexts/UserSubscriptionContext';
import LogoutResult from 'enums/LogoutResult';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { LOGIN, LoginData, LoginVar } from 'shared/graphql/mutation/user/Login';
import { LOGOUT, LogoutResultT } from 'shared/graphql/mutation/user/Logout';
import LoginResultT, { EMPTY_LOGIN_RESULT } from 'types/LoginResult';

import { LoginErrorDTO } from './DTO/login/LoginErrorDTO';
import { LoginResultDTO } from './DTO/login/LoginResultDTO';
import { LogoutResultDTO } from './DTO/LogoutResultDTO';
import { normalizer } from './Normalizer';
import { UserHookType } from './Type';

export const useCurrentUser = (): UserHookType => {
    const { user, loadingSubscription, errorSubscription } = useContext(UserSubscriptionContext);

    const [login, { loading: loadingLogin, error: errorLogin }] = useMutation<LoginData, LoginVar>(
        LOGIN,
    );
    const [logout, { loading: loadingLogout, error: errorLogout }] =
        useMutation<LogoutResultT>(LOGOUT);

    const loginAction = async (username: string, password: string): Promise<LoginResultT> => {
        const variables: LoginVar = {
            username,
            password,
        };

        try {
            const { data } = await login({ variables });

            return decodeDTO(
                LoginResultDTO,
                EMPTY_LOGIN_RESULT,
                (result) => result,
                data?.adminLogin,
            );
        } catch (error) {
            return decodeDTO(
                LoginErrorDTO,
                EMPTY_LOGIN_RESULT,
                (result) => result,
                (error as Error).message,
            );
        }
    };

    const logoutAction = async (): Promise<LogoutResult> => {
        try {
            const { data } = await logout();

            return decodeDTO(
                LogoutResultDTO,
                LogoutResult.Fail,
                (logoutResult) => logoutResult,
                data?.logout.result,
            );
        } catch {
            return LogoutResult.Fail;
        }
    };

    return {
        user: user ? normalizer(user) : user,
        loading: loadingSubscription || loadingLogin || loadingLogout || false,
        error: errorSubscription || errorLogin || errorLogout || null,
        login: loginAction,
        logout: logoutAction,
    };
};
