import { makeAutoObservable } from 'mobx';

export default class CreateOrganizationLocationModal {
    public isOpen: boolean = false;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }
}
