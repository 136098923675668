import { gql } from '@apollo/client';
import { MemberCardTypeT } from 'types/MemberCardType';

export type DeleteMultipleMemberCardTypeResponseT = {
    deleteMultipleMemberCardType: DeleteMultipleMemberCardTypeResponseDataT;
};

export type DeleteMultipleMemberCardTypeResponseDataT = {
    affectedRows: number;
}

export type DeleteMultipleMemberCardTypeVarT = {
    ids: [MemberCardTypeT['id']];
};

export const DELETE_MULTIPLE_MEMBER_CARD_TYPE = gql`
    mutation DeleteMultipleMemberCardType($ids: [String!] = []) {
        deleteMultipleMemberCardType: delete_member_card_type(where: {id: {_in: $ids}}) {
            affected_rows
        }
    }
`;
