import { useEffect } from 'react';
import useFetchPersonalManagerFilterOptionsData
    from 'shared/data-hook/filterOptions/useFetchPersonalManagerFilterOptionsData';
import { useStore } from 'store';

const useFetchPersonalManagerFilterOptions = () => {
    const { loading, data: personalManagerData } = useFetchPersonalManagerFilterOptionsData();
    const { setFilterOption } = useStore('filterOptions');

    useEffect(() => {
        if (!loading) {
            setFilterOption('personalManager', personalManagerData);
        }
    }, [loading]);

    return { loading };
};

export default useFetchPersonalManagerFilterOptions;
