import { CourtT } from './Court';

export type CourtTypePeriodT = {
    start: string;
    end: string;
};

export type CourtTypeT = {
    id: string;
    name: string;
    duration: string;
    useHalves: boolean;
    courts: CourtT[];
    periods: CourtTypePeriodT[];
};

export type CourtTypesT = {
    courtTypes: CourtTypeT[];
    courtTypesCount: number;
};

export const EMPTY_COURT_TYPES_RESULT = {
    courtTypes: [],
    courtTypesCount: 0,
};

export const EMPTY_COURT_TYPE_RESULT = {
    id: '',
    name: '',
    duration: '',
    useHalves: false,
    periods: [],
    courts: [],
};
