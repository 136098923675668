export type CourtT = {
    id: string;
    name: string;
    order: number;
    bonusClasses: boolean;
    schedule: CourtScheduleItem[];
    organizationLocationId: {
        id: string;
        name: string;
    };
    organizationId: {
        id: string;
        name: string;
    } | null;
    courtTypeId: {
        id: string;
        name: string;
    };
    cameraId: string | null;
};

export type CourtsT = {
    courts: CourtT[];
    courtsCount: number;
};

export type CourtScheduleItem = {
    day: number;
    start: string;
    end: string;
};

export const EMPTY_COURTS_RESULT = {
    courts: [],
    courtsCount: 0,
};

export const EMPTY_COURT_RESULT = {
    id: '',
    name: '',
    order: 0,
    bonusClasses: false,
    schedule: [],
    organizationLocationId: {
        id: '',
        name: '',
    },
    organizationId: {
        id: '',
        name: '',
    },
    courtTypeId: {
        id: '',
        name: '',
    },
    cameraId: '',
};
