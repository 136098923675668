import {
    ERROR_MEMBER_CARD_TYPE_DELETE,
    SUCCESS_MEMBER_CARD_TYPE_DELETE,
} from 'constants/notificationMessage';

import useMemberCardTypeDelete from 'shared/data-hook/memberCardType/delete/single/useMemberCardTypeDelete';
import { useStore } from 'store';
import { MemberCardTypeT } from 'types/MemberCardType';

import { useNotification } from '../../useNotification';

const useDeleteMemberCardType = () => {
    const { showError, showSuccess } = useNotification();
    const { error, deleteMemberCardType } = useMemberCardTypeDelete();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const memberCardTypeDelete = async (memberCardTypeId: MemberCardTypeT['id']) => {
        const response = await deleteMemberCardType(memberCardTypeId);

        if (error || !response || !response.deleteMemberCardType) {
            showError(ERROR_MEMBER_CARD_TYPE_DELETE);

            return;
        }

        toggleRefetchTrigger();
        showSuccess(SUCCESS_MEMBER_CARD_TYPE_DELETE);
    };

    return {
        memberCardTypeDelete,
    };
};

export default useDeleteMemberCardType;
