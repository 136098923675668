import { useNavigate } from 'react-router-dom';
import LogoutResult from 'enums/LogoutResult';
import { useCurrentUser } from 'shared/data-hook/user';
import logoutAction from 'shared/helper/logout/logoutAction';

import { useNotification } from './useNotification';

const useLogout = () => {
    const { logout } = useCurrentUser();
    const { showError } = useNotification();
    const navigate = useNavigate();

    return async (): Promise<void> => {
        const logoutResult = await logout();

        if (logoutResult === LogoutResult.Success) {
            logoutAction();
        }

        if (logoutResult === LogoutResult.Fail) {
            navigate(-1);
            showError('Что-то пошло не так!');
        }
    };
};

export default useLogout;
