import { PAYMENT_DATA_GRID_INITIAL_STATE } from 'constants/dataGrid';

import { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { preparePaymentHeaderData } from '../domain/preparePaymentHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const PaymentList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { payments, count } = useStore('payment');
    const { getFilterOption } = useStore('filterOptions');
    const paymentTypeFilterOptions = getFilterOption('paymentType');
    const paymentMethodFilterOptions = getFilterOption('paymentMethod');
    const organizationLocationFilterOptions = getFilterOption('organizationLocation');
    const columns = preparePaymentHeaderData(organizationLocationFilterOptions, paymentTypeFilterOptions, paymentMethodFilterOptions);

    return (
        <DataGrid
            rows={ payments }
            rowCount={ count }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
            initialState={ PAYMENT_DATA_GRID_INITIAL_STATE }
        />
    );
});

export default PaymentList;
