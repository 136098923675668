import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { CourtT } from 'types/Court';

const DTO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        order: t.number,
        bonus_classes: t.boolean,
        organization_location: t.type({
            id: t.string,
            name: t.string,
        }),
        organization: t.type({
            id: t.string,
            name: t.string,
        }),
        court_type: t.type({
            id: t.string,
            name: t.string,
        }),
        camera_id: Nullable(t.string),
    }),
    t.partial({
        schedule: t.array(t.type({
            end: t.string,
            start: t.string,
            day: t.number,
        })),
    }),
]);

export type CourtDTOT = t.TypeOf<typeof DTO>;

export const CourtDTO = tPromise.extendDecoder<CourtDTOT, CourtT>(
    DTO,
    ({
        id,
        name,
        order,
        bonus_classes,
        organization_location,
        organization,
        court_type,
        camera_id,
        schedule,
    }) => {
        return {
            id,
            name,
            order,
            organizationLocationId: organization_location,
            courtTypeId: court_type,
            bonusClasses: bonus_classes,
            schedule: schedule ?? [],
            organizationId: organization ?? '',
            cameraId: camera_id ?? '',
        };
    },
) as t.Type<CourtT, CourtDTOT>;
