import { makeAutoObservable } from 'mobx';
import { OrganizationT } from 'types/Organization';

export default class UpdateOrganizationModal {
    public isOpen: boolean = false;

    public updateOrganizationId: string = '';

    public organization: OrganizationT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedOrganizationId: string) {
        this.updateOrganizationId = updatedOrganizationId;
        this.isOpen = true;
    }

    public onClose() {
        this.updateOrganizationId = '';
        this.isOpen = false;
    }

    public setOrganization(organization: OrganizationT) {
        this.organization = organization;
    }
}
