import { EMPTY_CELL } from 'constants/dataGrid';
import {
    PROPERTY_ACTIONS, PROPERTY_COURTS_AREA,
    PROPERTY_POSITION,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteCourtCategory from 'shared/hook/courtCategory/delete/useDeleteCourtCategory';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';
import { OptionT } from 'types/Option';

const renderCourtCourtCategories: FC<GridRenderCellParams> = (props) => {
    const { row: { id, position, courts } } = props;

    if (courts.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <FlexWrapBox key={ id }>
            {
                courts.map((court: OptionT) => (
                    <Chip
                        label={ court.label }
                        variant="outlined"
                        color="primary"
                        size="small"
                        key={ `${id}-${position}-${court.value}` }
                    />
                ))
            }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, name } } = props;
    const { courtCategoryDelete } = useDeleteCourtCategory();
    const { onOpen } = useStore('updateCourtCategoryModal');

    return (
        <>
            <IconButton onClick={ () => onOpen(id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ name } actionDelete={ courtCategoryDelete } />
        </>
    );
};

export const COURT_CATEGORY_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 0.6,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_COURTS_AREA,
        field: 'courts',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['courts', 'value'],
        hasuraSortFields: ['courts', 'count'],
        filterOperators,
        renderCell: renderCourtCourtCategories,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.3,
        align: 'center',
        headerAlign: 'center',
    },
];
