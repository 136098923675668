import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';
import { OrganizationT } from 'types/Organization';

const DTO = t.type({
    id: t.string,
    name: t.string,
    service_app: Nullable(t.type({
        id: t.string,
        label: t.string,
    })),
    work_time: t.array(t.type({
        end: t.string,
        start: t.string,
        day: t.number,
    })),
});

export type OrganizationDTOT = t.TypeOf<typeof DTO>;

export const OrganizationDTO = tPromise.extendDecoder<OrganizationDTOT, OrganizationT>(
    DTO,
    ({
        id, name, service_app, work_time,
    }) => {
        return {
            id,
            name,
            serviceApp: service_app ? {
                id: service_app.id,
                label: service_app.label,
            } : null,
            workTime: work_time,
        };
    },
) as t.Type<OrganizationT, OrganizationDTOT>;
