import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareImplementationDocumentsHeaderData } from '../domain/prepareImplementationDocumentsHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const ImplementationDocumentList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const {
        implementationDocuments, count,
    } = useStore('implementationDocument');
    const { getFilterOption } = useStore('filterOptions');
    const serviceCategoryFilterOptions = getFilterOption('serviceCategory');
    const groupFilterOptions = getFilterOption('group');
    const employeeFilterOptions = getFilterOption('employee');

    const columns = prepareImplementationDocumentsHeaderData(serviceCategoryFilterOptions, groupFilterOptions, employeeFilterOptions);

    return (
        <DataGrid
            rows={ implementationDocuments }
            rowCount={ count }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
        />
    );
});

export default ImplementationDocumentList;
