import { GridValidRowModel } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { setProp } from 'shared/utils/setProp';
import { CourtVideoAnalyticsT } from 'types/CourtVideoAnalytics';

export const prepareNotifications = (rowData: GridValidRowModel[], data: CourtVideoAnalyticsT[]) => {
    data.forEach((item) => {
        rowData.forEach((rowItem) => {
            if (dayjs.unix(rowItem.id).isSame(dayjs(item.datetimeStart)) && !!item.notificationText) {
                Object.assign(rowItem, setProp(`notificationText-${item.court.order}`, item.notificationText));
            }
        });
    });

    return rowData;
};
