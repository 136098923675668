import { ClientT } from '../../../types/Client';

export const parseClientOptions = (clients: ClientT[]) => {
    const notEmptyClients = clients.filter((client) => client.lastName || client.firstName);

    return notEmptyClients.map((client) => ({
        value: client.id,
        label: `${client.lastName} ${client.firstName}${client.middleName ? ` ${client.middleName}` : ''}`,
    }));
};
