import { useEffect } from 'react';
import useCourtType from 'shared/data-hook/courtType/fetch/fetchOne/useCourtType';
import { useStore } from 'store';

const useFetchCourtType = (id: string) => {
    const { fetchCourtType, loading } = useCourtType();
    const { isOpen, setCourtType } = useStore('updateCourtTypeModal');

    useEffect(() => {
        if (isOpen && id) {
            fetchCourtType(id).then((fetchedCourtType) => {
                setCourtType(fetchedCourtType);
            });
        }
    }, [isOpen, id]);

    return {
        loading,
    };
};

export default useFetchCourtType;
