export type CourtVideoAnalyticsT = {
    id: string;
    datetimeStart: Date;
    datetimeEnd: Date;
    notificationText: string | null;
    reason: string | null;
    court: {
        id: string;
        name: string;
        order: number;
    };
};

export type CourtsVideoAnalyticsT = {
    courtsVideoAnalytics: CourtVideoAnalyticsT[];
}

export const EMPTY_COURTS_VIDEO_ANALYTICS: CourtsVideoAnalyticsT = {
    courtsVideoAnalytics: [],
};
