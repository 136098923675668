import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { PAYMENTS_QUERY, PaymentsResponseT } from 'shared/graphql/query/payment/PaymentQuery';
import { FetchDataParams } from 'types/DataGrid';
import { EMPTY_PAYMENTS } from 'types/Payment';

import { PaymentsDTO } from '../DTO/PaymentsDTO';

import { PaymentsDataT } from './PaymentsData';

const usePayments = (): PaymentsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<PaymentsResponseT>(
        PAYMENTS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedPaymentsData = decodeDTO(
        PaymentsDTO,
        EMPTY_PAYMENTS,
        (payments) => payments,
        {
            payments: data?.payments,
            paymentsCount: data?.paymentsCount,
        },
    );

    const fetchPayments = async ({
        limit,
        offset,
        orderBy = {},
        where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error((error as Error).message);
            throw error;
        }
    };

    return {
        fetchPayments,
        loading,
        data: decodedPaymentsData.payments,
        count: decodedPaymentsData.paymentsCount,
    };
};

export default usePayments;
