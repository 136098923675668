import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import SelectWithSearch from 'shared/component/Form/SelectWithSearch';
import { parseSelectOptions } from 'shared/helper/common/parseSelectOptions';
import { useStore } from 'store';

import { parseClientOptions } from '../domain/parseClientOptions';

import { MEMBER_CARD_FIELDS_KEYS, MEMBER_CARD_LABEL_DATA, MemberCardFormFields } from './formData';

type PropsT = {
    control: Control<MemberCardFormFields>;
};

const MemberCardForm: FC<PropsT> = observer((props) => {
    const { control } = props;
    const {
        clients, memberCardTypes, organizations,
    } = useStore('createMemberCardModal');
    const { field: client } = useController({ control, name: MEMBER_CARD_FIELDS_KEYS.clientDataId });

    return (
        <Grid2 container direction="column" spacing={ 1 }>
            <Grid2>
                <SelectWithSearch
                    control={ control }
                    options={ parseClientOptions(clients) }
                    name={ MEMBER_CARD_FIELDS_KEYS.clientDataId as keyof MemberCardFormFields }
                    label={ MEMBER_CARD_LABEL_DATA[MEMBER_CARD_FIELDS_KEYS.clientDataId] }
                    size="small"
                    selectedValue={ client.value }
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ parseSelectOptions(memberCardTypes) }
                    name={ MEMBER_CARD_FIELDS_KEYS.memberCardTypeId as keyof MemberCardFormFields }
                    control={ control }
                    size="small"
                    label={ MEMBER_CARD_LABEL_DATA[MEMBER_CARD_FIELDS_KEYS.memberCardTypeId] }
                />
            </Grid2>
            <Grid2>
                <Select
                    options={ parseSelectOptions(organizations) }
                    name={ MEMBER_CARD_FIELDS_KEYS.organizationId as keyof MemberCardFormFields }
                    control={ control }
                    size="small"
                    label={ MEMBER_CARD_LABEL_DATA[MEMBER_CARD_FIELDS_KEYS.organizationId] }
                />
            </Grid2>
        </Grid2>
    );
});

export default MemberCardForm;
