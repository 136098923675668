import { ERROR_ORGANIZATION_UPDATE, SUCCESS_ORGANIZATION_UPDATE } from 'constants/notificationMessage';

import { OrganizationFormFields } from 'page/Organization/components/formdata';
import useOrganizationUpdate from 'shared/data-hook/organization/update/useOrganizationUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { OrganizationT } from 'types/Organization';

const useUpdateOrganization = () => {
    const { error, loading, organizationUpdate } = useOrganizationUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateOrganization = async (input: OrganizationFormFields, organizationId: OrganizationT['id']) => {
        const response = await organizationUpdate(input, organizationId);

        if (error || !response || !response.updateOrganization) {
            showError(ERROR_ORGANIZATION_UPDATE);
        } else {
            showSuccess(SUCCESS_ORGANIZATION_UPDATE);
        }
        toggleRefetchTrigger();
    };

    return {
        updateOrganization,
        loading,
    };
};

export default useUpdateOrganization;
