import { API_END_OF_DAY_TIME, API_TIME_FORMAT } from 'constants/date';
import { VIDEO_ANALYTICS_TIME_INTERVAL } from 'constants/videoAnalyticsInterval';

import { GridValidRowModel } from '@mui/x-data-grid-premium';
import dayjs, { Dayjs } from 'dayjs';
import { OrganizationT } from 'types/Organization';

const prepareTimeIntervals = (data: OrganizationT[], date: Dayjs): GridValidRowModel[] => {
    const rowData: GridValidRowModel[] = [];

    if (data.length === 0) return rowData;

    const { start, end } = data[0].workTime[date.day()];
    let currentTime = date.add(dayjs(start, API_TIME_FORMAT).hour(), 'h');
    const endTime = end === API_END_OF_DAY_TIME ? date.add(1, 'day') : date.add(dayjs(end, API_TIME_FORMAT).hour(), 'h');
    const nextTime = (time: dayjs.Dayjs) => time.add(VIDEO_ANALYTICS_TIME_INTERVAL, 'm');

    while (currentTime.isBefore(endTime)) {
        rowData.push(
            {
                id: currentTime.unix(),
                timeInterval: `${currentTime.format(API_TIME_FORMAT)} - ${nextTime(currentTime).format(API_TIME_FORMAT)}`,
            },
        );
        currentTime = nextTime(currentTime);
    }

    return rowData;
};

export default prepareTimeIntervals;
