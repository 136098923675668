import { gql } from '@apollo/client';

export type OrganizationLocationCreatePayloadT = {
    name: string;
}

export type OrganizationLocationCreateResponseDataT = {
    createOrganizationLocation: {
        id: string;
    };
} | null | undefined;

export type OrganizationLocationCreateVarT = {
    object: OrganizationLocationCreatePayloadT;
};

export const ORGANIZATION_LOCATION_CREATE = gql`
    mutation OrganizationLocationCreate($object: organization_location_insert_input = {}) {
        createOrganizationLocation: insert_organization_location_one(
            object: $object
        ) {
            id
        }
    }
`;
