import { MODAL_TITLE_UPDATE_COURT_TYPE } from 'constants/modalTitles';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Skeleton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Switch from 'shared/component/Form/Switch';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useFetchCourtType from 'shared/hook/courtType/fetch/fetchOne/useFetchCourtType';
import useCourtTypeUpdate from 'shared/hook/courtType/update/useCourtTypeUpdate';
import { useStore } from 'store';

import {
    COURT_TYPE_DEFAULT_VALUES,
    COURT_TYPE_FIELD_KEYS, COURT_TYPE_LABEL_DATA,
    CourtTypeFormFields,
    getCourtTypeValidationSchema,
} from './formData';

const UpdateCourtTypeSettingModal: FC = observer(() => {
    const { updateCourtType, loading: loadingUpdate } = useCourtTypeUpdate();
    const {
        onClose, id, courtType, isOpen,
    } = useStore('updateCourtTypeModal');
    const { loading: loadingFetch } = useFetchCourtType(id);

    const {
        control, handleSubmit, reset, setValue, formState: { isValid, isDirty },
    } = useForm<CourtTypeFormFields>({
        defaultValues: COURT_TYPE_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getCourtTypeValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleUpdateCourtType = (data: CourtTypeFormFields) => {
        updateCourtType(data, id).then(() => {
            handleCloseModal();
        });
    };

    useEffect(() => {
        if (courtType) {
            Object.keys(courtType).forEach((key: string) => {
                setValue(key as keyof CourtTypeFormFields, courtType[key as keyof CourtTypeFormFields] as never);
            });
        }
    }, [courtType]);

    return (
        <Modal open={ isOpen } fullWidth={ true } maxWidth="lg">
            <ModalTitle onClose={ onClose }>
                { loadingFetch ? (
                    <Skeleton height={ 32 } />
                ) : (
                    <>{ MODAL_TITLE_UPDATE_COURT_TYPE } { courtType?.name }</>
                ) }
            </ModalTitle>
            <DialogContent dividers={ true }>
                { loadingFetch ? <Skeleton /> : (
                    <Grid2 spacing={ 2 } container padding={ 2 }>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COURT_TYPE_FIELD_KEYS.name }
                                key={ `key-courtType-${courtType?.name} ` }
                                label={ COURT_TYPE_LABEL_DATA[COURT_TYPE_FIELD_KEYS.name] }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <TextField
                                control={ control }
                                name={ COURT_TYPE_FIELD_KEYS.duration }
                                label={ COURT_TYPE_LABEL_DATA.duration }
                                type="text"
                            />
                        </Grid2>
                        <Grid2 xs={ 12 }>
                            <Switch
                                control={ control }
                                name={ COURT_TYPE_FIELD_KEYS.useHalves }
                                label={ COURT_TYPE_LABEL_DATA.useHalves }
                                type="boolean"
                            />
                        </Grid2>
                    </Grid2>
                ) }
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    onClick={ handleSubmit(handleUpdateCourtType) }
                    loading={ loadingUpdate }
                    disabled={ !isValid || !isDirty }
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default UpdateCourtTypeSettingModal;
