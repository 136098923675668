export const WEEK_DAYS_VALUE_SHORT_OPTIONS = [
    { value: 1, label: 'ПН' },
    { value: 2, label: 'ВТ' },
    { value: 3, label: 'СР' },
    { value: 4, label: 'ЧТ' },
    { value: 5, label: 'ПТ' },
    { value: 6, label: 'СБ' },
    { value: 7, label: 'ВС' },
];

export const WEEK_DAYS_VALUE_OPTIONS = [
    { value: 1, label: 'Понедельник' },
    { value: 2, label: 'Вторник' },
    { value: 3, label: 'Среда' },
    { value: 4, label: 'Четверг' },
    { value: 5, label: 'Пятница' },
    { value: 6, label: 'Суббота' },
    { value: 7, label: 'Воскресенье' },
];
