import { MODAL_TITLE_CREATE_COURT_CATEGORY } from 'constants/modalTitles';

import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import useCourtCategories from 'shared/data-hook/courtCategory/fetch/fetchAll/useCourtCategories';
import useCourtCategoryCreate from 'shared/hook/courtCategory/create/useCourtCategoryCreate';
import { useStore } from 'store';

import {
    COURT_CATEGORY_DEFAULT_VALUES,
    COURT_CATEGORY_FIELD_KEYS, COURT_CATEGORY_LABEL_DATA,
    CourtCategoryFormFields,
    getCourtCategoryValidationSchema,
} from './formData';

const CreateCourtCategoryModal: FC = observer(() => {
    const { isOpen, onClose, setCourtCategory } = useStore('createCourtCategoryModal');
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { createCourtCategory } = useCourtCategoryCreate();

    const {
        data: courtCategoryData,
        loading: courtLoading,
    } = useCourtCategories();
    const { control, handleSubmit, reset } = useForm<CourtCategoryFormFields>({
        defaultValues: COURT_CATEGORY_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(getCourtCategoryValidationSchema()),
    });

    const handleCloseModal = () => {
        reset();
        onClose();
    };

    const handleCreateCourtCategory = (data: CourtCategoryFormFields) => {
        createCourtCategory(data).then(() => {
            handleCloseModal();
            toggleRefetchTrigger();
        });
    };

    useEffect(() => {
        if (courtCategoryData) {
            setCourtCategory();
        }
    }, [courtCategoryData]);

    return (
        <Modal maxWidth="lg" open={ isOpen }>
            <ModalTitle onClose={ handleCloseModal }>
                { MODAL_TITLE_CREATE_COURT_CATEGORY }
            </ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 container spacing={ 2 } padding={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_CATEGORY_FIELD_KEYS.name }
                            label={ COURT_CATEGORY_LABEL_DATA.name }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <TextField
                            control={ control }
                            name={ COURT_CATEGORY_FIELD_KEYS.position }
                            label={ COURT_CATEGORY_LABEL_DATA.position }
                            type="number"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleCloseModal }>Отмена</Button>
                <LoadingButton
                    color="success"
                    variant="contained"
                    autoFocus
                    loading={ courtLoading }
                    onClick={ handleSubmit(handleCreateCourtCategory) }
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateCourtCategoryModal;
