import { GridFilterModel } from '@mui/x-data-grid-premium';
import { CustomHeaderData } from 'types/DataGrid';

export const addParamsToFilterModel = (filterModel: GridFilterModel, headerData: CustomHeaderData[]) => {
    const updatedItems = filterModel.items.map((filterItem) => {
        const matchingData = headerData.find((data) => data.field === filterItem.columnField);

        if (matchingData) {
            return {
                ...filterItem,
                type: matchingData.type,
                hasuraFilterFields: matchingData.hasuraFilterFields ? [...matchingData.hasuraFilterFields] : [],
            };
        }

        return filterItem;
    });

    return { ...filterModel, items: updatedItems };
};
