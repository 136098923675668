import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

const CreateButtonLink: FC = () => {
    return (
        <Link to="create" relative="path">
            <Button variant="outlined" color="primary" size="small" startIcon={ <AddIcon /> }>
                Добавить
            </Button>
        </Link>
    );
};

export default CreateButtonLink;
