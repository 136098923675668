import { makeAutoObservable } from 'mobx';

export class CreatePriceSalesRoundModal {
    public isOpen: boolean = false;

    public priceTypeId = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(priceTypeId?: string) {
        this.priceTypeId = priceTypeId || '';
        this.isOpen = true;
    }

    public onClose() {
        this.priceTypeId = '';
        this.isOpen = false;
    }
}
