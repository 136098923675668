import { ReactElement } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FiberNewRoundedIcon from '@mui/icons-material/FiberNewRounded';
import PendingIcon from '@mui/icons-material/Pending';
import { IconButton, Tooltip } from '@mui/material';
import { OptionT } from 'types/Option';

const enum BulkOperationsStatus {
    Finished = 'finished',
    Pending = 'pending',
    Error = 'error',
    New = 'new',
}

export const enum BulkOperationsType {
    createSchedule = 'create_schedule',
    recreateScheduleForPrice = 'recreate_schedule_for_price',
    createEntryGroupByTemplate = 'create_entry_group_by_template'
}

export const enum BulkOperationErrorMessages {
    errorScheduleSlotRateNotFound = 'error_schedule_slot_rate_not_found',
    scheduleSlotAlreadyReserved = 'schedule_slot_already_reserved',
    invalidGroupSchedule = 'invalid_group_schedule',
    wrongCoach = 'wrong_coach',
}

export const BULK_OPERATIONS_TYPE_DICTIONARY: Record<BulkOperationsType, string> = {
    [BulkOperationsType.createSchedule]: 'Создание расписания',
    [BulkOperationsType.recreateScheduleForPrice]: 'Перегенерация расписания для прайса',
    [BulkOperationsType.createEntryGroupByTemplate]: 'Cоздание групповых занятий по шаблону',
};

export const BULK_OPERATIONS_ERROR_MESSAGE: Record<BulkOperationErrorMessages, string> = {
    [BulkOperationErrorMessages.errorScheduleSlotRateNotFound]: 'Не найдена цена для слота расписания',
    [BulkOperationErrorMessages.scheduleSlotAlreadyReserved]: 'Слот расписания уже занят',
    [BulkOperationErrorMessages.invalidGroupSchedule]: 'Неверный шаблон расписания для групповых занятий',
    [BulkOperationErrorMessages.wrongCoach]: 'Неверный тренер',
};

export const BULK_OPERATIONS_TYPE_OPTIONS: OptionT[] = [
    {
        value: BulkOperationsType.createSchedule,
        label: BULK_OPERATIONS_TYPE_DICTIONARY[BulkOperationsType.createSchedule],
    },
    {
        value: BulkOperationsType.recreateScheduleForPrice,
        label: BULK_OPERATIONS_TYPE_DICTIONARY[BulkOperationsType.recreateScheduleForPrice],
    },
    {
        value: BulkOperationsType.createEntryGroupByTemplate,
        label: BULK_OPERATIONS_TYPE_DICTIONARY[BulkOperationsType.createEntryGroupByTemplate],
    },
];

export const BULK_OPERATIONS_STATUS_ICONS_DICTIONARY = {
    [BulkOperationsStatus.Finished]: 'Успешно',
    [BulkOperationsStatus.Pending]: 'В ожидании',
    [BulkOperationsStatus.Error]: 'Провалилось',
    [BulkOperationsStatus.New]: 'Новое',
};

export const BULK_OPERATIONS_STATUS_ICONS: { [key: string]: ReactElement } = {
    [BulkOperationsStatus.Finished]: (
        <Tooltip title={ BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Finished] } placement="right">
            <IconButton>
                <CheckCircleIcon color="success" />
            </IconButton>
        </Tooltip>
    ),
    [BulkOperationsStatus.Pending]: (
        <Tooltip title={ BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Pending] } placement="right">
            <IconButton>
                <PendingIcon color="warning" />
            </IconButton>
        </Tooltip>
    ),
    [BulkOperationsStatus.Error]: (
        <Tooltip title={ BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Error] } placement="right">
            <IconButton>
                <ErrorIcon color="error" />
            </IconButton>
        </Tooltip>
    ),
    [BulkOperationsStatus.New]: (
        <Tooltip title={ BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.New] } placement="right">
            <IconButton>
                <FiberNewRoundedIcon color="primary" />
            </IconButton>
        </Tooltip>
    ),
};

export const BULK_OPERATIONS_STATUS_OPTIONS = [
    {
        value: BulkOperationsStatus.Finished,
        label: BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Finished],
    },
    {
        value: BulkOperationsStatus.Pending,
        label: BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Pending],
    },
    {
        value: BulkOperationsStatus.Error,
        label: BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.Error],
    },
    {
        value: BulkOperationsStatus.New,
        label: BULK_OPERATIONS_STATUS_ICONS_DICTIONARY[BulkOperationsStatus.New],
    },
];
