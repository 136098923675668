import {
    TIME_24_HOURS_15_STEP,
} from 'constants/hours';
import { MODAL_TITLE_CREATE_ORGANIZATION } from 'constants/modalTitles';
import { PROPERTY_END, PROPERTY_START } from 'constants/propertyNames';
import { WEEK_DAYS_VALUE_OPTIONS } from 'constants/weekdays';

import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import {
    Button, DialogActions, DialogContent, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import Select from 'shared/component/Form/Select';
import TextField from 'shared/component/Form/TextField';
import { Modal } from 'shared/component/Modal';
import ModalTitle from 'shared/component/Modal/ModalTitle';
import { SERVICE_APP_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import useFetchFilterOptions from 'shared/hook/filterOptions/useFetchFilterOptions';
import useOrganizationCreate from 'shared/hook/organization/create/useOrganiztionCreate';
import { useStore } from 'store';

import {
    ORGANIZATION_DEFAULT_VALUES, ORGANIZATION_FIELD_KEYS,
    ORGANIZATION_LABEL_DATA,
    ORGANIZATION_VALIDATION_SCHEMA, OrganizationFormFields,
} from './formdata';

const CreateOrganizationModal: FC = observer(() => {
    const { getFilterOption } = useStore('filterOptions');
    const { isOpen, onClose } = useStore('createOrganizationModal');
    const { createOrganization } = useOrganizationCreate();

    const { loading: serviceAppLoading } = useFetchFilterOptions(SERVICE_APP_FILTER_OPTIONS_QUERY, 'serviceApp');
    const serviceAppFilterOptions = getFilterOption('serviceApp');

    const {
        control, handleSubmit, reset, formState: { isValid, isDirty },
    } = useForm<OrganizationFormFields>({
        defaultValues: ORGANIZATION_DEFAULT_VALUES,
        mode: 'onBlur',
        resolver: yupResolver(ORGANIZATION_VALIDATION_SCHEMA),
    });

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleCreateOrganization = (data: OrganizationFormFields) => {
        createOrganization(data).then(() => handleClose());
    };

    return (
        <Modal maxWidth="md" open={ isOpen }>
            <ModalTitle onClose={ handleClose }>{ MODAL_TITLE_CREATE_ORGANIZATION }</ModalTitle>
            <DialogContent dividers={ true }>
                <Grid2 xs={ 12 } container spacing={ 2 }>
                    <Grid2 xs={ 12 }>
                        <TextField
                            size="small"
                            label={ ORGANIZATION_LABEL_DATA.name }
                            name={ ORGANIZATION_FIELD_KEYS.name }
                            control={ control }
                            type="text"
                        />
                    </Grid2>
                    <Grid2 xs={ 12 }>
                        <Select
                            label={ ORGANIZATION_LABEL_DATA.serviceApp }
                            name={ ORGANIZATION_FIELD_KEYS.serviceApp }
                            loading={ serviceAppLoading }
                            options={ serviceAppFilterOptions }
                            control={ control }
                        />
                    </Grid2>
                    <Grid2 xs={ 12 } display="flex" justifyContent="space-between">
                        <Typography variant="h6">Рабочее время</Typography>
                    </Grid2>
                    { WEEK_DAYS_VALUE_OPTIONS.map((day, index) => (
                        <Grid2 xs={ 12 } key={ day.value } container>
                            <Grid2 xs={ 2 } display="flex" alignItems="center" pt="30px">
                                <Typography>{ day.label }</Typography>
                            </Grid2>
                            <Grid2 xs={ 5 }>
                                <Select
                                    label={ PROPERTY_START }
                                    name={ `workTime[${index}].start` }
                                    options={ TIME_24_HOURS_15_STEP }
                                    control={ control }
                                />
                            </Grid2>
                            <Grid2 xs={ 5 }>
                                <Select
                                    label={ PROPERTY_END }
                                    name={ `workTime[${index}].end` }
                                    options={ TIME_24_HOURS_15_STEP }
                                    control={ control }
                                />
                            </Grid2>
                        </Grid2>
                    )) }
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>
                    Отмена
                </Button>
                <LoadingButton
                    variant="contained"
                    color="success"
                    autoFocus
                    disabled={ !isValid || !isDirty }
                    onClick={ handleSubmit(handleCreateOrganization) }
                >
                    Добавить
                </LoadingButton>
            </DialogActions>
        </Modal>
    );
});

export default CreateOrganizationModal;
