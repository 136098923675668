import { makeAutoObservable } from 'mobx';
import { ScheduleSlotReserveT } from 'types/ScheduleSlotReserve';
import { ServiceCategoryT } from 'types/ServiceCategory';

export default class ScheduleSlotReserveStore {
    public scheduleSlotReserveList: ScheduleSlotReserveT[] = [];

    public count: number = 0;

    public serviceCategories: ServiceCategoryT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get scheduleSlotReserves(): ScheduleSlotReserveT[] {
        return this.scheduleSlotReserveList;
    }

    public setScheduleSlotReserves(scheduleSlotReserves: ScheduleSlotReserveT[], count?: number) {
        this.scheduleSlotReserveList = scheduleSlotReserves;
        this.count = count ?? 0;
    }
}
