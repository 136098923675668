import { gql } from '@apollo/client';
import { WorkTime } from 'types/Organization';

export type OrganizationUpdateResponseDataT = {
    updateOrganization: {
        id: string;
    };
} | null | undefined;

type OrganizationUpdateMutationPayloadT = {
    name: string;
    service_app_id: string | null;
    work_time: WorkTime[];
};

export type OrganizationUpdateVarT = {
    id: string;
    set: OrganizationUpdateMutationPayloadT;
}

export const ORGANIZATION_UPDATE = gql`
    mutation updateOrganization($set: organization_set_input = {}, $id: String = "") {
        updateOrganization: update_organization_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
