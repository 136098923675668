import { ERROR_COURT_TYPE_DELETE, SUCCESS_COURT_TYPE_DELETE } from 'constants/notificationMessage';

import useCourtTypeDelete from 'shared/data-hook/courtType/delete/single/useCourtTypeDelete';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { CourtTypeT } from 'types/CourtType';

const useDeleteCourtType = () => {
    const { error, deleteCourtType } = useCourtTypeDelete();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const courtTypeDelete = async (courtTypeId: CourtTypeT['id']) => {
        const response = await deleteCourtType(courtTypeId);

        if (error || !response) {
            showError(ERROR_COURT_TYPE_DELETE);
        } else {
            toggleRefetchTrigger();
            showSuccess(SUCCESS_COURT_TYPE_DELETE);
        }
    };

    return {
        courtTypeDelete,
    };
};

export default useDeleteCourtType;
