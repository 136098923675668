import { gql } from '@apollo/client';

export type LoginVar = {
    username: string;
    password: string;
};

export type LoginData = {
    adminLogin: { userId: string };
};

// TODO: проверить "credentinals"
export const LOGIN = gql`
    mutation adminLogin($password: String = "", $username: String = "") {
        adminLogin(credentinals: { password: $password, username: $username }) {
            user_id
        }
    }
`;
