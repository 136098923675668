import {
    PROPERTY_ACTIONS, PROPERTY_DURATION, PROPERTY_TITLE, PROPERTY_USE_HALVES,
} from 'constants/propertyNames';

import React, { FC, MouseEventHandler } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import useDeleteCourtType from 'shared/hook/courtType/delete/useDeleteCourtType';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { courtTypeDelete } = useDeleteCourtType();
    const { onOpen } = useStore('updateCourtTypeModal');

    const handleOpenClick: MouseEventHandler<HTMLButtonElement> = () => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ handleOpenClick }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ courtTypeDelete } />
        </>
    );
};

export const COURT_TYPE_SETTING_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 0.7,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_USE_HALVES,
        field: 'useHalves',
        flex: 0.2,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: PROPERTY_DURATION,
        field: 'duration',
        flex: 0.3,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        flex: 0.1,
        align: 'center',
        headerAlign: 'center',
    },
];
