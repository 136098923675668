import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { BulkOperationsT } from 'types/BulkOperation';

import { AggregateDTO } from '../../../utils/DTO/AggregateDTO';

import { BulkOperationDTO } from './BulkOperationDTO';

const DTO = t.type({
    bulkOperations: t.array(BulkOperationDTO),
    bulkOperationsCount: AggregateDTO,
});

type BulkOperationDTOT = t.TypeOf<typeof DTO>;

export const BulkOperationsDTO = tPromise.extendDecoder<BulkOperationDTOT, BulkOperationsT>(
    DTO,
    ({
        bulkOperations,
        bulkOperationsCount,
    }) => ({
        bulkOperations,
        bulkOperationsCount,
    }),
) as t.Type<BulkOperationsT, BulkOperationDTOT>;
