import { API_DATE_FORMAT } from 'constants/date';
import { PAGE_TITLE_COURT_VIDEO_ANALYTICS } from 'constants/pageTitles';
import { PROPERTY_DATETIME } from 'constants/propertyNames';

import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import DatePicker from 'shared/component/DatePicker';
import useCourts from 'shared/data-hook/court/fetch/fetchAll/useCourts';
import useCourtsVideoAnalytics from 'shared/data-hook/courtVideoAnalytics/fetch/fetchAll/useCourtsVideoAnalytics';
import useOrganizations from 'shared/data-hook/organization/fetch/fetchAll/useOrganizations';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CourtVideoAnalyticsList from './components/CourtVideoAnalyticsList';

const List: FC = observer(() => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');
    const [date, setDate] = useState<Dayjs>(
        searchParams && dayjs(dateParam).isValid()
            ? dayjs(dateParam, API_DATE_FORMAT).startOf('day')
            : dayjs().startOf('day'),
    );
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: courtsData, loading: courtsLoading, count: colCount, fetchCourts,
    } = useCourts();
    const {
        data: organizationsData, loading: organizationsLoading, fetchOrganizations,
    } = useOrganizations();
    const {
        data: courtsVideoAnalyticsData, loading: courtsVideoAnalyticsLoading, fetchCourtsVideoAnalytics,
    } = useCourtsVideoAnalytics();
    const loading = courtsLoading || organizationsLoading || courtsVideoAnalyticsLoading;
    const { setCourts } = useStore('court');
    const { setOrganizations } = useStore('organization');
    const { setCourtVideoAnalytics } = useStore('courtVideoAnalytics');

    usePageTitle(PAGE_TITLE_COURT_VIDEO_ANALYTICS);

    useEffect(() => {
        if (!date) return;

        setSearchParams({
            date: date.format(API_DATE_FORMAT),
        });
    }, [date]);

    useEffect(() => {
        fetchCourts().then();
        fetchOrganizations().then();
    }, []);

    useEffect(() => {
        fetchCourtsVideoAnalytics(date).then();
    }, [date]);

    useEffect(() => {
        setCourts(courtsData, colCount);
    }, [courtsData, courtsLoading]);

    useEffect(() => {
        setOrganizations(organizationsData);
    }, [organizationsData, organizationsLoading]);

    useEffect(() => {
        setCourtVideoAnalytics(courtsVideoAnalyticsData);
    }, [courtsVideoAnalyticsData, courtsVideoAnalyticsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel>
                <DatePicker label={ PROPERTY_DATETIME } setValue={ setDate } value={ date } />
            </ControlPanel>
            <CourtVideoAnalyticsList
                date={ date }
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
