import { useMutation } from '@apollo/client';
import { decamelizeKeys } from 'humps';
import { MemberCardFormFields } from 'page/MemberCard/components/formData';
import {
    CREATE_MEMBER_CARD, CreateMemberCardPayloadT, CreateMemberCardResponseT,
    CreateMemberCardT,
    CreateMemberCardVarT, EMPTY_CREATE_MEMBER_CARD_RESPONSE,
} from 'shared/graphql/mutation/memberCard/MemberCardCreate';
import { useStore } from 'store';
import { PaymentMethodT } from 'types/PaymentMethod';
import { PaymentTypeT } from 'types/PaymentType';

import { CreateMemberCardDataT } from './CreateMemberCardData';

const useCreateMemberCard = (): CreateMemberCardDataT => {
    const [create, { loading, error }] = useMutation<CreateMemberCardT, CreateMemberCardVarT>(
        CREATE_MEMBER_CARD,
    );
    const { serviceApp } = useStore('serviceApp');

    const createMemberCard = async (createMemberCardData: MemberCardFormFields, paymentTypeId: PaymentTypeT['id'], paymentMethodId: PaymentMethodT['id']) => {
        try {
            const { data } = await create({
                variables: {
                    appId: serviceApp.id,
                    payload: decamelizeKeys({
                        ...createMemberCardData,
                        paymentTypeId,
                        paymentMethodId,
                    }) as CreateMemberCardPayloadT,
                },
            });

            return data?.adminMemberCardCreate as CreateMemberCardResponseT;
        } catch {
            return EMPTY_CREATE_MEMBER_CARD_RESPONSE;
        }
    };

    return {
        createMemberCard,
        loading,
        error,
    };
};

export default useCreateMemberCard;
