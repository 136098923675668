import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import queryString from 'query-string';

export const setQueryParams = (
    shouldUpdateUrl: boolean,
    sortModel: GridSortModel,
    filterModel: GridFilterModel,
    page: number,
    pageSize: number,
    setShouldUpdateUrl: any,
    navigate: any,
) => {
    if (!shouldUpdateUrl) {
        return;
    }

    let filter;
    let sort;
    let linkOperator;

    if (Object.keys(sortModel).length > 0) {
        sort = Object.values(sortModel).map((item: any) => {
            return `${item.field};${item.sort}`;
        });
    }

    if (Object.keys(filterModel).length > 0) {
        filter = Object.values(filterModel?.items).map((item: any) => {
            if (!item.value && (!item.columnField || !item.operatorValue)) {
                return undefined;
            }
            linkOperator = filterModel.linkOperator;

            if (!item.value && item.columnField && item.operatorValue) {
                return `${item.columnField};${item.operatorValue}`;
            }

            return `${item.columnField};${item.operatorValue};${item.value}`;
        });
    }

    const params = {
        page: (page + 1).toString(),
        pageSize: pageSize.toString(),
        linkOperator,
        filter,
        sort,
    };

    navigate({
        search: queryString.stringifyUrl({ url: '', query: { ...params } }),
    });
    setShouldUpdateUrl(false);
};
