import { BONUS } from 'constants/currency';
import { currencyHeaderName } from 'constants/CurrencyHeaderName';
import { EMPTY_CELL } from 'constants/dataGrid';
import {
    PROPERTY_ACTIONS, PROPERTY_IS_ACTIVE, PROPERTY_POSITION, PROPERTY_TITLE,
} from 'constants/propertyNames';

import { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { isActiveIcon } from 'shared/component/DataGrid/Cell/ActiveIcon';
import useDeletePaymentType from 'shared/hook/paymentType/delete/useDeletePaymentType';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const renderCost = (value: number | null, currency?: string) => {
    const { serviceApp } = useStore('serviceApp');

    return (
        value
            ? <span>{ value } { currency ?? serviceApp.fiatCurrencyTitle }</span>
            : <span>{ EMPTY_CELL }</span>
    );
};

const RenderActionCell: FC <GridRenderCellParams> = (props) => {
    const { row: { id, title } } = props;
    const { onOpen } = useStore('updatePaymentType');
    const { paymentTypeDelete } = useDeletePaymentType();

    const handleOpenUpdate = () => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ () => handleOpenUpdate() }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ id } title={ title } actionDelete={ paymentTypeDelete } />
        </>
    );
};

export const PAYMENT_TYPE_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'title',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['title'],
        hasuraSortFields: ['title'],
    },
    {
        headerName: PROPERTY_POSITION,
        field: 'position',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraFilterFields: ['position'],
        hasuraSortFields: ['position'],
    },
    {
        headerName: PROPERTY_IS_ACTIVE,
        field: 'isActive',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
        renderCell: isActiveIcon,
        hasuraFilterFields: ['is_active'],
        hasuraSortFields: ['is_active'],
    },
    {
        field: 'defaultFiatPayment',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraFilterFields: ['default_fiat_payment'],
        hasuraSortFields: ['default_fiat_payment'],
        renderHeader: () => currencyHeaderName(),
        renderCell: (params) => renderCost(params.row?.defaultFiatPayment),
    },
    {
        field: 'defaultBonusPayment',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        hasuraFilterFields: ['default_bonus_payment'],
        hasuraSortFields: ['default_bonus_payment'],
        renderHeader: () => currencyHeaderName(BONUS),
        renderCell: (params) => renderCost(params.row?.defaultBonusPayment, BONUS),
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        renderCell: RenderActionCell,
    },
];
