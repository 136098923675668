import { useMutation } from '@apollo/client';
import { OrganizationLocationFormFields } from 'page/OrganizationLocation/components/formData';
import {
    ORGANIZATION_LOCATION_CREATE,
    OrganizationLocationCreateResponseDataT,
    OrganizationLocationCreateVarT,
} from 'shared/graphql/mutation/organizationLocation/OrganizationLocationCreate';

import { CreateOrganizationLocationDataT } from './CreateOrganizationLocationData';

const useCreateOrganizationLocation = (): CreateOrganizationLocationDataT => {
    const [createOrganizationLocation, { loading, error }] = useMutation<OrganizationLocationCreateResponseDataT, OrganizationLocationCreateVarT>(ORGANIZATION_LOCATION_CREATE);

    const create = async (input: OrganizationLocationFormFields) => {
        try {
            const { data } = await createOrganizationLocation({
                variables: {
                    object: {
                        name: input.name,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message);
            throw error;
        }
    };

    return {
        loading,
        error,
        organizationLocationCreate: create,
    };
};

export default useCreateOrganizationLocation;
