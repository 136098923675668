import { useMutation } from '@apollo/client';
import { CourtTypeDeleteDataT } from 'shared/data-hook/courtType/delete/single/CourtTypeDeleteData';
import {
    COURT_TYPE_DELETE,
    CourtTypeDeleteResponseDataT,
    CourtTypeDeleteVarT,
} from 'shared/graphql/mutation/courtType/CourtTypeDelete';
import { CourtTypeT } from 'types/CourtType';

const useCourtTypeDelete = (): CourtTypeDeleteDataT => {
    const [deleteCourtType, { loading, error }] = useMutation<
        CourtTypeDeleteResponseDataT,
        CourtTypeDeleteVarT
    >(COURT_TYPE_DELETE);

    const deleteAction = async (courtTypeId: CourtTypeT['id']) => {
        try {
            const { data } = await deleteCourtType({
                variables: {
                    id: courtTypeId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteCourtType: deleteAction,
        loading,
        error,
    };
};

export default useCourtTypeDelete;
