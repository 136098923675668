import { useLazyQuery } from '@apollo/client';
import { CourtCategoriesDTO } from 'shared/data-hook/courtCategory/fetch/DTO/CourtCategoriesDTO';
import { CourtCategoriesDataT } from 'shared/data-hook/courtCategory/fetch/fetchAll/CourtCategoriesData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { COURT_CATEGORIES_QUERY, CourtCategoriesResponseT } from 'shared/graphql/query/courtCategory/CourtCategoryQuery';
import { EMPTY_COURT_CATEGORIES_RESULT } from 'types/CourtCategory';
import { FetchDataParams } from 'types/DataGrid';

const useCourtCategories = (): CourtCategoriesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CourtCategoriesResponseT>(
        COURT_CATEGORIES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedCourtCategoryData = decodeDTO(
        CourtCategoriesDTO,
        EMPTY_COURT_CATEGORIES_RESULT,
        (courtCategories) => courtCategories,
        {
            courtCategories: data?.courtCategories,
            courtCategoriesCount: data?.courtCategoriesCount,
        },
    );

    const fetchCourtCategories = async ({
        limit, offset, orderBy = { position: 'asc' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchCourtCategories,
        loading,
        data: decodedCourtCategoryData?.courtCategories,
        count: decodedCourtCategoryData?.courtCategoriesCount,
    };
};

export default useCourtCategories;
