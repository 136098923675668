import { useLazyQuery } from '@apollo/client';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import {
    SERVICE_APP_QUERY,
    ServiceAppDataT,
} from 'shared/graphql/query/serviceApp/ServiceAppQuery';
import { EMPTY_SERVICE_APP, ServiceAppT } from 'types/serviceApp/ServiceApp';

import { ServiceAppDTO } from '../DTO/ServiceAppDTO';

import { FetchServiceAppDataT } from './FetchServiceAppData';

const useFetchServiceApp = (): FetchServiceAppDataT => {
    const [getServiceApp, { loading }] = useLazyQuery<ServiceAppDataT>(
        SERVICE_APP_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const fetch = async (): Promise<ServiceAppT> => {
        try {
            const { data } = await getServiceApp();

            return decodeDTO(
                ServiceAppDTO,
                EMPTY_SERVICE_APP,
                (serviceAppData) => serviceAppData,
                data?.serviceApp[0],
            );
        } catch (error) {
            return EMPTY_SERVICE_APP;
        }
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchServiceApp;
