import { useLazyQuery } from '@apollo/client';
import { CourtsDTO } from 'shared/data-hook/court/fetch/DTO/CourtsDTO';
import { CourtsDataT } from 'shared/data-hook/court/fetch/fetchAll/CourtsData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import { COURTS_QUERY, CourtsResponseT } from 'shared/graphql/query/court/CourtQuery';
import { EMPTY_COURTS_RESULT } from 'types/Court';
import { FetchDataParams } from 'types/DataGrid';

const useCourts = (): CourtsDataT => {
    const [fetch, { data, loading }] = useLazyQuery<CourtsResponseT>(COURTS_QUERY, {
        fetchPolicy: 'network-only',
    });

    const decodedCourtData = decodeDTO(CourtsDTO, EMPTY_COURTS_RESULT, (courts) => courts, {
        courts: data?.courts,
        courtsCount: data?.courtsCount,
    });

    const fetchCourts = async ({
        limit,
        offset,
        orderBy = { order: 'asc' },
        where = {
            items: [],
            linkOperator: 'and',
        },
    }: FetchDataParams = {}): Promise<void> => {
        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    where: filterData(where),
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchCourts,
        loading,
        data: decodedCourtData?.courts,
        count: decodedCourtData?.courtsCount,
    };
};

export default useCourts;
