import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { ClientFullNameOptions } from 'shared/data-hook/utils/DTO/Client';
import { MemberCardT } from 'types/MemberCard';

const DTO = t.type({
    id: t.string,
    is_active: t.boolean,
    member_card_type: t.type({
        title: t.string,
    }),
    datetime_start: t.string,
    datetime_end: t.string,
    client: t.type(ClientFullNameOptions()),
});

export type MemberCardDTOT = t.TypeOf<typeof DTO>;

export const MemberCardDTO = tPromise.extendDecoder<MemberCardDTOT, MemberCardT>(
    DTO,
    ({
        id, is_active, member_card_type: { title }, datetime_start, datetime_end, client: { first_name, last_name, middle_name },
    }) => {
        return {
            id,
            isActive: is_active,
            title,
            datetimeStart: new Date(datetime_start),
            datetimeEnd: new Date(datetime_end),
            client: {
                firstName: first_name,
                lastName: last_name,
                middleName: middle_name,
            },
        };
    },
) as t.Type<MemberCardT, MemberCardDTOT>;
