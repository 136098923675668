import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        sidebarWidth: number;
    }
    interface ThemeOptions {
        sidebarWidth?: number;
    }
}

export const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
        },
    },
    sidebarWidth: 240,
});
