import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { AggregateDTO } from 'shared/data-hook/utils/DTO/AggregateDTO';
import { OrganizationsT } from 'types/Organization';

import { OrganizationDTO } from './OrganizationDTO';

const DTO = t.type({
    organizations: t.array(OrganizationDTO),
    organizationsCount: AggregateDTO,
});

type OrganizationsDTOT = t.TypeOf<typeof DTO>;

export const OrganizationsDTO = tPromise.extendDecoder<OrganizationsDTOT, OrganizationsT>(
    DTO,
    ({
        organizations,
        organizationsCount,
    }) => ({
        organizations,
        organizationsCount,
    }),
) as t.Type<OrganizationsT, OrganizationsDTOT>;
