import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { PaymentTypesT } from 'types/PaymentType';

import { AggregateDTO } from '../../../utils/DTO/AggregateDTO';

import { PaymentTypeDTO } from './PaymentTypeDTO';

const DTO = t.type({
    paymentTypes: t.array(PaymentTypeDTO),
    paymentTypesCount: AggregateDTO,
});

type PaymentTypesDTOT = t.TypeOf<typeof DTO>;

export const PaymentTypesDTO = tPromise.extendDecoder<PaymentTypesDTOT, PaymentTypesT>(
    DTO,
    ({
        paymentTypes,
        paymentTypesCount,
    }) => ({
        paymentTypes,
        paymentTypesCount,
    }),
) as t.Type<PaymentTypesT, PaymentTypesDTOT>;
