import { Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)({
    '&.fullHeightModal': {
        '& .MuiDialog-paper': {
            minHeight: 'calc(100% - 64px)',
        },
    },

    '&.modalWithTabs': {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
    },
}) as typeof Dialog;
