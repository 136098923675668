export type GroupViewT = {
    id: string;
    name: string;
    categoryId: string | null;
    ageTypeId: string | null;
    minSize: number | null;
    avgSize: number | null;
    maxSize: number | null;
    clientsForMainCoach: number;
    ignoreBusyCoaches: boolean;
    groupConstantPrice: boolean;
    correctionFactors: Array<{
        value: string;
        label: string;
    }>;
};

export type GroupViewsT = {
    groupViews: GroupViewT[];
    groupViewsCount: number;
};

export const EMPTY_GROUP_VIEWS: GroupViewsT = {
    groupViews: [],
    groupViewsCount: 0,
};

export const EMPTY_GROUP_VIEW_RESULT: GroupViewT = {
    id: '',
    name: '',
    categoryId: null,
    ageTypeId: null,
    minSize: null,
    avgSize: null,
    maxSize: null,
    clientsForMainCoach: 0,
    ignoreBusyCoaches: false,
    groupConstantPrice: false,
    correctionFactors: [],
};
