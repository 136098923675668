import { gql } from '@apollo/client';

export type MemberCardSwitchIsActivePayloadT = {
    is_active: boolean;
};

export type MemberCardSwitchIsActiveVar = {
    id: string;
    set: MemberCardSwitchIsActivePayloadT;
};

export type MemberCardSwitchIsActiveResponseDataT = {
    switchIsActiveMemberCard: {
        id: string;
    };
} | null | undefined;
export const MEMBER_CARD_SWITCH_IS_ACTIVE = gql`
    mutation SwitchIsActiveMemberCard($set: member_card_set_input = {}, $id: String = "") {
        switchIsActiveMemberCard: update_member_card_by_pk(pk_columns: { id: $id }, _set: $set) {
            id
        }
    }
`;
