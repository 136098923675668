import { gql } from '@apollo/client';
import { ClientT } from 'types/Client';

export type ClientsResponseT = {
    clients: ClientT[];
};

export const CLIENTS_QUERY = gql`
    query Clients {
        clients: client_data(order_by: {client: {last_name: asc}}) {
            id
            client {
                first_name
                last_name
                middle_name
            }
        }
    }
`;
