import { useEffect } from 'react';
import useCourtCategory from 'shared/data-hook/courtCategory/fetch/fetchOne/useCourtCategory';
import { useStore } from 'store';

const useFetchCourtCategory = (id: string) => {
    const { fetchCourtCategory, loading } = useCourtCategory();
    const { isOpen, setCourtCategory } = useStore('updateCourtCategoryModal');

    useEffect(() => {
        if (isOpen && id) {
            fetchCourtCategory(id).then((fetchedCourtCategory) => {
                setCourtCategory(fetchedCourtCategory);
            });
        }
    }, [isOpen, id]);

    return {
        loading,
    };
};

export default useFetchCourtCategory;
