import { gql } from '@apollo/client';

export type LogoutResultT = {
    logout: {
        result: string;
    };
};

export const LOGOUT = gql`
    mutation Logout {
        logout {
            result
        }
    }
`;
