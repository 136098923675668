import {
    PROPERTY_AGE_TYPE_GROUP,
    PROPERTY_AVG_SIZE,
    PROPERTY_CATEGORY,
    PROPERTY_CLIENTS_FOR_MAIN_COACH,
    PROPERTY_CORRECTION_FACTORS, PROPERTY_GROUP_CONSTANT_PRICE, PROPERTY_IGNORE_BUSY_COACHES,
    PROPERTY_MAX_SIZE,
    PROPERTY_MIN_SIZE,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import { OptionT } from 'types/Option';
import { object, string } from 'yup';

export type GroupViewFormFields = {
    name: string;
    categoryId: string;
    ageTypeId: string;
    minSize: number;
    avgSize: number;
    maxSize: number;
    clientsForMainCoach: number;
    ignoreBusyCoaches: boolean;
    groupConstantPrice: boolean;
    correctionFactors: OptionT[];
}

export enum GROUP_VIEW_FIELD_KEYS {
    name = 'name',
    categoryId = 'categoryId',
    ageTypeId = 'ageTypeId',
    minSize = 'minSize',
    avgSize = 'avgSize',
    maxSize = 'maxSize',
    clientsForMainCoach = 'clientsForMainCoach',
    ignoreBusyCoaches = 'ignoreBusyCoaches',
    groupConstantPrice = 'groupConstantPrice',
}

export const GROUP_VIEW_DEFAULT_VALUES = {
    name: '',
    categoryId: '',
    ageTypeId: '',
    minSize: 0,
    avgSize: 0,
    maxSize: 0,
    clientsForMainCoach: 0,
    ignoreBusyCoaches: false,
    groupConstantPrice: false,
    correctionFactors: [],
};

type GroupViewLabelData= Record<keyof GroupViewFormFields, string>

export const GROUP_VIEW_LABEL_DATA: GroupViewLabelData = {
    name: PROPERTY_TITLE,
    categoryId: PROPERTY_CATEGORY,
    ageTypeId: PROPERTY_AGE_TYPE_GROUP,
    minSize: PROPERTY_MIN_SIZE,
    avgSize: PROPERTY_AVG_SIZE,
    maxSize: PROPERTY_MAX_SIZE,
    clientsForMainCoach: PROPERTY_CLIENTS_FOR_MAIN_COACH,
    ignoreBusyCoaches: PROPERTY_IGNORE_BUSY_COACHES,
    groupConstantPrice: PROPERTY_GROUP_CONSTANT_PRICE,
    correctionFactors: PROPERTY_CORRECTION_FACTORS,
};

export const GROUP_VIEW_VALIDATION_SCHEMA = object().shape({
    name: string().required(),
}).required();

export const getGroupViewValidationSchema = () => GROUP_VIEW_VALIDATION_SCHEMA;
