import {
    ERROR_MEMBER_CARD_SWITCH_IS_ACTIVE,
    SUCCESS_MEMBER_CARD_SWITCH_IS_ACTIVE,
} from 'constants/notificationMessage';

import useMemberCardSwitchIsActive from 'shared/data-hook/memberCard/switchIsActive/useMemberCardSwitchIsActive';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useSwitchIsActiveMemberCard = () => {
    const { error, loading, memberCardSwitchIsActive } = useMemberCardSwitchIsActive();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const switchIsActiveMemberCard = async (memberCardId: string, input: boolean) => {
        const response = await memberCardSwitchIsActive(memberCardId, input);

        if (error || !response || !response.switchIsActiveMemberCard) {
            showError(ERROR_MEMBER_CARD_SWITCH_IS_ACTIVE);

            return;
        }

        toggleRefetchTrigger();
        showSuccess(SUCCESS_MEMBER_CARD_SWITCH_IS_ACTIVE);
    };

    return {
        switchIsActiveMemberCard,
        loading,
    };
};

export default useSwitchIsActiveMemberCard;
