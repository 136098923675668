import { makeAutoObservable } from 'mobx';

import { ServiceCategoryT } from '../../types/ServiceCategory';

export default class CreateServiceCategoryModal {
    public isOpen: boolean = false;

    public serviceCategories: ServiceCategoryT[] = [];

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }

    public setServiceCategories(serviceCategories: ServiceCategoryT[]) {
        this.serviceCategories = serviceCategories;
    }
}
