export const buildNestedObject = (keys: string[], value: any): any => {
    if (keys.length === 0) {
        return value;
    }

    const key = keys[0];
    const nestedObject = buildNestedObject(keys.slice(1), value);

    return { [key]: nestedObject };
};
