import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './List';

const Organization: FC = () => {
    return (
        <Routes>
            <Route path="/" element={ <List /> } />
        </Routes>
    );
};

export default Organization;
