import { makeAutoObservable } from 'mobx';

export default class DuplicateItemModal {
    public duplicatedItemId = '';

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(duplicatedItemId: string) {
        this.duplicatedItemId = duplicatedItemId;
    }

    public onClose() {
        this.duplicatedItemId = '';
    }
}
