import { makeAutoObservable } from 'mobx';

export default class CreateCourtCategoryModal {
    public isOpen: boolean = false;

    public onOpen() {
        this.isOpen = true;
    }

    public onClose() {
        this.isOpen = false;
    }

    public setCourtCategory() {
        this.isOpen = false;
    }

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }
}
