import { FC } from 'react';
import { observer } from 'mobx-react';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { prepareCoachesInGroupsHeaderData } from '../domain/prepareCoachesInGroupsHeaderData';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const CoachesInGroupsList: FC<PropsT> = observer((props) => {
    const {
        sortModel, filterModel, page, pageSize, loading, setPageSize, setPage, setSortModel, setFilterModel,
    } = props;
    const { coachesInGroups, count } = useStore('coachesInGroup');
    const { getFilterOption } = useStore('filterOptions');
    const groupFilterOptions = getFilterOption('group');
    const coachFilterOptions = getFilterOption('coach');
    const columns = prepareCoachesInGroupsHeaderData(groupFilterOptions, coachFilterOptions);

    return (
        <DataGrid
            rows={ coachesInGroups }
            rowCount={ count }
            rowAutoHeight={ true }
            columns={ columns }
            loading={ loading }
            pageSize={ pageSize }
            page={ page }
            setPageSize={ setPageSize }
            setPage={ setPage }
            setSortModel={ setSortModel }
            setFilterModel={ setFilterModel }
            sortModel={ sortModel }
            filterModel={ filterModel }
        />
    );
});

export default CoachesInGroupsList;
