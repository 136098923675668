import { EMPTY_CELL } from 'constants/dataGrid';
import { DATE_TIME_FORMAT_FOR_DATA_GRID } from 'constants/date';
import {
    PROPERTY_AUXILIARY_COACHES,
    PROPERTY_GROUP,
    PROPERTY_MAIN_COACH,
    PROPERTY_PERIOD,
} from 'constants/propertyNames';

import { Box, Chip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import renderDateToGrid from 'shared/utils/headerData/renderDateToGrid';
import { CoachT } from 'types/Coach';
import { CustomHeaderData } from 'types/DataGrid';

const renderCoaches = (coaches: CoachT[], mainCoach: CoachT) => {
    const preparedCoaches: CoachT[] = coaches.filter((coach) => coach.id !== mainCoach.id);

    if (preparedCoaches.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <Box sx={ {
            display: 'flex', gap: '4px', flexWrap: 'wrap', justifyContent: 'center',
        } }
        >
            {
                preparedCoaches.map((coach) => <Chip label={ coach.name } variant="outlined" color="primary" size="small" key={ coach.id } />)
            }
        </Box>
    );
};

export const COACHES_IN_GROUPS_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_GROUP,
        field: 'group',
        flex: 1.5,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['group', 'id'],
        hasuraSortFields: ['group', 'name'],
        renderCell: (params: GridRenderCellParams) => params.row.group?.name || EMPTY_CELL,
    },
    {
        headerName: PROPERTY_PERIOD,
        field: 'datetime',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        hasuraFilterFields: ['implementations', 'datetime'],
        hasuraSortFields: ['implementations_aggregate', 'max', 'datetime'],
        renderCell: (params: GridRenderCellParams) => renderDateToGrid(params.row.datetime, DATE_TIME_FORMAT_FOR_DATA_GRID),
    },
    {
        headerName: PROPERTY_MAIN_COACH,
        field: 'mainCoach',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        hasuraFilterFields: ['groupMainCoach', 'id'],
        hasuraSortFields: ['groupMainCoach', 'employee', 'full_name'],
        renderCell: (params: GridRenderCellParams) => <Chip label={ params.row.mainCoach.name } color="primary" size="small" key={ params.row.mainCoach.id } />,
    },
    {
        headerName: PROPERTY_AUXILIARY_COACHES,
        field: 'coaches',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => renderCoaches(params.row.coaches, params.row.mainCoach),
    },
];
