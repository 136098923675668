import React, { FC } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import TextField from 'shared/component/Form/TextField';
import { useStore } from 'store';

import {
    MEMBER_CARD_DISCOUNT_RATES_LABELS,
    PARAMS_MEMBER_CARD_DISCOUNT_RATES,
    PRICE_MEMBER_CARD_LABEL_DATA,
    PriceMemberCardFormFields,
} from './formData';

type PropsT = {
    control: Control<PriceMemberCardFormFields>;
};

export const DiscountAndCashbackParams: FC<PropsT> = observer(({ control }) => {
    const { priceSalesRounds } = useStore('priceSalesRound');

    const { serviceApp } = useStore('serviceApp');
    const { fields } = useFieldArray<PriceMemberCardFormFields>({
        control,
        name: 'memberCardTypeDiscountRates',
    });

    return (
        <>
            <Grid2 xs={ 12 }>
                <TextField
                    control={ control }
                    name="price"
                    type="number"
                    label={ PRICE_MEMBER_CARD_LABEL_DATA.price }
                    inputAdornment={ serviceApp.fiatCurrencyTitle }
                />
            </Grid2>
            <Grid2 xs={ 12 } container columnSpacing={ 2 }>
                { PARAMS_MEMBER_CARD_DISCOUNT_RATES.map((discountRateKey) => (
                    <Grid2 xs={ 6 } key={ discountRateKey }>
                        <Typography variant="h6" sx={ { mb: 2 } }>
                            { MEMBER_CARD_DISCOUNT_RATES_LABELS[discountRateKey as keyof typeof MEMBER_CARD_DISCOUNT_RATES_LABELS] }
                        </Typography>
                        { fields?.map((discountRate, index) => (
                            <Grid2 key={ discountRate.id } sx={ { mb: 2 } }>
                                <TextField
                                    label={ priceSalesRounds[index].title }
                                    name={ `memberCardTypeDiscountRates.${index}.${discountRateKey}` }
                                    control={ control }
                                    type="number"
                                    customError={ true }
                                    inputAdornment={ discountRateKey === 'multiplyingFactor' ? undefined : '%' }
                                />
                            </Grid2>
                        )) }
                    </Grid2>
                )) }
            </Grid2>
        </>
    );
});
