import { ClientDataT } from './Client';

export const decodeClientData = (clientDatum: ClientDataT) => {
    if (!clientDatum || !clientDatum.client) {
        return null;
    }

    const { last_name, middle_name, first_name } = clientDatum.client;

    return {
        firstName: first_name,
        middleName: middle_name,
        lastName: last_name,
    };
};
