import { gql } from '@apollo/client';
import { CoachCategoryT } from 'types/CoachCategory';

export type CoachCategoriesResponseT = {
    coachCategories: CoachCategoryT[];
};

export const COACH_CATEGORY_FILTER_OPTIONS_QUERY = gql`
    query CoachCategoryFilterOptions {
        filterOptions: coach_category(
            order_by: { name: asc }
        ) {
            value: id,
            label: name
        }
    }
`;

export const COACH_CATEGORIES_QUERY = gql`
    query CoachCategory {
        coachCategories: coach_category {
            id
            name
        }
    }
`;
