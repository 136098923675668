import { makeAutoObservable } from 'mobx';

export default class CreateScheduleModal {
    public priceSeasonId: string | null = null;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onClose() {
        this.priceSeasonId = null;
    }

    public onOpen(priceSeasonId: string) {
        this.priceSeasonId = priceSeasonId;
    }
}
