import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { CourtsVideoAnalyticsT } from 'types/CourtVideoAnalytics';

import { CourtVideoAnalyticsDTO } from './CourtVideoAnalyticsDTO';

const DTO = t.type({
    courtsVideoAnalytics: t.array(CourtVideoAnalyticsDTO),
});

type CourtsVideoAnalyticsDTOT = t.TypeOf<typeof DTO>;

export const CourtsVideoAnalyticsDTO = tPromise.extendDecoder<CourtsVideoAnalyticsDTOT, CourtsVideoAnalyticsT>(
    DTO,
    ({
        courtsVideoAnalytics,
    }) => ({
        courtsVideoAnalytics,
    }),
) as t.Type<CourtsVideoAnalyticsDTOT, CourtsVideoAnalyticsDTOT>;
