import {
    ERROR_MEMBER_CARD_UPDATE,
    SUCCESS_MEMBER_CARD_UPDATE,
} from 'constants/notificationMessage';

import { UpdateMemberCardFormFields } from 'page/MemberCard/components/updateFormData';
import useMemberCardUpdate from 'shared/data-hook/memberCard/update/useMemberCardUpdate';
import { useStore } from 'store';

import { useNotification } from '../../useNotification';

const useUpdateMemberCard = () => {
    const { error, memberCardUpdate, loading } = useMemberCardUpdate();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');
    const { showError, showSuccess } = useNotification();

    const updateMemberCard = async (data: UpdateMemberCardFormFields, id: string) => {
        const response = await memberCardUpdate(data, id);

        if (error || !response || !response.updateMemberCard) {
            showError(ERROR_MEMBER_CARD_UPDATE);
        }

        toggleRefetchTrigger();
        showSuccess(SUCCESS_MEMBER_CARD_UPDATE);
    };

    return {
        updateMemberCard,
        loading,
    };
};

export default useUpdateMemberCard;
