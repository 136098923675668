import { useMutation } from '@apollo/client';
import { CourtCategoryDeleteDataT } from 'shared/data-hook/courtCategory/delete/single/CourtCategoryDeleteData';
import {
    COURT_CATEGORY_DELETE,
    CourtCategoryDeleteResponseDataT,
    CourtCategoryDeleteVarT,
} from 'shared/graphql/mutation/courtCategory/courtCategoryDelete';
import { CourtCategoryT } from 'types/CourtCategory';

const useCourtCategoryDelete = (): CourtCategoryDeleteDataT => {
    const [deleteCourtCategory, { loading, error }] = useMutation<
        CourtCategoryDeleteResponseDataT,
        CourtCategoryDeleteVarT
    >(COURT_CATEGORY_DELETE);

    const deleteAction = async (courtCategoryId: CourtCategoryT['id']) => {
        try {
            const { data } = await deleteCourtCategory({
                variables: {
                    id: courtCategoryId,
                },
            });

            return data;
        } catch (errorCreate) {
            // TODO: use Sentry
            console.error((errorCreate as Error).message); // eslint-disable-line
            throw errorCreate;
        }
    };

    return {
        deleteCourtCategory: deleteAction,
        loading,
        error,
    };
};

export default useCourtCategoryDelete;
