import useMemberCard from 'shared/data-hook/memberCard/fetch/fetchOne/useMemberCard';
import { useStore } from 'store';

const useFetchMemberCard = () => {
    const { fetchMemberCard, loading } = useMemberCard();
    const { setMemberCard } = useStore('memberCard');
    const { id } = useStore('updateMemberCardModal');

    const fetch = () => {
        if (!id) {
            return;
        }

        fetchMemberCard(id).then((memberCard) => setMemberCard(memberCard));
    };

    return {
        fetch,
        loading,
    };
};

export default useFetchMemberCard;
