import { makeAutoObservable } from 'mobx';
import { OrganizationLocationT } from 'types/OrganizationLocation';

export default class UpdateOrganizationLocationModal {
    public isOpen: boolean = false;

    public updateOrganizationLocationId: string = '';

    public organizationLocation: OrganizationLocationT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedOrganizationLocationId: string) {
        this.updateOrganizationLocationId = updatedOrganizationLocationId;
        this.isOpen = true;
    }

    public onClose() {
        this.updateOrganizationLocationId = '';
        this.isOpen = false;
    }

    public setOrganizationLocation(organizationLocation: OrganizationLocationT) {
        this.organizationLocation = organizationLocation;
    }
}
