import { makeAutoObservable } from 'mobx';
import { PaymentT } from 'types/Payment';

export default class PaymentStore {
    public payments: PaymentT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public setPayments(payments: PaymentT[], count?: number) {
        this.payments = payments;
        this.count = count ?? 0;
    }
}
