import { EMPTY_CELL } from 'constants/dataGrid';
import {
    PROPERTY_ACTIONS,
    PROPERTY_PERSONAL_MANAGERS,
    PROPERTY_TITLE,
} from 'constants/propertyNames';

import React, { FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteOrganizationLocation
    from 'shared/hook/organizationLocation/delete/useDeleteOrganizationLocation';
import { base64Encode } from 'shared/utils/base64';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import { useStore } from 'store';
import { CustomHeaderData } from 'types/DataGrid';

const renderPersonalManagers: FC<GridRenderCellParams> = (props) => {
    const { row } = props;

    if (row.personalManagers.length === 0) {
        return <span>{ EMPTY_CELL }</span>;
    }

    return (
        <FlexWrapBox>
            {
                row.personalManagers.map((manager: string) => (
                    <Chip label={ manager } variant="outlined" color="primary" size="small" key={ `${row.id}-${base64Encode(manager)}` } />
                ))
            }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { onOpen } = useStore('updateOrganizationLocationModal');
    const { organizationLocationDelete } = useDeleteOrganizationLocation();

    return (
        <>
            <IconButton onClick={ () => onOpen(row.id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ row.id } title={ row.name } actionDelete={ organizationLocationDelete } />
        </>
    );
};

export const ORGANIZATION_LOCATION_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: PROPERTY_TITLE,
        field: 'name',
        flex: 0.6,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: PROPERTY_PERSONAL_MANAGERS,
        field: 'personalManagers',
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        type: 'string',
        hasuraFilterFields: ['personal_manager_locations', 'personal_manager', 'employee', 'full_name'],
        hasuraSortFields: ['personal_manager_locations_aggregate', 'count'],
        filterOperators,
        renderCell: renderPersonalManagers,
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        flex: 0.4,
        align: 'center',
        headerAlign: 'center',
        renderCell: RenderActionCell,
    },
];
