import { gql } from '@apollo/client';
import { GroupT } from 'types/Group';

export type GroupsResponseT = {
    groups: GroupT[];
};

export const GROUP_FILTER_OPTIONS_QUERY = gql`
    query GroupFilterOptions {
        filterOptions: group(
            order_by: { name: asc }
        ) {
            value: id
            label: name
        }
    }
`;

export const GROUPS_QUERY = gql`
    query Groups {
        groups: group(
            order_by: { name: asc }
        ) {
            id
            name
        }
    }
`;
