import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import Nullable from 'shared/data-hook/utils/DTO/Nullable';

const DTO = t.type({
    aggregate: t.type({
        count: Nullable(t.number),
    }),
});

export type AggregateDTOT = t.TypeOf<typeof DTO>;

export const AggregateDTO = tPromise.extendType<AggregateDTOT, number>(
    DTO,
    ({
        aggregate: {
            count,
        },
    }) => count ?? 0,
    (count) => ({
        aggregate: {
            count,
        },
    }),
) as t.Type<number, AggregateDTOT>;
