import { OptionT } from './Option';

export type CourtCategoryT = {
    id: string;
    name: string;
    position: number;
    courts: OptionT[];
};

export type CourtCategoriesT = {
    courtCategories: CourtCategoryT[];
    courtCategoriesCount: number;
};

export const EMPTY_COURT_CATEGORIES_RESULT = {
    courtCategories: [],
    courtCategoriesCount: 0,
};

export const EMPTY_COURT_CATEGORY_RESULT = {
    id: '',
    name: '',
    position: 0,
    courts: [],
};
