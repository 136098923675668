import { useMutation } from '@apollo/client';
import { OrganizationFormFields } from 'page/Organization/components/formdata';
import {
    ORGANIZATION_CREATE,
    OrganizationCreateResponseDataT,
    OrganizationCreateVarT,
} from 'shared/graphql/mutation/organization/OrganizationCreate';

import { CreateOrganizationDataT } from './CreateOrganizationData';

const useCreateOrganization = (): CreateOrganizationDataT => {
    const [createOrganization, { loading, error }] = useMutation<OrganizationCreateResponseDataT, OrganizationCreateVarT>(ORGANIZATION_CREATE);

    const create = async (input: OrganizationFormFields) => {
        try {
            const { data } = await createOrganization({
                variables: {
                    object: {
                        name: input.name,
                        service_app_id: input.serviceApp as string || null,
                        work_time: input.workTime,
                    },
                },
            });

            return data;
        } catch (errorCreate) {
            // eslint-disable-next-line no-console
            console.error((errorCreate as Error).message);
            throw error;
        }
    };

    return {
        loading,
        error,
        organizationCreate: create,
    };
};

export default useCreateOrganization;
