import { useLazyQuery } from '@apollo/client';
import { CourtDTO } from 'shared/data-hook/court/fetch/DTO/CourtDTO';
import { CourtDataT } from 'shared/data-hook/court/fetch/fetchOne/CourtData';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { COURT_QUERY, CourtResponseT } from 'shared/graphql/query/court/CourtQuery';
import { CourtT, EMPTY_COURT_RESULT } from 'types/Court';

const useCourt = (): CourtDataT => {
    const [fetch, { loading, error }] = useLazyQuery<CourtResponseT>(COURT_QUERY, {
        fetchPolicy: 'network-only',
    });

    const fetchCourt = async (id: string): Promise<CourtT> => {
        try {
            const { data } = await fetch({
                variables: {
                    id,
                },
            });

            return decodeDTO(CourtDTO, EMPTY_COURT_RESULT, (court) => court, data?.court);
        } catch {
            return EMPTY_COURT_RESULT;
        }
    };

    return {
        fetchCourt,
        loading,
        error,
    };
};

export default useCourt;
