import {
    PROPERTY_CLIENT,
    PROPERTY_MEMBER_CARD_TYPE,
    PROPERTY_ORGANIZATION,
} from 'constants/propertyNames';

import { object, string } from 'yup';

export type MemberCardFormFields = {
    clientDataId: string;
    memberCardTypeId: string;
    organizationId: string;
};

export const MEMBER_CARD_DEFAULT_VALUES: MemberCardFormFields = {
    clientDataId: '',
    memberCardTypeId: '',
    organizationId: '',
};

export enum MEMBER_CARD_FIELDS_KEYS {
    clientDataId = 'clientDataId',
    memberCardTypeId = 'memberCardTypeId',
    organizationId = 'organizationId',
}

export const MEMBER_CARD_LABEL_DATA = {
    clientDataId: PROPERTY_CLIENT,
    memberCardTypeId: PROPERTY_MEMBER_CARD_TYPE,
    organizationId: PROPERTY_ORGANIZATION,
};

export const getMemberCardValidationSchema = () => (
    object().shape({
        clientDataId: string().required(),
        memberCardTypeId: string().required(),
        organizationId: string().required(),
    }).required()
);
