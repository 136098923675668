import { makeAutoObservable } from 'mobx';
import { CourtCategoryT } from 'types/CourtCategory';

export default class CourtCategoryStore {
    public courtCategoryList: CourtCategoryT[] = [];

    public count: number = 0;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public get courtCategories(): CourtCategoryT[] {
        return this.courtCategoryList;
    }

    public setCourtCategories(courtCategories: CourtCategoryT[], count?: number) {
        this.courtCategoryList = courtCategories;
        this.count = count ?? 0;
    }
}
