export const DEFAULT_TIMEZONE = 7;

export const TIMEZONE_SELECT_OPTIONS = [
    {
        value: -11,
        label: '(GMT-11:00) Американское Самоа',
    },
    {
        value: -10,
        label: '(GMT-10:00) Гавайи',
    },
    {
        value: -9,
        label: '(GMT-09:00) Аляска',
    },
    {
        value: -8,
        label: '(GMT-08:00) Тихоокеанское время (США и Канада)',
    },
    {
        value: -7,
        label: '(GMT-07:00) Аризона, Горное время (США и Канада)',
    },
    {
        value: -6,
        label: '(GMT-06:00) Мехико, Монтеррей',
    },
    {
        value: -5,
        label: '(GMT-05:00) Восточное время (США и Канада)',
    },
    {
        value: -4,
        label: '(GMT-04:00) Атлантическое время (Канада), Джорджтаун',
    },
    {
        value: -3,
        label: '(GMT-03:00) Бразилиа, Буэнос-Айрес',
    },
    {
        value: -2,
        label: '(GMT-02:00) Средняя Атлантика',
    },
    {
        value: -1,
        label: '(GMT-01:00) Азорские острова, Острова Кабо-Верде',
    },
    {
        value: 0,
        label: '(GMT+00:00) Лондон, Лиссабон, Всемирное координированное время',
    },
    {
        value: 1,
        label: '(GMT+01:00) Белград, Берлин, Мадрид, Париж, Рим',
    },
    {
        value: 2,
        label: '(GMT+02:00) Калининград, Киев, Хельсинки, Рига, Таллин',
    },
    {
        value: 3,
        label: '(GMT+03:00) Москва, Санкт-Петербург, Минск, Кувейт, Стамбул',
    },
    {
        value: 4,
        label: '(GMT+04:00) Самара, Абу-Даби, Баку, Ереван',
    },
    {
        value: 5,
        label: '(GMT+05:00) Екатеринбург, Ташкент',
    },
    {
        value: 6,
        label: '(GMT+06:00) Омск, Астана, Алматы',
    },
    {
        value: 7,
        label: '(GMT+07:00) Новосибирск, Красноярск, Джакарта',
    },
    {
        value: 8,
        label: '(GMT+08:00) Иркутск, Пекин, Гонконг, Улан-Батор',
    },
    {
        value: 9,
        label: '(GMT+09:00) Якутск, Токио, Сеул, Осака',
    },
    {
        value: 10,
        label: '(GMT+10:00) Владивосток, Мельбурн, Сидней',
    },
    {
        value: 11,
        label: '(GMT+11:00) Магадан, Соломоновы острова',
    },
    {
        value: 12,
        label: '(GMT+12:00) Камчатка, Окленд, Фиджи',
    },
    {
        value: 13,
        label: '(GMT+13:00) Самоа, Токелау',
    },
];
