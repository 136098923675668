import { PAGE_TITLE_CLIENT } from 'constants/pageTitles';

import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useClientViews from 'shared/data-hook/clientView/fetch/fetchAll/useClientViews';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import useFetchPersonalManagerFilterOptions
    from 'shared/hook/personalManager/useFetchPersonalManagerFilterOptions';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import ClientList from './components/ClientList';
import { CLIENT_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const {
        data: clientViewsData, loading: clientViewsLoading, count: rowCount, fetchClientViews,
    } = useClientViews();
    const { setClientViews } = useStore('clientView');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { loading: personalManagerFilterOptionsLoading } = useFetchPersonalManagerFilterOptions();

    const loading = clientViewsLoading || personalManagerFilterOptionsLoading;

    usePageTitle(PAGE_TITLE_CLIENT);

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchClientViews, refetchTrigger, CLIENT_HEADER_DATA);

    useEffect(() => {
        setClientViews(clientViewsData, rowCount);
    }, [clientViewsData, clientViewsLoading]);

    return (
        <StyledPageWrap>
            <ControlPanel />
            <ClientList
                loading={ loading }
                pageSize={ pageSize }
                page={ page }
                setPageSize={ setPageSize }
                setPage={ setPage }
                setSortModel={ setSortModel }
                setFilterModel={ setFilterModel }
                sortModel={ sortModel }
                filterModel={ filterModel }
            />
        </StyledPageWrap>
    );
});

export default List;
