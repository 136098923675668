import { useQuery } from '@apollo/client';
import { COACH_FILTER_OPTIONS_QUERY } from 'shared/graphql/query/coach/CoachQuery';
import { EMPTY_FILTER_OPTIONS } from 'types/DataGrid';
import { CoachFilterOptionT } from 'types/filterOptions/CoachFilterOptions';
import { CoachFilterOptionsResponseT } from 'types/filterOptions/CoachFilterOptionsResponse';
import { FetchFilterOptionsDataT } from 'types/filterOptions/FetchFilterOptionsData';

const useFetchCoachFilterOptionsData = (): FetchFilterOptionsDataT => {
    const { data, loading } = useQuery<CoachFilterOptionsResponseT>(
        COACH_FILTER_OPTIONS_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    return {
        data: data?.filterOptions.map((coach: CoachFilterOptionT) => ({
            value: coach.value,
            label: coach.employee.full_name,
        })) || EMPTY_FILTER_OPTIONS,
        loading,
    };
};

export default useFetchCoachFilterOptionsData;
