export type PaymentTypeT = {
    id: string;
    title: string;
    position: number;
    isActive: boolean;
    defaultFiatPayment: number | null;
    defaultBonusPayment: number | null;
    fiatPaymentDivisible: number;
    bonusPaymentDivisible: number;
    paymentMethodId: string;
    shouldConfirmBySms: boolean;
    allowNegativePayments: boolean;
};

export type PaymentTypesT = {
    paymentTypes: PaymentTypeT[];
    paymentTypesCount: number;
};

export const EMPTY_PAYMENT_TYPES: PaymentTypesT = {
    paymentTypes: [],
    paymentTypesCount: 0,
};

export const EMPTY_PAYMENT_TYPE_RESULT = {
    id: '',
    title: '',
    position: 0,
    isActive: false,
    defaultFiatPayment: 0,
    defaultBonusPayment: 0,
    fiatPaymentDivisible: 0,
    bonusPaymentDivisible: 0,
    paymentMethodId: '',
    shouldConfirmBySms: false,
    allowNegativePayments: false,
};

export enum PaymentTypePosition {
    PurchaseMemberCard = 202,
}
