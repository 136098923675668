import { gql } from '@apollo/client';
import { WorkTime } from 'types/Organization';

export type OrganizationCreatePayloadT = {
    name: string;
    service_app_id: string | null;
    work_time: WorkTime[];
}

export type OrganizationCreateResponseDataT = {
    createOrganization: {
        id: string;
    };
} | null | undefined;

export type OrganizationCreateVarT = {
    object: OrganizationCreatePayloadT;
};

export const ORGANIZATION_CREATE = gql`
    mutation OrganizationCreate($object: organization_insert_input = {}) {
        createOrganization: insert_organization_one(
            object: $object
        ) {
            id
        }
    }
`;
