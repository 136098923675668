import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

import { StyledSpinnerBlock, StyledSpinnerWrap } from './domain/Styled';

const Spinner: FC = () => {
    return (
        <StyledSpinnerBlock>
            <StyledSpinnerWrap>
                <Box sx={ { display: 'flex' } }>
                    <CircularProgress color="inherit" />
                </Box>
            </StyledSpinnerWrap>
        </StyledSpinnerBlock>
    );
};

export default React.memo(Spinner);
