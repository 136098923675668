export const CountForm = (number: number, titles: string[]) => {
    const absNumber = Math.abs(number);

    if (Number.isInteger(number)) {
        const cases = [2, 0, 1, 1, 1, 2];

        return titles[(absNumber % 100 > 4 && absNumber % 100 < 20) ? 2 : cases[(absNumber % 10 < 5) ? absNumber % 10 : 5]];
    }

    return titles[1];
};
