import { PAGE_TITLE_GROUP_VIEW } from 'constants/pageTitles';

import { FC, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';
import ControlPanel from 'shared/component/ControlPanel';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';

import CreateGroupViewModal from './components/CreateGroupViewModal';
import GroupViewList from './components/GroupViewList';
import UpdateGroupViewModal from './components/UpdateGroupViewModal';
import { GROUP_VIEW_HEADER_DATA } from './domain/HeaderData';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { setGroupViews } = useStore('groupView');
    const { refetchTrigger } = useStore('refetchTrigger');

    const {
        data: groupViewsData, loading: groupViewsLoading, count: rowCount, fetchGroupViews,
    } = useGroupViews();

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchGroupViews, refetchTrigger, GROUP_VIEW_HEADER_DATA);

    usePageTitle(PAGE_TITLE_GROUP_VIEW);

    useEffect(() => {
        setGroupViews(groupViewsData, rowCount);
    }, [groupViewsData, groupViewsLoading]);

    const { onOpen } = useStore('createGroupViewModal');

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => onOpen() }
                    startIcon={ <AddIcon /> }
                >
                    Добавить
                </Button>
            </ControlPanel>
            <GroupViewList
                loading={ groupViewsLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                sortModel={ sortModel }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
            />
            <CreateGroupViewModal />
            <UpdateGroupViewModal />
        </StyledPageWrap>
    );
});

export default List;
