import { makeAutoObservable } from 'mobx';
import { ServiceCategoryT } from 'types/ServiceCategory';

export default class UpdateServiceCategoryModal {
    public isOpen: boolean = false;

    public updatedItemId: string = '';

    public serviceCategory: ServiceCategoryT | undefined = undefined;

    constructor() {
        makeAutoObservable(this, undefined, {
            autoBind: true,
        });
    }

    public onOpen(updatedItemId: string) {
        this.updatedItemId = updatedItemId;
        this.isOpen = true;
    }

    public onClose() {
        this.updatedItemId = '';
        this.isOpen = false;
    }

    public setServiceCategory(serviceCategory: ServiceCategoryT) {
        this.serviceCategory = serviceCategory;
    }
}
